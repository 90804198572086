import { Box, Stack, Typography } from '@mui/material'

type Props = { value: string; info?: string; big?: boolean }

export const InformationWidget = ({ value, info, big = false }: Props) => {
  if (value === '' || value === '0')
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="baseline"
        justifyContent="center"
      >
        <Typography variant="body2">{info}</Typography>
        <Typography variant="body2" fontSize={24}>
          0
        </Typography>
      </Stack>
    )
  const time = value.split(' ')
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Typography align="center" variant="body2" paddingRight={1}>
        {info}
      </Typography>
      {time.map(chunk => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            marginRight="8px"
            alignItems="baseline"
            key={chunk}
          >
            <Typography
              fontSize={big ? '28px' : '20px'}
              lineHeight="24px"
              sx={{
                color: '#003049',
              }}
            >
              {chunk.slice(0, Math.max(0, chunk.length - 1))}
            </Typography>

            <Typography
              fontSize={big ? '17px' : '12px'}
              lineHeight={big ? '28px' : 1.4}
              component="sub"
              sx={{
                color: '#87A1B2',
              }}
            >
              {chunk.charAt(chunk.length - 1)}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
