export const IconCheck = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.04159 3.67077C1.64081 3.29078 1.00787 3.30763 0.627876 3.70841C0.247885 4.10919 0.264738 4.74213 0.665519 5.12212L2.04159 3.67077ZM7 9.75L6.31197 10.4757C6.50489 10.6586 6.76271 10.7572 7.02845 10.7496C7.2942 10.742 7.54599 10.629 7.7282 10.4354L7 9.75ZM15.7282 1.93536C16.1067 1.53319 16.0875 0.900316 15.6854 0.5218C15.2832 0.143283 14.6503 0.162462 14.2718 0.564635L15.7282 1.93536ZM0.665519 5.12212L6.31197 10.4757L7.68803 9.02432L2.04159 3.67077L0.665519 5.12212ZM7.7282 10.4354L15.7282 1.93536L14.2718 0.564635L6.2718 9.06464L7.7282 10.4354Z"
      fill="currentColor"
    />
  </svg>
)
