const MB = 1024 * 1024

export const definePartsNumber = (size: number) => {
  if (size > 5 * MB && size <= 10 * MB) {
    // 5mb < size < 10mb
    return 1
  }
  if (size > 10 * MB && size <= 50 * MB) {
    // 10mb < size < 50mb
    return 2
  }
  if (size > 50 * MB && size <= 500 * MB) {
    // 50mb < size < 500mb
    return Math.ceil(size / (50 * MB))
  }
  if (size > 500 * MB && size <= 1000 * MB) {
    // 500mb < size < 1000mb
    return Math.ceil(size / (100 * MB))
  }
  //   > 1000mb
  return Math.ceil(size / (200 * MB))
}
