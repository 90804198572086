import promiseRetry from 'promise-retry'
import { ComponentType } from 'react'
import lazy, { PreloadableComponent } from 'react-lazy-with-preload'

export type LazyRoute<T extends ComponentType<any> = ComponentType<any>> = {
  title: string
  path: string
  component: PreloadableComponent<T>
}

export const createLazyRoute = () => {
  return <T extends ComponentType<any>>({
    title,
    path,
    factory,
  }: {
    title: string
    path: string
    factory: () => Promise<{ default: T }>
  }): LazyRoute<T> => ({
    title,
    path,
    component: lazy(() =>
      promiseRetry(retry => factory().catch(retry), {
        retries: 5,
      }),
    ),
  })
}
