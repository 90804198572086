import { Box, CircularProgress } from '@mui/material'
import { memo } from 'react'

export const Loader = memo(() => (
  <Box
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(50%,-50%)',
    }}
  >
    <CircularProgress />
  </Box>
))
