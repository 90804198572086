import { useCallback, useEffect } from 'react'

export const useSyncAcrossTabs = (topic: string, callback: () => void) => {
  useEffect(() => {
    const sync = (event: StorageEvent) => {
      if (event.key === topic) {
        callback()
      }
    }
    window.addEventListener('storage', sync)
    return () => {
      window.removeEventListener('storage', sync)
    }
  }, [callback, topic])

  return useCallback(() => {
    callback()
    localStorage.setItem(topic, Date.now().toString())
  }, [callback, topic])
}
