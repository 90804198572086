export const routes = {
  index: '/',
  signin: '/signin',
  signup: '/signup',
  recoverPassword: '/recover-password',
  dashboard: '/dashboard',
  profile: '/profile',
  accountSettings: '/account-settings',
  file: '/files/:fileId',
  shared: '/files/shared/:fileId',
  myFiles: '/files',
  upload: '/upload',
  confirmRegistration: '/confirm/registration',
  confirmPassword: '/confirm/password',
  confirmEmail: '/confirm/email',
  organizationSettings: '/organization-settings',
  users: '/users',
  confirmInvite: '/confirm/invitation',
  organizations: '/organizations',
  emails: '/emails',
  integrations: '/integrations',
  integrationCallback: '/integrations/callback',
  organizationsDetails: '/organizations/:organizationId/:accountId',
  customDictionary: '/custom-dictionary',
  configuration: '/configuration',
} as const
