import * as t from 'io-ts'
import { DateFromISOString } from 'lib/io-ts-codecs/DateFromISOString'

export const TIntegrationType = t.union([
  t.literal('23v'),
  t.literal('kaltura'),
  t.literal('google'),
  t.literal('ibm'),
  t.literal('brightcove'),
  t.literal('zoom'),
])
export const TIntegrationStatus = t.union([
  t.literal('ACTIVE'),
  t.literal('INIT'),
])
export const IntegrationsCodec = t.type({
  createdAt: t.union([t.string, t.undefined]),
  integrationId: t.union([t.string, t.undefined]),
  organizationId: t.union([t.string, t.undefined]),
  type: t.union([TIntegrationType, t.undefined]),
  url: t.union([t.string, t.undefined]),
})

export const IntegrationCallbackCodec = t.type({
  integrationId: t.string,
  type: t.string,
  token: t.string,
  tokenSecret: t.string,
  domain: t.string,
  authUserId: t.string,
  createdAt: t.string,
})

export type IIntegrationType = t.TypeOf<typeof TIntegrationType>
export type IIntegrationStatus = t.TypeOf<typeof TIntegrationStatus>

export const IntegrationListCodec = t.type({
  integrationId: t.string,
  type: TIntegrationType,
  createdAt: DateFromISOString,
  status: TIntegrationStatus,
})

export const LanguageConfigCodec = t.type({
  languageCode: t.string,
  relatedLanguage: t.string,
})

export const LanguageMetaDataCodec = t.type({
  languagesConfig: t.array(LanguageConfigCodec),
  metadataSchemaId: t.union([t.number, t.undefined]),
  metadataFieldSystemName: t.string,
  metadataSchemaSystemName: t.union([t.string, t.undefined]),
})

const OnPremiseConfigCodec = t.type({
  apiEndpoint: t.string,
})
export const IntegrationCodec = t.type({
  integrationId: t.string,
  type: TIntegrationType,
  createdAt: DateFromISOString,
  language: t.string,
  partnerId: t.union([t.string, t.undefined]),
  serviceUrl: t.union([t.string, t.undefined]),
  onPremise: t.union([t.boolean, t.undefined]),
  secret: t.union([t.string, t.undefined]),
  clientSecret: t.union([t.string, t.undefined]),
  clientId: t.union([t.string, t.undefined]),
  subtitle: t.boolean,
  languageMetadata: t.union([LanguageMetaDataCodec, t.null, t.undefined]),
  sendInvite: t.boolean,
  autoProcess: t.boolean,
  onPremiseConfig: t.union([OnPremiseConfigCodec, t.null, t.undefined]),
})
export type IIntegration = t.TypeOf<typeof IntegrationCodec>

export const UserRuleCodec = t.type({
  userRuleId: t.string,
  integrationType: t.union([t.literal('23v'), t.literal('kaltura')]),
  patternType: t.union([t.literal('include'), t.literal('exclude')]),
  patternText: t.string,
  createdAt: DateFromISOString,
})
