import { Chip as MuiChip } from '@mui/material'

type Props = {
  label: string
  backgroundColor: string

  color?: string
}

export const Chip = (props: Props) => {
  return (
    <MuiChip
      size="small"
      label={props.label}
      sx={{
        py: '1px',
        px: '6px',
        fontSize: '14px',
        lineHeight: '22px',
        color: props.color ?? 'white',
        backgroundColor: props.backgroundColor,
      }}
    />
  )
}
