import { Grid } from '@mui/material'
import { FormInput, FormModal } from 'components/Form'
import { validation } from 'config/validation'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const formatDurationToSeconds = ({
  hours,
  minutes,
}: {
  hours?: number
  minutes?: number
}) => {
  return Number(hours ?? 0) * 3600 + Number(minutes ?? 0) * 60
}

type FormValues = {
  hours: number
  minutes: number
}

type Props = {
  organizationId: string
  open: boolean
  mode: 'add' | 'reduce'

  onClose: () => void
  refetchCredits: () => void
  refetchList: () => void
}

export const AddCreditsModal = (props: Props) => {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const $addCredit = useMutation(
    'POST',
    '/api/organizations/:organizationId/credits',
  )

  const MINIMUM_CREDIT = 60

  const mutateAddCredit = $addCredit.mutate

  const onSubmit = (data: FormValues) => {
    const creditsSum = formatDurationToSeconds(data)
    if (creditsSum < MINIMUM_CREDIT) {
      enqueueSnackbar(
        t('validation.min_credits_number', { min: MINIMUM_CREDIT }),
        {
          variant: 'error',
        },
      )
      return
    }

    mutateAddCredit(
      {
        params: { organizationId: props.organizationId },
        body: {
          creditsNumber:
            formatDurationToSeconds(data) * (props.mode === 'reduce' ? -1 : 1),
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            t(
              `success_notification.credits_${
                props.mode === 'add' ? 'added' : 'reduced'
              }`,
            ),
            {
              variant: 'success',
            },
          )
          props.onClose()
          props.refetchCredits()
          props.refetchList()
        },
        onError: () => {
          props.onClose()
        },
      },
    )
  }

  return (
    <FormModal<FormValues>
      onSubmit={onSubmit}
      title={t(`labels.${props.mode}_credit`)}
      submitTitle={t(`buttons.${props.mode}`)}
      onClose={props.onClose}
      open={props.open}
      isLoading={$addCredit.isLoading}
      defaultValues={{
        hours: undefined,
        minutes: undefined,
      }}
      renderForm={({ control }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              type="filled"
              control={control}
              name="hours"
              required={false}
              fullWidth
              placeholder={t('placeholders.hours')}
              pattern={{
                value: validation.lessThan1000,
                message: t('validation.number_max_1000'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="minutes"
              required={false}
              fullWidth
              placeholder={t('placeholders.minutes')}
              pattern={{
                value: validation.numberLess60,
                message: t(`validation.non_negative_number_max`),
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
