import { Box, Button } from '@mui/material'
import { ReactComponent as Denmark } from 'assets/icons/denmark.svg'
import { ReactComponent as England } from 'assets/icons/united-kingdom.svg'
import { Tooltip } from 'components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const LanguageMenu = () => {
  const [language, setLanguage] = useState(true)
  const { t } = useTranslation()

  return (
    <Box>
      <Tooltip placement="bottom" title={t('tooltips.change_language')}>
        <Button onClick={() => setLanguage(!language)} disableRipple>
          {language ? <England width={'32px'} /> : <Denmark width={'32px'} />}
        </Button>
      </Tooltip>
    </Box>
  )
}
