import { Grid, Typography } from '@mui/material'
import { UserCodec } from 'app/codecs'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { FormModal } from 'components/Form'
import { array } from 'io-ts'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type Props = {
  onClose: () => void
  open: boolean
  refetch: () => void
}
type FormValues = {
  manager: string
}

export const SetAccountManagerModal = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const $userList = useJsonQuery(
    'GET',
    '/api/organizations/my/users?roles=ADMIN,OWNER',
    array(UserCodec),
  )
  const $pickManager = useMutation(
    'PUT',
    '/api/organizations/my/account-manager',
  )
  const { data } = $userList

  const onSubmit = ({ manager }: FormValues) => {
    $pickManager.mutate(
      { body: { accountManagerId: manager } },
      {
        onSuccess: () => {
          props.onClose()
          enqueueSnackbar(t('success_notification.account_manager_set'), {
            variant: 'success',
          })
          props.refetch()
        },
        onError: () => {
          enqueueSnackbar(
            t('error_notification.error_setting_account_manager'),
            {
              variant: 'error',
            },
          )
        },
      },
    )
  }

  if (!data) {
    return null
  }

  return (
    <FormModal<FormValues>
      title={t('common.choose_manager')}
      submitTitle={t('buttons.pick')}
      open={props.open}
      onClose={props.onClose}
      onSubmit={onSubmit}
      isLoading={$pickManager.isLoading}
      renderForm={({ control }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {t('organization_settings.choose_manager')}
            </Typography>
            <ControlledAutocomplete
              control={control}
              label={''}
              name="manager"
              options={data.map(user => ({
                label: `${user.firstName}  ${user.lastName}`,
                value: user.userId,
              }))}
              required={false}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
