import { env } from 'app/env'
import { initI18n } from 'app/i18n'
import { reportWebVitals } from 'app/report-web-vitals'
import { createRoot } from 'react-dom/client'

import PageIndex from './pages'
import App from './pages/_app'

async function main() {
  await initI18n({
    debug:
      env.REACT_APP_ENVIRONMENT === 'development' &&
      env.REACT_APP_DEBUG_I18N === 'on',
  })

  if (
    process.env.NODE_ENV === 'development' &&
    (process.env.REACT_APP_MSW === 'on' ||
      process.env.REACT_APP_MSW === 'quiet')
  ) {
    const { worker } = await import('./mocks/browser')
    await worker.start({ quiet: process.env.REACT_APP_MSW === 'quiet' })
  }

  const container = document.querySelector('#root')
  const root = createRoot(container!)

  root.render(<App Component={PageIndex} />)
}

main()

if (env.REACT_APP_REPORT_WEB_VITALS === 'on') {
  reportWebVitals(console.log)
}
