import { Stack } from '@mui/material'
import { IIntegrationType, IntegrationCodec } from 'app/codecs'
import { Button } from 'components'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { integrationTypeMapping } from 'config/integration-options'
import { TypeOf } from 'io-ts'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ActivateDeactivateSwitch } from '../ActivateDeactivateSwitch'
import DisabledField from '../DisabledField'

type Props = {
  onClose: () => void
  integrationData: TypeOf<typeof IntegrationCodec>
  languages: {
    [languageName: string]: string
  }
  onSubmitForm: (values: { language: string; subtitle: boolean }) => void
}
type FormFields = {
  language: string
  subtitle: boolean
}

const BrightcoveUpdateForm = ({
  onClose,
  onSubmitForm,
  integrationData,
  languages,
}: Props) => {
  const { t } = useTranslation()

  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      language: integrationData.language,
      subtitle: integrationData.subtitle,
    },
  })

  const { clientSecret, clientId, language, type } = integrationData
  if (!clientSecret || !clientId || !language) {
    return null
  }

  return (
    <form
      onSubmit={handleSubmit(values => {
        onSubmitForm(values)
      })}
    >
      <Stack spacing={2} sx={{ maxWidth: 530 }}>
        <DisabledField
          title={t('labels.type_of_integration')}
          value={integrationTypeMapping[type as IIntegrationType]}
        />

        <DisabledField title={t('labels.client_id')} value={clientId} />

        <DisabledField title={t('labels.client_secret')} value={clientSecret} />

        <ControlledAutocomplete
          control={control}
          label={t('integrations.choose_default_language')}
          name="language"
          options={Object.keys(languages).map(key => ({
            label: languages[key],
            value: key,
          }))}
          required
        />

        <ActivateDeactivateSwitch control={control} />

        <Stack direction="row" justifyContent="space-between">
          <Button
            noSpacing
            variant="text"
            onClick={onClose}
            sx={{ textTransform: 'capitalize' }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            noSpacing
            withShadow
            type="submit"
            color="primary"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
          >
            {t('common.update')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default BrightcoveUpdateForm
