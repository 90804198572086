import {
  Grid,
  Skeleton as MuiSkeleton,
  SkeletonProps,
  Stack,
} from '@mui/material'
import { Box } from '@mui/system'
import { ChunksTableLoader } from 'views/VideoEditor'

const Skeleton = (props: SkeletonProps) => (
  <MuiSkeleton
    {...props}
    sx={{ scale: 'none', transform: 'none', ...props.sx }}
  />
)

export const FilePreloader = () => {
  return (
    <Box maxHeight="90vh" overflow="hidden">
      <Stack direction="column" justifyContent="flex-start" spacing={1} mb={2}>
        <Skeleton animation="wave" height={18} width="25vw" />
        <Skeleton variant="text" animation="wave" height={40} width="50vw" />
        <Skeleton animation="wave" height={20} width="30vw" />
      </Stack>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} md={6} lg={7.2} xl={8.4} order={{ xs: 2, md: 1 }}>
          <ChunksTableLoader shared />
        </Grid>
        <Grid item xs={12} md={6} lg={4.8} xl={3.6} order={{ xs: 1, md: 2 }}>
          <Skeleton animation="wave" height="45vh" sx={{ mb: 1 }} />
        </Grid>
      </Grid>
    </Box>
  )
}
