import { brand, Branded, number, TypeOf } from 'io-ts'

interface PositiveBrand {
  readonly Positive: unique symbol
}

export const TPositive = brand(
  number,
  (n): n is Branded<number, PositiveBrand> => 0 < n,
  'Positive',
)

export type Positive = TypeOf<typeof TPositive>
