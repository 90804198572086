import { IconButton, Menu, MenuItem } from '@mui/material'
import { useAuthContext } from 'app/auth'
import { ReactComponent as ActionIcon } from 'assets/icons/action.svg'
import { Tooltip } from 'components'
import { MouseEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Action = {
  label: string
  action: () => void | Promise<void> | null
  variant?: 'primary' | 'warning'
}

type Props = {
  actions: Array<Action | null>
  hidden?: number
  disabled?: boolean
  userId?: string
  isOwnFile?: boolean
}

export const ActionCell = ({
  actions,
  hidden,
  disabled,
  userId,
  isOwnFile,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { t } = useTranslation()
  const { accessToken } = useAuthContext()

  const onClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const onOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const accessForbidden = useMemo(
    () =>
      accessToken === null ||
      (accessToken.role === 'EDITOR' &&
        accessToken.userId !== userId &&
        !isOwnFile),
    [accessToken, isOwnFile, userId],
  )

  return (
    <>
      {!accessForbidden && (
        <Tooltip placement="top" title={t('common.more_actions')}>
          <span>
            <IconButton
              disabled={disabled}
              onClick={onOpen}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <ActionIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {open && (
        <Menu
          sx={{
            marginTop: theme => theme.spacing(1),
            boxShadow:
              '0px 0px 0px 1px rgba(34, 34, 34, 0.06), 0px 4px 4px rgba(34, 34, 34, 0.06), 0px 0px 1px rgba(34, 34, 34, 0.06);',
            minWidth: theme => theme.typography.pxToRem(200),
            '.MuiList-root': {
              padding: 0,
            },
          }}
          keepMounted
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {actions.slice(hidden).map((action, idx) => {
            if (action === null) {
              return null
            }

            return (
              <MenuItem
                key={action.label + idx}
                sx={{
                  color: theme => theme.palette.text.primary,
                  minWidth: '101px',
                  borderRadius: 0,
                  textTransform: 'inherit',
                  height: '41px',
                  '&:hover': {
                    backgroundColor: 'info.light',
                  },
                }}
                onClick={action.action}
              >
                {action.label}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </>
  )
}
