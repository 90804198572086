import * as t from 'io-ts'

export const ProfileCodec = t.type({
  userId: t.string,
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  organizationId: t.string,
  avatarUrl: t.union([t.string, t.null]),
  creditsLimit: t.union([t.number, t.null]),
  availableCredits: t.union([t.number, t.null]),
  newUnconfirmedEmail: t.union([t.string, t.null]),
  fileUpload: t.boolean,
  groupRepresentation: t.boolean,
})

export const OrganizationCodec = t.type({
  name: t.string,
  logoUrl: t.union([t.string, t.null]),
  defaultCreditsLimit: t.number,
  accountId: t.string,
})

export const UpdatedEmailCodec = t.type({
  email: t.string,
})

export const ProfileTasksDurationCodec = t.number

export type Profile = t.TypeOf<typeof ProfileCodec>

export type Organization = t.TypeOf<typeof OrganizationCodec>

export type ProfileTaskDuration = t.TypeOf<typeof ProfileTasksDurationCodec>
