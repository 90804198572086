import * as t from 'io-ts'

export const SessionCodec = t.type({
  session: t.string,
})

export const TUploadTokenUpload = t.type({
  autoFinalize: t.boolean,
  createdAt: t.number,
  fileName: t.string,
  fileSize: t.number,
  id: t.string,
  partnerId: t.number,
  status: t.number,
  updatedAt: t.number,
  uploadUrl: t.string,
  uploadedFileSize: t.number,
  userId: t.string,
})

export const TUploadTokenAdd = t.type({
  autoFinalize: t.boolean,
  createdAt: t.number,
  id: t.string,
  objectType: t.string,
  partnerId: t.number,
  status: t.number,
  updatedAt: t.number,
  uploadUrl: t.string,
  userId: t.string,
})

export const TMediaAdd = t.type({
  accessControlId: t.number,
  blockAutoTranscript: t.boolean,
  capabilities: t.string,
  createdAt: t.number,
  creatorId: t.string,
  dataUrl: t.string,
  displayInSearch: t.number,
  downloadUrl: t.string,
  duration: t.number,
  entitledUsersEdit: t.string,
  entitledUsersPublish: t.string,
  entitledUsersView: t.string,
  id: t.string,
  licenseType: t.number,
  mediaType: t.number,
  moderationCount: t.number,
  moderationStatus: t.number,
  msDuration: t.number,
  name: t.string,
  objectType: t.string,
  operationAttributes: t.array(t.number),
  partnerId: t.number,
  partnerSortValue: t.number,
  plays: t.number,
  rank: t.number,
  replacementStatus: t.number,
  rootEntryId: t.string,
  searchText: t.string,
  source: t.string,
  status: t.number,
  thumbnailUrl: t.string,
  totalRank: t.number,
  type: t.number,
  updatedAt: t.number,
  userId: t.string,
  version: t.number,
  views: t.number,
  votes: t.number,
})
