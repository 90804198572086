export const validation = {
  organisationFieldMaxLength: 100,
  commonFieldMaxLength: 50,
  commonFieldMinLength: 2,
  patternTextMinLength: 3,
  passwordMinLength: 8,
  passwordMaxLength: 50,
  emailRegex: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
  passwordRegex:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
  siretRegex: /^[\da-z]+$/i,
  onlyNumbersRegex: /^\d$/,
  onlyDigitsRegex: /^\d+$/,
  numberLess60: /^[1-5]?\d$/,
  numbersRegex: /\b(0*(?:\d{1,2}|99))\b/,
  lessThan1000: /\b((\d|[1-9]\d|[1-9]\d\d))\b/,
  lessOrEqual100: /\b((\d|[1-9]\d|100))\b/,
  // eslint-disable-next-line security/detect-unsafe-regex
  validUrl: /https?:\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w!#%&+./:=?-]))?/,
}
