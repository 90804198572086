import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
} from '@mui/material'
import { IconCheck } from 'assets/icons/check'
import { useUuid } from 'lib/use-uuid'
import { forwardRef, ReactNode } from 'react'

import { useStyles } from './styles'

export type CheckboxProps = Omit<FormControlProps, 'children' | 'htmlFor'> & {
  checked: boolean
  onChange: (checked: boolean) => void

  label?: string | JSX.Element | any
  disabled?: boolean
  error?: boolean
  helperText?: ReactNode
}

export const emptyCheckboxValue = false

export const Checkbox = forwardRef((props: CheckboxProps, ref) => {
  const { classes } = useStyles()

  const id = useUuid()

  const control = (
    <MuiCheckbox
      id={id}
      disableRipple
      checked={props.checked}
      onChange={event => props.onChange(event.target.checked)}
      disabled={props.disabled}
      checkedIcon={<IconCheck className={classes.check} />}
      icon={<span className={classes.icon} />}
      classes={{
        disabled: classes.iconDisabled,
      }}
      sx={{
        mr: 1,
        p: 0,
        '&.Mui-checked': {
          color: '#00ff00',
        },
      }}
      style={props.style}
    />
  )

  if (props.label) {
    return (
      <FormControl error={props.error}>
        <FormControlLabel
          label={props.label}
          ref={ref}
          control={control}
          classes={{ root: classes.container, label: classes.label }}
        />
        <FormHelperText error={props.error} sx={{ ml: 0, mt: -0.5 }}>
          {props.helperText}
        </FormHelperText>
      </FormControl>
    )
  }

  return control
})

Checkbox.displayName = 'Checkbox'
