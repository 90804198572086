import { Theme } from '@mui/material'

export const controlBoxStyles = (theme: Theme, borderRadius: string) => ({
  container: {
    margin: 0,
    marginBottom: '12px',
  },

  label: {
    marginLeft: '8px',
    fontSize: 'inherit',

    '&.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },

  iconContainer: {
    padding: 0,
    transition: 'box-shadow 0.25s',

    '&:hover': {
      background: 'transparent',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    },
  },
  icon: {
    borderRadius,
    width: 16,
    height: 16,
    background: '#fff',
    padding: 2,
    border: '1px solid #4AC398',
  },
})
