import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as FineTuneIcon } from 'assets/icons/subtitles.svg'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useBoolean } from 'lib/use-boolean'
import { useSingleAndDoubleClicks } from 'lib/use-single-and-double-clicks'
import { useSnackbar } from 'notistack'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  text: string
  children: ReactNode
  taskId: string
  chunkIds: string[]
  allowEdit: boolean
  fineTuneMode: boolean
  grouped: boolean
  translation: string | null
  disableButton?: boolean

  onFineTuneOpen: () => Promise<void>
  onFineTuneClose: () => Promise<void>
  onFineTuneUpdate: () => Promise<void>
  onTranscriptionUpdate: () => Promise<void>
}

export const EditableTableCell = (props: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [phraseGroup, setPhraseGroup] = useState<string>(props.text)

  const editMode = useBoolean(false)
  const updating = useBoolean(false)
  const button = useBoolean(false)

  const phrases = useMemo(() => phraseGroup.split('\u202F'), [phraseGroup])

  const search = useMemo(() => {
    const params = new URLSearchParams({})

    if (props.translation) {
      params.set('language', props.translation)
    }

    return params
  }, [props.translation])

  useEffect(() => {
    return () => {
      if (editMode.isTrue) {
        editMode.setFalse()
      }

      if (updating.isTrue) {
        updating.setFalse()
      }
    }
  }, [editMode, updating])

  const $updatePhrase = useMutation(
    'PATCH',
    '/api/tasks/:taskId/transcription/chunks',
  )

  const { handleClick, handleDoubleClick } = useSingleAndDoubleClicks({
    onClick: editMode.setTrue,
  })

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        wordBreak: 'break-word',
        backgroundColor: props.fineTuneMode
          ? 'rgba(143, 160, 250, 0.1)'
          : undefined,
        p: 1,
        '&:hover': {
          backgroundColor: props.fineTuneMode
            ? undefined
            : 'rgba(135, 161, 178, 0.07)',
        },
      }}
      onClick={event => {
        if (props.allowEdit && !props.fineTuneMode) {
          handleClick(event)
        }
      }}
      onDoubleClick={handleDoubleClick}
      onMouseEnter={() => button.setTrue()}
      onMouseLeave={() => button.setFalse()}
    >
      {editMode.isFalse ? (
        <Stack direction="row" justifyContent="space-between">
          <Box flexWrap={props.grouped ? 'nowrap' : 'wrap'}>
            <Typography component="div" fontSize={14}>
              {props.fineTuneMode || !props.allowEdit
                ? props.text
                : props.children}
            </Typography>
          </Box>
          {!props.grouped && (
            <Stack flexWrap="nowrap" direction="row-reverse" spacing={0.25}>
              <Tooltip
                title={t(
                  `buttons.${props.fineTuneMode ? 'save' : 'fine_tuning'}`,
                )}
              >
                <ButtonBase
                  onClick={async event => {
                    event.stopPropagation()

                    await (props.fineTuneMode
                      ? props.onFineTuneUpdate()
                      : props.onFineTuneOpen())
                  }}
                  onKeyDown={event => {
                    if (event.key === ' ') {
                      event.preventDefault()
                    }
                  }}
                  sx={{
                    display:
                      (button.isTrue && props.allowEdit) || props.fineTuneMode
                        ? 'block'
                        : 'none',
                  }}
                  disabled={props.disableButton}
                >
                  {props.fineTuneMode ? <SaveIcon /> : <FineTuneIcon />}
                </ButtonBase>
              </Tooltip>
              <Tooltip title={t('buttons.cancel')}>
                <ButtonBase
                  onClick={async event => {
                    event.stopPropagation()

                    await props.onFineTuneClose()
                  }}
                  onKeyDown={event => {
                    if (event.key === ' ') {
                      event.preventDefault()
                    }
                  }}
                  sx={{
                    display: props.fineTuneMode ? 'block' : 'none',
                  }}
                  disabled={props.disableButton}
                >
                  <CloseIcon />
                </ButtonBase>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      ) : (
        <ClickAwayListener
          onClickAway={() => {
            if (phraseGroup === props.text) {
              setPhraseGroup(props.text)
              editMode.setFalse()
            } else {
              updating.setTrue()

              const body = props.chunkIds.map((chunkId, idx) => ({
                chunkId,
                newText: phrases[idx] ?? '',
              }))

              $updatePhrase.mutate(
                {
                  params: {
                    taskId: props.taskId,
                  },
                  body,
                  search,
                },
                {
                  onSuccess: async () => {
                    enqueueSnackbar(
                      t('success_notification.phrase_was_changed'),
                      {
                        variant: 'success',
                      },
                    )
                    await props.onTranscriptionUpdate()
                  },
                  onError: error => {
                    updating.setFalse()
                    setPhraseGroup(props.text)
                    if (error.type === 'client_error') {
                      enqueueSnackbar(
                        t(
                          `error_notification.${
                            error.code ?? 'error_phrase_change_failed'
                          }`,
                        ),
                        {
                          variant: 'error',
                        },
                      )
                    }

                    if (error.type === 'server_error') {
                      enqueueSnackbar(t(`server_error.${error.status}`), {
                        variant: 'error',
                      })
                    }
                  },
                },
              )
            }
          }}
        >
          <TextField
            multiline
            margin="none"
            size="small"
            variant="standard"
            inputProps={{
              sx: { fontSize: 14, fontWeight: 500 },
            }}
            value={phraseGroup}
            onChange={e => setPhraseGroup(e.target.value)}
            onKeyDown={event => {
              if (event.key === ' ') {
                event.stopPropagation()
              }
            }}
            disabled={updating.isTrue}
          />
        </ClickAwayListener>
      )}
    </Box>
  )
}
