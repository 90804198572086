import { Stack, Typography } from '@mui/material'

type Props = {
  id: string
  type: string
}

export const Transaction = (props: Props) => {
  return (
    <Stack p={2}>
      <Typography variant="h3" noWrap align="left">
        {props.id}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'uppercase', opacity: '0.6' }}
        noWrap
        align="left"
      >
        {props.type}
      </Typography>
    </Stack>
  )
}
