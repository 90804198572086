import { useTranslation } from 'react-i18next'
import { Route } from 'react-router-dom'
import { useTitle } from 'react-use'

import { LazyRoute } from './lazy-route'

const Title = ({ title }: { title: string }) => {
  const { t } = useTranslation()
  useTitle(`${t(`pages.${title}`)} · IMGplay's`)
  return null
}

export const createRoutes = (routes: Array<LazyRoute>) =>
  routes.map(route => {
    const { path, title, component: Component } = route

    return (
      <Route
        key={path}
        path={path}
        element={
          <>
            <Title title={title} />
            <Component />
          </>
        }
      />
    )
  })
