import { createLazyRoute, LazyRoute } from 'lib/lazy-routing'

import { routes } from './routes'

const lazyRoute = createLazyRoute()

export const pageRoutesEditor: Array<LazyRoute> = [
  lazyRoute({
    title: 'profile',
    path: routes.profile,
    factory: () => import(`pages/profile`),
  }),
  lazyRoute({
    title: 'my_files',
    path: routes.myFiles,
    factory: () => import(`pages/my-files`),
  }),
  lazyRoute({
    title: 'file',
    path: routes.file,
    factory: () => import(`pages/file`),
  }),
  lazyRoute({
    title: 'confirmation',
    path: routes.confirmRegistration,
    factory: () => import(`pages/confirmation/confirm-registration`),
  }),
  lazyRoute({
    title: 'change_email',
    path: routes.confirmEmail,
    factory: () => import(`pages/confirmation/email-change`),
  }),
  lazyRoute({
    title: 'shared',
    path: routes.shared,
    factory: () => import(`pages/shared-file`),
  }),
  lazyRoute({
    title: 'dictionary',
    path: routes.customDictionary,
    factory: () => import(`pages/custom-dictionary`),
  }),
]

export const pageRoutesOwner: Array<LazyRoute> = [
  lazyRoute({
    title: 'profile',
    path: routes.profile,
    factory: () => import(`pages/profile`),
  }),
  lazyRoute({
    title: 'organization_settings',
    path: routes.organizationSettings,
    factory: () => import(`pages/organization-settings`),
  }),
  lazyRoute({
    title: 'my_files',
    path: routes.myFiles,
    factory: () => import(`pages/my-files`),
  }),
  lazyRoute({
    title: 'file',
    path: routes.file,
    factory: () => import(`pages/file`),
  }),
  lazyRoute({
    title: 'integrations',
    path: routes.integrations,
    factory: () => import(`pages/integrations`),
  }),
  lazyRoute({
    title: 'integration_callback',
    path: routes.integrationCallback,
    factory: () => import(`pages/integration`),
  }),
  lazyRoute({
    title: 'users',
    path: routes.users,
    factory: () => import(`pages/users`),
  }),
  lazyRoute({
    title: 'confirmation',
    path: routes.confirmRegistration,
    factory: () => import(`pages/confirmation/confirm-registration`),
  }),
  lazyRoute({
    title: 'change_email',
    path: routes.confirmEmail,
    factory: () => import(`pages/confirmation/email-change`),
  }),
  lazyRoute({
    title: 'shared',
    path: routes.shared,
    factory: () => import(`pages/shared-file`),
  }),
  lazyRoute({
    title: 'dictionary',
    path: routes.customDictionary,
    factory: () => import(`pages/custom-dictionary`),
  }),
]

export const pageRoutesAdmin: Array<LazyRoute> = [
  lazyRoute({
    title: 'profile',
    path: routes.profile,
    factory: () => import(`pages/profile`),
  }),
  lazyRoute({
    title: 'my_files',
    path: routes.myFiles,
    factory: () => import(`pages/my-files`),
  }),
  lazyRoute({
    title: 'file',
    path: routes.file,
    factory: () => import(`pages/file`),
  }),
  lazyRoute({
    title: 'users',
    path: routes.users,
    factory: () => import(`pages/users`),
  }),
  lazyRoute({
    title: 'confirmation',
    path: routes.confirmRegistration,
    factory: () => import(`pages/confirmation/confirm-registration`),
  }),
  lazyRoute({
    title: 'change_email',
    path: routes.confirmEmail,
    factory: () => import(`pages/confirmation/email-change`),
  }),
  lazyRoute({
    title: 'shared',
    path: routes.shared,
    factory: () => import(`pages/shared-file`),
  }),
  lazyRoute({
    title: 'integrations',
    path: routes.integrations,
    factory: () => import(`pages/integrations`),
  }),
  lazyRoute({
    title: 'integration_callback',
    path: routes.integrationCallback,
    factory: () => import(`pages/integration`),
  }),
  lazyRoute({
    title: 'dictionary',
    path: routes.customDictionary,
    factory: () => import(`pages/custom-dictionary`),
  }),
]

export const pageRoutesSystemAdmin: Array<LazyRoute> = [
  lazyRoute({
    title: 'dashboard',
    path: routes.dashboard,
    factory: () => import(`pages/dashboard`),
  }),
  lazyRoute({
    title: 'organizations',
    path: routes.organizations,
    factory: () => import(`pages/organizations`),
  }),
  lazyRoute({
    title: 'organization_details',
    path: routes.organizationsDetails,
    factory: () => import(`pages/organizations/[organizationId]`),
  }),
  lazyRoute({
    title: 'profile',
    path: routes.profile,
    factory: () => import(`pages/profile`),
  }),
  lazyRoute({
    title: 'organization_settings',
    path: routes.organizationSettings,
    factory: () => import(`pages/organization-settings`),
  }),
  lazyRoute({
    title: 'file',
    path: routes.file,
    factory: () => import(`pages/file`),
  }),
  lazyRoute({
    title: 'confirmation',
    path: routes.confirmRegistration,
    factory: () => import(`pages/confirmation/confirm-registration`),
  }),
  lazyRoute({
    title: 'change_email',
    path: routes.confirmEmail,
    factory: () => import(`pages/confirmation/email-change`),
  }),
  lazyRoute({
    title: 'shared',
    path: routes.shared,
    factory: () => import(`pages/shared-file`),
  }),
  lazyRoute({
    title: 'configuration',
    path: routes.configuration,
    factory: () => import(`pages/configuration`),
  }),
]

export const pageRoutesUnauth: Array<LazyRoute> = [
  lazyRoute({
    title: 'sign_in',
    path: routes.signin,
    factory: () => import(`pages/signin`),
  }),
  lazyRoute({
    title: 'integration_callback',
    path: routes.integrationCallback,
    factory: () => import(`pages/integration`),
  }),
  lazyRoute({
    title: 'sign_up',
    path: routes.signup,
    factory: () => import(`pages/signup`),
  }),
  lazyRoute({
    title: 'recover_password',
    path: routes.recoverPassword,
    factory: () => import(`pages/recover-password`),
  }),
  lazyRoute({
    title: 'confirmation',
    path: routes.confirmRegistration,
    factory: () => import(`pages/confirmation/confirm-registration`),
  }),
  lazyRoute({
    title: 'change_password',
    path: routes.confirmPassword,
    factory: () => import(`pages/change-password`),
  }),
  lazyRoute({
    title: 'change_email',
    path: routes.confirmEmail,
    factory: () => import(`pages/confirmation/email-change`),
  }),
  lazyRoute({
    title: 'confirm_invitation',
    path: routes.confirmInvite,
    factory: () => import(`pages/confirm/invitation`),
  }),
  lazyRoute({
    title: 'shared',
    path: routes.shared,
    factory: () => import(`pages/shared-file`),
  }),
]

export const allPageRoutes = [
  ...pageRoutesEditor,
  ...pageRoutesUnauth,
  ...pageRoutesAdmin,
  ...pageRoutesOwner,
]
