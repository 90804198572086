import { Box, MenuItem, Stack, Typography } from '@mui/material'
import flags from 'assets/languages'
import flags2x from 'assets/languages/languages@2x'
import { flagCodes } from 'config/flag-name-code'
import { localizeLanguageName } from 'imgplay-domain/format'
import {
  bindHover,
  bindMenu,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { useTranslation } from 'react-i18next'

type Language = { name: string; code: string }

type Props =
  | {
      languageName?: string
      languageCode?: string
      translations?: undefined
      taskId?: undefined
    }
  | {
      languageName: string
      languageCode: string
      translations: Language[]
      taskId: string
    }

const imgStyles = {
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
} as const

export const LanguageCell = ({
  languageName,
  languageCode,
  translations,
  taskId,
}: Props) => {
  const { i18n, t } = useTranslation()

  const popupState = usePopupState({
    popupId: `translations-${taskId}`,
    variant: 'popover',
  })

  return (
    <Stack direction="column" spacing={0.5} alignItems="center">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {languageName && languageCode && languageCode !== 'none' && (
          <Box
            width="21.517px"
            height="18.207px"
            overflow="hidden"
            borderRadius="3.034px"
            border="0.759px solid #CED7DE"
            boxShadow={'0px 10.621px 31.862px 0px rgba(8, 15, 52, 0.06);'}
          >
            <img
              style={imgStyles}
              loading="lazy"
              // @ts-ignore
              src={flags[flagCodes[languageName]]}
              // @ts-ignore
              srcSet={`${flags2x[flagCodes[languageName]]} 2x`}
              alt={languageCode}
            />
          </Box>
        )}
        <Typography variant="body2">
          {languageCode && languageCode !== 'none' && languageName
            ? localizeLanguageName(i18n.language, languageCode)
            : '—'}
        </Typography>
      </Stack>

      {translations !== undefined && translations.length > 0 && (
        <>
          <Typography
            variant="subtitle2"
            lineHeight="14px"
            {...bindHover(popupState)}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >{`+${translations.length} ${t('common.translations', {
            count: translations.length,
          })}`}</Typography>

          <HoverMenu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: -8,
              horizontal: 'center',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '8px',
                border: '1px solid var(--Lines-Athens-Gray, #E6EAEE)',
                boxShadow: '0px 4px 8px 0px rgba(8, 15, 52, 0.06)',
              },
              '& .MuiList-root': {
                py: 0.5,
              },
            }}
          >
            {translations.map((translation, idx) => (
              <MenuItem
                key={translation.code}
                divider={idx < translations.length - 1}
                sx={{
                  cursor: 'default',
                  '&:hover': {
                    backgroundColor: 'inherit !important',
                  },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Box
                    width="18.909px"
                    height="16px"
                    overflow="hidden"
                    borderRadius="3.034px"
                    border="0.759px solid #CED7DE"
                    boxShadow={
                      '0px 10.621px 31.862px 0px rgba(8, 15, 52, 0.06);'
                    }
                  >
                    <img
                      style={imgStyles}
                      loading="lazy"
                      // @ts-ignore
                      src={flags[flagCodes[translation.name]]}
                      // @ts-ignore
                      srcSet={`${flags2x[flagCodes[translation.name]]} 2x`}
                      alt={translation.code}
                    />
                  </Box>
                  <Typography variant="subtitle2" lineHeight="14px">
                    {localizeLanguageName(i18n.language, translation.code)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </HoverMenu>
        </>
      )}
    </Stack>
  )
}
