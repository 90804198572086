import { Box } from '@mui/material'
import { ReactComponent as AvatarEllipse } from 'assets/icons/avatar-upload.svg'
import { ConfirmationDialog, StyledAvatar } from 'components'
import { useBoolean } from 'lib/use-boolean'
import { useSnackbar } from 'notistack'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

type Props = {
  type: 'view' | 'update'
  defaultAvatarName: string
  size?: number
  src?: string
  square?: boolean
  validate?: (avatar: File) => boolean
  onDeleteAvatar?: () => void
  onUploadAvatar?: (file: File) => void
}

const useStyles = makeStyles()({
  container: {
    borderRadius: '50%',
    cursor: 'pointer',
  },
  input: {
    display: 'none',
    width: '100%',
    height: '100%',
  },
})

export const UploadAvatar = ({
  defaultAvatarName,
  type,
  validate,
  onUploadAvatar,
  src,
  onDeleteAvatar,
  size,
  square,
}: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { classes } = useStyles()
  const confirmDelete = useBoolean(false)

  const defaultAvatar = useMemo(() => {
    return defaultAvatarName.length === 2
      ? defaultAvatarName
      : defaultAvatarName[0]
  }, [defaultAvatarName])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files !== null && type && type === 'update') {
        const avatar = e.target.files[0]

        const isValidAvatar = validate && validate(avatar)

        if (isValidAvatar && onUploadAvatar) {
          return onUploadAvatar(avatar)
        }

        enqueueSnackbar(t('error_notification.error_avatar_limit'), {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar, onUploadAvatar, t, type, validate],
  )

  if (type && type === 'update') {
    return (
      <>
        <Box position="relative" width="80px" height="80px">
          <label className={classes.container} htmlFor="upload">
            <StyledAvatar
              sx={{
                width: '136px',
                height: '136px',
                textTransform: 'uppercase',
              }}
              src={src}
            >
              {defaultAvatar}
            </StyledAvatar>
            <input
              onChange={onChange}
              id="upload"
              className={classes.input}
              type="file"
            />
            <Box
              position="absolute"
              width="40px"
              height="40px"
              left="91px"
              top="95px"
            >
              <AvatarEllipse />
            </Box>
          </label>
        </Box>

        <ConfirmationDialog
          title={t('delete_avatar_dialog.title')}
          confirmationLabel={t('buttons.delete')}
          confirmationMessage={t('delete_avatar_dialog.message')}
          onConfirm={() => {
            if (onDeleteAvatar) {
              onDeleteAvatar()
              confirmDelete.setFalse()
            }
          }}
          open={confirmDelete.isTrue}
          onClose={confirmDelete.setFalse}
        />
      </>
    )
  }
  return (
    <>
      <Box
        position="relative"
        sx={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <StyledAvatar
          sx={{
            width: `${size}px`,
            height: `${size}px`,
            textTransform: 'uppercase',
          }}
          variant={square ? 'rounded' : 'circular'}
          src={src}
        >
          {defaultAvatar}
        </StyledAvatar>
      </Box>
    </>
  )
}
