import { MouseEventHandler, useCallback, useRef } from 'react'

export const useSingleAndDoubleClicks = ({
  onClick,
  onDoubleClick,
}: {
  onClick: (event: any) => void
  onDoubleClick?: (event: any) => void
}) => {
  const timer = useRef<number | null>(null)

  const cancelPendingClick = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }, [timer])

  /**
   * This handler must delay long enough to be sure a second click will not trigger a double-click.
   * According to Microsoft, in Windows the max delay between clicks for a double-click is 500ms.
   * Other operating systems can have other delays, and most have customizable settings.
   */
  const handleClick: MouseEventHandler<any> = useCallback(
    event => {
      // We only cache the most recent click event, so cancel any pending clicks
      cancelPendingClick()
      timer.current = window.setTimeout(() => {
        timer.current = null
        onClick(event)
      }, 200)
    },
    [timer, cancelPendingClick, onClick],
  )

  const handleDoubleClick: MouseEventHandler<any> = useCallback(
    event => {
      cancelPendingClick()
      onDoubleClick?.(event)
    },
    [cancelPendingClick, onDoubleClick],
  )

  return { handleClick, handleDoubleClick }
}
