import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Button } from '../Button'

type Props = {
  title: string
  confirmationMessage: string
  cancelLabel?: string
  confirmationLabel: string
  onConfirm: () => void
  onClose: () => void
  open: boolean
}

export const ConfirmationDialog = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog maxWidth="xs" open={props.open} onClose={props.onClose}>
      <DialogTitle
        sx={{ color: 'black', padding: theme => theme.spacing(3, 3, 0.5, 3) }}
      >
        <strong>{props.title}</strong>
      </DialogTitle>
      <DialogContent sx={{ padding: theme => theme.spacing(0.5, 3, 5, 3) }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>{props.confirmationMessage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid justifyContent="space-between" container>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={props.onClose}
                  sx={{ width: '124px', textTransform: 'capitalize' }}
                >
                  {props.cancelLabel || t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={props.onConfirm}
                  sx={{ width: '124px', textTransform: 'capitalize' }}
                >
                  {props.confirmationLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
