import { union } from 'io-ts'

import {
  UserAlreadyConfirmedErrorCodeCodec,
  UserNotFoundErrorCodeCodec,
} from '../errors'

export const ConfirmRegistrationErrorCodeCodec = union([
  UserAlreadyConfirmedErrorCodeCodec,
  UserNotFoundErrorCodeCodec,
])
