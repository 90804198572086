import { Dialog, Fade } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  onClose: () => void
  open: boolean
  children: ReactNode
}

export const Modal = ({ open, children, onClose }: Props) => {
  return (
    <Dialog
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        outline: 'none',
      }}
      open={open}
      onClose={onClose}
      closeAfterTransition
      TransitionComponent={Fade}
      maxWidth="md"
      scroll="paper"
      slotProps={{
        backdrop: {
          timeout: 300,
        },
      }}
      fullWidth
    >
      {children}
    </Dialog>
  )
}
