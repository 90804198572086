import { formatDuration as dateFnsFormatDuration } from 'date-fns'

export const formatDuration = (duration: number | null) => {
  if (duration) {
    const sec = Number(duration)
    const hours = Math.floor(sec / 3600)
    const minutes = Math.floor((sec - hours * 3600) / 60)
    const seconds = sec - hours * 3600 - minutes * 60

    const res = dateFnsFormatDuration({
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: Number(seconds),
    })
      .replace(' seconds', 's')
      .replace(' minutes', 'm')
      .replace(' hours', 'h')
      .replace(' second', 's')
      .replace(' minute', 'm')
      .replace(' hour', 'h')

    return res
  }

  return '0'
}

export const formatConfidenceScore = (score: number | null) => {
  if (score) {
    const percentage = Number((score * 100).toFixed(1))
    return percentage + '% '
  }

  return '—'
}

export const formatFileName = (originalName: string): string => {
  try {
    decodeURIComponent(originalName)
  } catch {
    return originalName
  }

  return decodeURIComponent(originalName)
}

export const localizeLanguageName = (
  appLanguage: string,
  languageCode: string,
) => {
  const localization = new Intl.DisplayNames(appLanguage, {
    type: 'language',
  })

  try {
    localization.of(languageCode)
  } catch {
    return languageCode
  }

  return localization.of(languageCode)
}
