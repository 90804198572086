import EditIcon from '@mui/icons-material/Edit'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material'
import { IIntegrationStatus, IIntegrationType } from 'app/codecs'
import { ReactComponent as ActivateIcon } from 'assets/icons/activate.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/ashtray.svg'
import { ReactComponent as BrightcoveIcon } from 'assets/icons/brightcove.svg'
import { ReactComponent as IbmIcon } from 'assets/icons/ibm.svg'
import { ReactComponent as KalturaIcon } from 'assets/icons/kaltura.svg'
import { ReactComponent as LanguageConfiguration } from 'assets/icons/language-configuration.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/scheduled.svg'
import { ReactComponent as SynchronizeIcon } from 'assets/icons/synchronize.svg'
import { ReactComponent as TwentythreeIcon } from 'assets/icons/twentythree.svg'
import { ReactComponent as ZoomIcon } from 'assets/icons/zoom.svg'
import { Tooltip } from 'components'
import { integrationTypeMapping } from 'config/integration-options'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const avatars: Record<string, any> = {
  '23v': <TwentythreeIcon />,
  kaltura: <KalturaIcon />,
  google: (
    <YouTubeIcon
      color="error"
      fontSize="medium"
      sx={{ height: 72, width: 72 }}
    />
  ),
  ibm: <IbmIcon />,
  brightcove: <BrightcoveIcon />,
  zoom: <ZoomIcon />,
}

const useStyles = makeStyles()({
  wrapperDiv: {
    '&:hover .iconsBox': {
      opacity: 1,
    },
  },
  iconsBox: {
    height: 48,
    opacity: 0,
    transition: 'opacity .4s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 6,
    position: 'absolute',
    top: 0,
    right: 0,
    '& .MuiIconButton-root': {
      width: 40,
      height: 40,
    },
  },
})

type IntegrationProps =
  | {
      edit: 'view'
      type: string
      data: {
        type: IIntegrationType
        createdAt: Date
        status: IIntegrationStatus
      }
      onOpenModal: () => void
    }
  | {
      edit: 'edit'
      type: string
      onActivateIntegrationClick: () => void
      onEditIntegrationClick: () => void
      onDeleteIntegration: () => void
      onOpenModal: () => void
      onSyncYoutubeIntegration: () => void
      data: {
        type: string
        createdAt: Date
        status: IIntegrationStatus
      }
    }

const DisabledBlock = () => (
  <Box
    sx={{
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      zIndex: 1,
    }}
  />
)
export const IntegrationBlock = (props: IntegrationProps) => {
  const { createdAt } = props.data || {
    createdAt: new Date(),
  }
  const { classes } = useStyles()
  const isEnabled = Boolean(props.data)
  const isActive = isEnabled && props.data.status === 'ACTIVE'

  const { t } = useTranslation()

  const integrationName = integrationTypeMapping[props.type as IIntegrationType]

  return (
    <Box
      sx={{
        boxShadow: '-1px 2px 8px rgba(8, 15, 52, 0.2)',
        borderRadius: 1.5,
        pt: 8,
        pb: 3,
        position: 'relative',
        width: 284,
        height: 254,
      }}
      className={classes.wrapperDiv}
    >
      <Stack display="flex" flexDirection="column" mb={5}>
        <Box className={`${classes.iconsBox} iconsBox`}>
          {isEnabled && !isActive && (
            <Tooltip
              placement="top"
              title={
                <Typography variant="caption">
                  {t('integrations.needs_authorization')}
                </Typography>
              }
            >
              <span>
                <IconButton disabled>
                  <WarningIcon style={{ width: '40px' }} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {props.type === 'google' && props.edit === 'edit' && isEnabled && (
            <Tooltip
              placement="top"
              title={
                <Typography variant="caption">
                  {t('integrations.synchronize')}
                </Typography>
              }
            >
              <IconButton onClick={props.onSyncYoutubeIntegration}>
                <SynchronizeIcon />
              </IconButton>
            </Tooltip>
          )}
          {props.edit === 'view' &&
            (props.type === 'kaltura' || props.type === 'brightcove') &&
            props.data !== undefined && (
              <Tooltip
                placement="top"
                title={
                  <Typography variant="caption">
                    {t('integrations.language_configuration')}
                  </Typography>
                }
              >
                <IconButton onClick={props.onOpenModal}>
                  <LanguageConfiguration style={{ color: '#37637E' }} />
                </IconButton>
              </Tooltip>
            )}
          {props.edit === 'edit' && isEnabled ? (
            <Box display="flex" justifyContent="flex-end" pr={1}>
              {(props.type === 'kaltura' || props.type === 'brightcove') && (
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="caption">
                      {t('integrations.language_configuration')}
                    </Typography>
                  }
                >
                  <IconButton onClick={props.onOpenModal}>
                    <LanguageConfiguration style={{ color: '#37637E' }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                placement="top"
                title={
                  <Typography variant="caption">{t('buttons.edit')}</Typography>
                }
              >
                <IconButton onClick={props.onEditIntegrationClick}>
                  <EditIcon style={{ color: '#37637E' }} />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="top"
                title={
                  <Typography variant="caption">
                    {t('buttons.delete')}
                  </Typography>
                }
              >
                <IconButton onClick={props.onDeleteIntegration}>
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            props.edit === 'edit' && (
              <IconButton onClick={props.onActivateIntegrationClick}>
                <ActivateIcon />
              </IconButton>
            )
          )}
        </Box>
        <Box
          sx={{
            margin: '0 auto',
            position: 'relative',
            filter: isEnabled ? undefined : 'grayscale(1)',
          }}
        >
          {!isEnabled && <DisabledBlock />}
          <Avatar
            sx={{
              width: 72,
              height: 72,
              backgroundColor: 'transparent',
              border: ({ palette }) => `1px solid ${palette.info.light}`,
              borderRadius: 1,
              margin: '0 auto',
            }}
          >
            {avatars[props.type]}
          </Avatar>
          <Typography
            align="center"
            variant="h2"
            style={{
              fontWeight: 'bold',
              marginTop: 16,
              textTransform: 'capitalize',
              textShadow: '0.1px 1px -0.1px black',
            }}
          >
            {integrationName}
          </Typography>
        </Box>
      </Stack>
      {isEnabled && (
        <Typography
          align="center"
          variant="subtitle1"
          sx={{
            color: 'text.disabled',
            fontWeight: 900,
            textTransform: 'uppercase',
          }}
        >
          {t('common.added')}: {format(createdAt, 'dd MMMM yyyy')}
        </Typography>
      )}
    </Box>
  )
}
