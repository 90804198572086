import { Menu } from '@mui/icons-material'
import { Box, Grid, Hidden, IconButton } from '@mui/material'
import { ReactComponent as Logo } from 'assets/icons/main-logo.svg'

import { nav } from '../AppUnauthorizedLayout/ResponsiveAppBar'

type Props = {
  toggleDrawer?: () => void
}

export const ContentMenu = (props: Props) => {
  return (
    <Grid container>
      <Hidden mdUp>
        <IconButton
          onClick={props.toggleDrawer}
          sx={{ marginTop: theme => theme.spacing(0.5), color: 'white' }}
        >
          <Menu />
        </IconButton>
      </Hidden>

      <Hidden lgDown>
        <Box
          onClick={nav}
          sx={{ marginTop: 'auto', marginBottom: 'auto', cursor: 'pointer' }}
        >
          <Logo />
        </Box>
      </Hidden>
    </Grid>
  )
}
