export const formatSeconds = (seconds: number | string) =>
  new Date(Number(Number.parseFloat(String(seconds)).toFixed(3)) * 1000)
    .toISOString()
    .slice(11, 23)

export const formatTimecodesInSrtFormat = (seconds: string) =>
  formatSeconds(Number.parseFloat(seconds)).replace('.', ',')

export const formatTimeForFiles = (start: number) =>
  formatTimecodesInSrtFormat(String(start)).split(',')[0]
