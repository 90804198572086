import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    customRed: {
      active: string
      hover: string
      disabled: string
      focus: string
    }
    externalEditor: {
      icon: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customRed: {
      active: string
      hover: string
      disabled: string
      focus: string
    }
    externalEditor: {
      icon: string
    }
  }
}
const palette = {
  accent: {
    accent: '#C1121F',
  },
  fonts: {
    blue: '#003049',
    ming: '#37637E',
    hai: '#87A1B2',
  },
  background: {
    white: '#FFFFFF',
    blue: '#003049',
  },
  lines: {
    white: '#F3F7F9',
    loblolly: '#CED7DE',
  },
  secondary: {
    malibu: '#8FA0FA',
    rico: '#4AC398',
    jaffa: '#F2994A',
  },
  error: {
    main: '#E03C31',
    dark: '#c1121f',
  },
}

export const muiTheme = createTheme({
  customRed: {
    active: '#C1121F',
    hover: '#E31524',
    disabled: '#C1121F',
    focus: '#A9101B',
  },
  // There is no such color in design palette.
  // This color added because of external slate-transcript-editor has own icon colors and we need to add optional icon.
  externalEditor: {
    icon: '#3f51b5',
  },
  palette: {
    primary: {
      main: palette.fonts.blue,
    },
    secondary: {
      light: palette.secondary.jaffa,
      main: palette.secondary.malibu,
      dark: palette.secondary.rico,
    },
    error: {
      main: palette.error.main,
      dark: palette.error.dark,
    },
    text: {
      primary: palette.fonts.blue,
      secondary: palette.fonts.ming,
      disabled: palette.fonts.hai,
    },
    background: {
      default: palette.background.white,
    },
    info: {
      main: palette.lines.loblolly,
      light: palette.lines.white,
    },
  },
  typography: {
    h1: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontSize: '32px',
      fontWeight: 500,
      color: palette.fonts.blue,
    },
    h2: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontSize: '24px',
      fontWeight: 900,
      color: palette.fonts.blue,
    },
    h3: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontSize: '20px',
      fontWeight: 500,
      color: palette.fonts.blue,
    },
    h4: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontSize: '14px',
      fontWeight: 400,
      color: palette.fonts.ming,
    },
    body1: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontWeight: 500,
      fontSize: '16px',
      color: palette.fonts.blue,
    },
    body2: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '22px',
      color: palette.fonts.blue,
    },

    subtitle1: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontSize: '10px',
      fontWeight: 900,
      color: palette.fonts.blue,
    },
    subtitle2: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      fontSize: '12px',
      fontWeight: 500,
      color: palette.fonts.blue,
    },
    caption: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      color: 'white',
      fontSize: '12px',
      fontWeight: 400,
    },
    button: {
      fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
      color: 'white',
      fontSize: '16px',
      fontWeight: 900,
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '16px',
          color: palette.fonts.blue,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          color: '#003049',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: 'grey',
        },
        underline: 'grey',
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '16px',
          color: palette.fonts.blue,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          underline: 'grey',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: palette.secondary.malibu,
          height: 2,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 1,
          minWidth: 0,
          fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
          fontSize: 16,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          color: palette.fonts.ming,
        },
        clearIndicator: {
          color: palette.fonts.ming,
        },
        endAdornment: {
          color: palette.fonts.ming,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: palette.fonts.ming,
          '&.MuiFormHelperText-root.Mui-error': {
            color: palette.error.dark,
          },
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 50,
    },
  },
})
