import { Grid } from '@mui/material'
import { FormModal, FormPasswordInput } from 'components/Form'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type Props = {
  onClose: () => void
  open: boolean
}

type FormValues = {
  password: string
  newPassword: string
  confirmPassword: string
}

export const UpdatePasswordModal = (props: Props) => {
  const $changePassword = useMutation('PATCH', '/api/users/me/password')

  const { enqueueSnackbar } = useSnackbar()

  const { t } = useTranslation()
  const { isLoading, mutate } = $changePassword

  const onSubmit = (data: FormValues) => {
    const { newPassword, confirmPassword, password } = data

    if (newPassword !== confirmPassword) {
      return enqueueSnackbar(t('error_notification.error_password_mismatch'), {
        variant: 'error',
      })
    }

    mutate(
      {
        body: {
          password,
          newPassword,
        },
      },
      {
        onSuccess: () => {
          props.onClose()
          enqueueSnackbar(t('success_notification.password_was_changed'), {
            variant: 'success',
          })
        },
        onError: error => {
          if (error.type === 'client_error') {
            enqueueSnackbar(t(`error_notification.${error.code}`), {
              variant: 'error',
            })
          }

          if (error.type === 'server_error') {
            enqueueSnackbar(t(`server_error.${error.status}`), {
              variant: 'error',
            })
          }
        },
      },
    )
  }

  return (
    <FormModal<FormValues>
      onSubmit={onSubmit}
      title={t('labels.update_password')}
      submitTitle={t('buttons.save')}
      onClose={props.onClose}
      open={props.open}
      sx={{ marginTop: 4 }}
      isLoading={isLoading}
      renderForm={({ control }) => (
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <FormPasswordInput
              control={control}
              label={t('Inputs.old_password')}
              name="password"
              required
              fullWidth
              placeholder={t('Inputs.old_password')}
            />
          </Grid>
          <Grid item xs={12} mt={4}>
            <FormPasswordInput
              control={control}
              label={t('Inputs.new_password')}
              name="newPassword"
              required
              fullWidth
              placeholder={t('Inputs.new_password')}
            />
          </Grid>
          <Grid item xs={12} mt={4}>
            <FormPasswordInput
              control={control}
              label={t('Inputs.confirm_new_password')}
              name="confirmPassword"
              required
              fullWidth
              placeholder={t('Inputs.confirm_new_password')}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
