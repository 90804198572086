import {
  Autocomplete,
  Chip,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Roles } from 'app/codecs'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { TableBodyCell } from 'components'
import { Button } from 'components/Button'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { FormInput } from 'components/Form'
import { Switch } from 'components/Switch'
import { InformationWidget } from 'components/Widgets'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type FormValues = {
  language: string
  name: string
  isDefault: boolean
  general: boolean
  content: string
  phrases: {
    content: string
    soundsLike: string[]
  }[]
}

type Props = {
  form: UseFormReturn<FormValues>
  mode: 'add' | 'edit'
  languages: {
    [languageName: string]: string
  }
  role: Roles
}

export const CustomDictionaryForm = ({
  form,
  languages,
  role,
  mode,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { control, watch } = form

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'phrases',
  })

  const isGeneral = watch('general')

  // react-hook-form convert uncontrolled fields from useFieldArray to controlled ones
  const watchFieldArray = watch('phrases')
  const phrases = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })

  const MAX_SOUND_LIKES = 5

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-end"
      onKeyDown={event => {
        if (event.key === ' ') {
          event.stopPropagation()
        }
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">
          {t('custom_dictionary.modal_subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormInput
          type="filled"
          control={control}
          label={`${t('custom_dictionary.name')}*`}
          name="name"
          required
          fullWidth
          minLength={3}
          maxLength={50}
          placeholder={t('custom_dictionary.name')}
          disabled={mode === 'edit' && role !== 'OWNER' && isGeneral}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" mb={0.5}>{`${t(
          'integrations.language',
        )}*`}</Typography>
        <ControlledAutocomplete
          control={control}
          placeholder={t('integrations.language')}
          name="language"
          options={Object.keys(languages).map(key => ({
            label: languages[key],
            value: key,
          }))}
          sx={{
            '.MuiInput-root:hover:not(.Mui-disabled):before': {
              borderBottom: '1px solid #CED7DE',
            },
          }}
          disabled={mode === 'edit' && role !== 'OWNER' && isGeneral}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="isDefault"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Switch
                onChange={checked => {
                  onChange(checked)
                }}
                isChecked={value}
                label={
                  value
                    ? t('custom_dictionary.active')
                    : t('custom_dictionary.inactive')
                }
                labelPlacement="end"
                disabled={mode === 'edit' && role !== 'OWNER' && isGeneral}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="general"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Switch
                onChange={checked => {
                  onChange(checked)
                }}
                isChecked={value}
                label={
                  value
                    ? t('custom_dictionary.global')
                    : t('custom_dictionary.not_global')
                }
                labelPlacement="end"
                disabled={mode === 'edit' && role !== 'OWNER'}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button
            noSpacing
            withShadow
            onClick={() => {
              prepend({ content: '', soundsLike: [] })
            }}
            color="primary"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            disabled={mode === 'edit' && role !== 'OWNER' && isGeneral}
          >
            {t('custom_dictionary.add_phrase')}
          </Button>
          <InformationWidget
            value={`${phrases.length}\u00A0`}
            info={t('custom_dictionary.total_phrases') + ':'}
          />
        </Stack>
      </Grid>
      <Stack
        mb={3}
        pl={2}
        direction="column"
        flexWrap="wrap"
        alignItems="space-between"
        width={'100%'}
      >
        {phrases.length > 0 && (
          <TableContainer>
            <Table
              sx={{
                whiteSpace: 'nowrap',
                borderCollapse: 'separate',
                borderSpacing: '0px 4px',
              }}
            >
              <TableHead>
                <TableRow sx={{ borderRadius: '8px', borderBottom: '0px' }}>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      paddingX: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      textTransform="capitalize"
                    >
                      {t('custom_dictionary.phrase')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      paddingX: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      textTransform="capitalize"
                    >
                      {t('custom_dictionary.sounds_like')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      paddingX: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      textTransform="capitalize"
                      align="center"
                    >
                      {t('tables.action')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {phrases.map((phrase, idx) => (
                  <TableRow
                    key={phrase.id}
                    sx={{
                      background: '#FFFFFF',
                      border: '3px solid #000000',
                      boxSizing: 'border-box',
                      boxShadow: '0px 4px 8px rgba(8, 15, 52, 0.06)',
                      borderRadius: '8px',
                      minHeight: '72px',
                      maxHeight: '92px',
                      padding: '8px',
                      'td:first-of-type': {
                        paddingLeft: 1,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 1,
                      },
                    }}
                  >
                    <TableBodyCell>
                      <FormInput
                        control={control}
                        name={`phrases.${idx}.content`}
                        validate={{
                          [`phrases.${idx}.content`]: value => {
                            const duplicates =
                              phrases.filter(item => item.content === value)
                                .length > 1

                            if (duplicates) {
                              return t('custom_dictionary.phrase_already_added')
                            }
                          },
                        }}
                        type="filled"
                        required
                        maxLength={50}
                        placeholder={t('custom_dictionary.enter_phrase')}
                        disabled={
                          mode === 'edit' && role !== 'OWNER' && isGeneral
                        }
                      />
                    </TableBodyCell>
                    <TableBodyCell>
                      <Controller
                        control={control}
                        name={`phrases.${idx}.soundsLike` as const}
                        render={({ field }) => (
                          <Autocomplete
                            open={false}
                            forcePopupIcon={false}
                            multiple
                            freeSolo
                            options={[]}
                            limitTags={
                              field.value.length < MAX_SOUND_LIKES
                                ? 2
                                : undefined
                            }
                            onChange={(_e, value) => field.onChange(value)}
                            value={field.value}
                            fullWidth
                            sx={{
                              width: 264,
                            }}
                            disabled={
                              field.value.length >= MAX_SOUND_LIKES ||
                              (mode === 'edit' && role !== 'OWNER' && isGeneral)
                            }
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  disabled={
                                    mode === 'edit' &&
                                    role !== 'OWNER' &&
                                    isGeneral
                                  }
                                  key={index}
                                  sx={{ height: 27 }}
                                  label={option}
                                />
                              ))
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  sx: {
                                    '& .MuiInputBase-root': {
                                      'label + &': {
                                        marginTop: theme.spacing(2),
                                      },
                                      borderBottom: '1px solid #CED7DE',
                                    },
                                    '& .Mui-error': {
                                      borderBottom: `2px solid ${theme.palette.error.main}`,
                                    },
                                    '& .MuiInputBase-input': {
                                      color: '#003049',

                                      position: 'relative',
                                      fontSize: theme.typography.pxToRem(16),
                                      height: 27,
                                    },
                                  },
                                }}
                                margin="none"
                                size="small"
                                variant="standard"
                                placeholder={
                                  field.value.length === 0
                                    ? t(
                                        'custom_dictionary.sounds_like_placeholder',
                                      )
                                    : undefined
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </TableBodyCell>
                    <TableBodyCell>
                      <IconButton
                        onClick={() => {
                          remove(idx)
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        disabled={
                          mode === 'edit' && role !== 'OWNER' && isGeneral
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableBodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Grid>
  )
}
