import { Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { ReactComponent as ErrorModalIcon } from 'assets/icons/error-modal-icon.svg'
import { ReactComponent as SuccessModalIcon } from 'assets/icons/success-modal-icon.svg'

import { Button } from '../Button'

type Props = {
  title: string
  text: string
  buttonLabel: string
  open: boolean
  variant: 'error' | 'success'
  onClose: () => void

  onConfirm?: () => void
}

export const NotificationAlertDialog = (props: Props) => {
  return (
    <Dialog maxWidth="xs" open={props.open} onClose={props.onClose}>
      <DialogContent sx={{ padding: theme => theme.spacing(8, 3, 5, 3) }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            {props.variant === 'success' ? (
              <SuccessModalIcon />
            ) : (
              <ErrorModalIcon />
            )}
          </Grid>
          <Grid item xs={12} alignItems="center" justifyContent="center">
            <Typography variant="h2" align="center">
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent="center" alignContent="center">
            <Typography align="center">{props.text}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid justifyContent="center" alignItems="center" container>
              <Button
                variant="contained"
                onClick={() => {
                  props.onClose()
                  props.onConfirm?.()
                }}
                sx={{ width: '124px', textTransform: 'capitalize' }}
              >
                {props.buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
