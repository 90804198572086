import { format, parse } from 'date-fns'
import { ChangeEvent, useCallback } from 'react'
import MaskedInput from 'react-text-mask'

export const getDividerCharacters = () => [' ', '-', '(', ')']

/**
 * The variables below are tightly coupled and should not be changed (or all of them should be changed at the same time).
 */
const dateFormat = 'dd/MM/yyyy'
// eslint-disable-next-line unicorn/better-regex
const regex = /\d\d\/\d\d\/\d\d\d\d/
const mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

type Value = Date | null

type Props = {
  value: Value
  onChange: (value: Value) => void

  id?: string
}

export const DateInput = ({ value, id, onChange }: Props) => {
  const valueAsString = value ? format(value, dateFormat) : ''

  const onChangeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (regex.test(event.target.value)) {
        const date = parse(event.target.value, dateFormat, new Date())
        if (date.getFullYear() > 2015) {
          onChange(date)
        }
      }
    },
    [onChange],
  )

  return (
    <MaskedInput
      mask={mask}
      placeholder={dateFormat.toUpperCase()}
      value={valueAsString}
      onChange={onChangeValue}
      render={(ref, props) => (
        <input
          id={id}
          style={{
            width: '136px',
            border: '1px solid transparent',
            borderBottomColor: '#c4cdd5',
            backgroundColor: '#ffffff',
            lineHeight: '24px',
            fontSize: '16px',
            fontFamily: `'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
            outline: 'none',
            color: '#003049',
          }}
          ref={(node: HTMLInputElement) => {
            ref(node)
          }}
          {...props}
        />
      )}
    />
  )
}
