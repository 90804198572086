import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'

export const ChunksTableLoader = ({ shared }: { shared: boolean }) => (
  <Box maxHeight={`calc(100vh - ${shared ? 460 : 400}px`} overflow="hidden">
    <Table>
      <TableBody>
        {Array.from({ length: 30 }).map((_a, i) => (
          <TableRow key={i}>
            <TableCell width={80}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </TableCell>
            <TableCell>
              <Skeleton animation="wave" height={55} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
)
