import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NoHistoryIcon } from './no-history.svg'

export const NoEntriesPlaceholder = ({ text }: { text?: string }) => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={4}
      pt={12}
      textAlign="center"
      color="textPrimary"
    >
      <Box>
        <NoHistoryIcon width="292px" height="100%" />
      </Box>
      <Typography
        fontSize={14}
        fontWeight={500}
        color="text.secondary"
        lineHeight="22px"
      >
        {text ?? t('file_history.no_actions')}
      </Typography>
    </Box>
  )
}
