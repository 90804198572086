import { LinearProgress } from '@mui/material'
import { IntegrationCodec } from 'app/codecs'
import { Modal } from 'components'
import { useJsonQuery } from 'lib/rest-query'
import { Suspense } from 'react'
import { useLocation } from 'react-router-dom'

import BrightcoveUpdate from './BrightcoveUpdate'
import IbmUpdate from './IbmUpdate'
import KalturaUpdate from './KalturaUpdate'
import ModalWrapper from './ModalWrapper'
import TTVUpdate from './TTVUpdate'
import YouTubeUpdate from './YouTubeUpdate'
import ZoomUpdate from './ZoomUpdate'

type Props = {
  onClose: () => void
  open: boolean
}

const UpdateIntegrationModal = ({
  onClose,
  open,
  id,
}: Props & { id: string }) => {
  const $integration = useJsonQuery(
    'GET',
    `/api/integrations/${id}`,
    IntegrationCodec,
  )

  const { data, refetch } = $integration

  return (
    <Modal open={open} onClose={onClose}>
      {data ? (
        <ModalWrapper>
          <Suspense fallback={<LinearProgress />}>
            {data.type === '23v' && (
              <TTVUpdate
                onClose={onClose}
                integrationData={data}
                refetch={refetch}
              />
            )}
            {data.type === 'kaltura' && (
              <KalturaUpdate
                onClose={onClose}
                integrationData={data}
                refetch={refetch}
              />
            )}
            {data.type === 'google' && (
              <YouTubeUpdate
                onClose={onClose}
                integrationData={data}
                refetch={refetch}
              />
            )}

            {data.type === 'zoom' && (
              <ZoomUpdate
                onClose={onClose}
                integrationData={data}
                refetch={refetch}
              />
            )}
            {data.type === 'ibm' && (
              <IbmUpdate
                onClose={onClose}
                integrationData={data}
                refetch={refetch}
              />
            )}
            {data.type === 'brightcove' && (
              <BrightcoveUpdate
                onClose={onClose}
                integrationData={data}
                refetch={refetch}
              />
            )}
          </Suspense>
        </ModalWrapper>
      ) : (
        <LinearProgress />
      )}
    </Modal>
  )
}

export const UpdateIntegrationRoot = ({ onClose, open }: Props) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const id = query.get('id')
  if (!id) {
    return null
  }
  return <UpdateIntegrationModal onClose={onClose} open={open} id={id} />
}
