import { Grid, Typography } from '@mui/material'
import { Button } from 'components'
import {
  Form,
  FormPasswordInput,
  UnauthorizedFormContainer,
} from 'components/Form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormValues = {
  password: string
}

type Props = {
  label: string
  submitButtonLabel: string
  onSubmit: (values: FormValues) => void
}

export const PasswordForm = ({ onSubmit, label, submitButtonLabel }: Props) => {
  const { t } = useTranslation()
  const form = useForm<FormValues>({
    defaultValues: { password: '' },
  })

  const { control } = form

  return (
    <UnauthorizedFormContainer
      label="Change password"
      helperText={
        <Typography variant="body2" color="textSecondary">
          {t('common.enter_new_password')}
        </Typography>
      }
      sx={{ maxWidth: '400px' }}
    >
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormPasswordInput
              control={control}
              label={t('labels.new_password')}
              name="password"
              required
              fullWidth
              placeholder={t('labels.new_password')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={6} sm={4}>
                <Button
                  withShadow
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </UnauthorizedFormContainer>
  )
}
