import { ControllerFieldState } from 'react-hook-form'

export const textFieldError = (error?: ControllerFieldState['error']) => {
  return error === undefined
    ? {}
    : {
        error: true,
        // eslint-disable-next-line dot-notation
        helperText: error['message'] ?? '',
      }
}
