import { Grid } from '@mui/material'
import { OrganizationCodec } from 'app/codecs'
import { FormInput, FormModal } from 'components/Form'
import { validation } from 'config/validation'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type FormValues = {
  name: string
}

type Props = {
  onClose: () => void
  open: boolean
}

export const EditOrganizationModal = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const $profile = useJsonQuery(
    'GET',
    '/api/organizations/my',
    OrganizationCodec,
  )
  const { t } = useTranslation()
  const { data, refetch } = $profile
  const $updateProfile = useMutation('PUT', '/api/organizations/my')

  if (!data) return null

  const onSubmit = (data: FormValues) => {
    $updateProfile.mutate(
      { body: { name: data.name } },
      {
        onSuccess: () => {
          refetch()
          props.onClose()
          enqueueSnackbar(t('success_notification.organization_was_updated'), {
            variant: 'success',
          })
        },
      },
    )
  }

  return (
    <FormModal<FormValues>
      onSubmit={(values, form) => {
        form.reset(values)
        onSubmit(values)
      }}
      title={t('organization_settings.edit_organization')}
      submitTitle={t('buttons.save')}
      onClose={props.onClose}
      open={props.open}
      isLoading={$updateProfile.isLoading}
      defaultValues={{
        name: data.name,
      }}
      renderForm={({ control }) => (
        <Grid container spacing={4}>
          <Grid item xs={12} mt={1}>
            <FormInput
              label={t('organizations.name')}
              control={control}
              maxLength={validation.organisationFieldMaxLength}
              minLength={validation.commonFieldMinLength}
              name="name"
              required
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
