import { ReadonlyNonEmptyArray } from 'fp-ts/ReadonlyNonEmptyArray'
import { Int, intersection } from 'io-ts'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import {
  QueryDispatch,
  SetStateAction as RRSetStateAction,
} from 'react-router-use-location-state'

import { useLocalStorageValidated, useQueryStateValidated } from '../filters'
import { TPositive } from './brand-positive'

const TPositiveInt = intersection([Int, TPositive])

const isPageValid = (page: number) => {
  return TPositiveInt.is(page)
}

const isRowsPerPageValid = (
  rowsPerPage: number,
  rowsPerPageOptions: ReadonlyNonEmptyArray<number>,
) => {
  return (
    TPositiveInt.is(rowsPerPage) && rowsPerPageOptions.includes(rowsPerPage)
  )
}

type UsePaginationInput = Readonly<{
  initialPage?: number
  initialRowsPerPage?: number
  rowsPerPageOptions?: ReadonlyNonEmptyArray<number>
  rowsPerPageStorageKey: string
}>

type UsePaginationOutput = Readonly<{
  page: number
  setPage: QueryDispatch<RRSetStateAction<number>>
  resetPage: () => void
  rowsPerPage: number
  setRowsPerPage: Dispatch<SetStateAction<number>>
  rowsPerPageOptions: ReadonlyNonEmptyArray<number>
}>

export const usePagination = ({
  initialPage = 1,
  rowsPerPageOptions = [10, 25, 50, 100],
  initialRowsPerPage = rowsPerPageOptions[0],
  rowsPerPageStorageKey,
}: UsePaginationInput): UsePaginationOutput => {
  const defaultPage = isPageValid(initialPage) ? initialPage : 1

  const defaultRowsPerPage = useMemo(
    () =>
      isRowsPerPageValid(initialRowsPerPage, rowsPerPageOptions)
        ? initialRowsPerPage
        : rowsPerPageOptions[0],
    [rowsPerPageOptions, initialRowsPerPage],
  )

  const [page, setPage] = useQueryStateValidated({
    key: 'page',
    defaultValue: defaultPage,
    isValid: isPageValid,
  })

  const resetPage = useCallback(() => {
    setPage(defaultPage)
  }, [setPage, defaultPage])

  const [rowsPerPage, setRowsPerPage] = useLocalStorageValidated({
    key: rowsPerPageStorageKey,
    defaultValue: defaultRowsPerPage,
    isValid: v => isRowsPerPageValid(v, rowsPerPageOptions),
  })

  return {
    page,
    setPage,
    resetPage,
    rowsPerPage,
    setRowsPerPage: useCallback(
      value => {
        setRowsPerPage(value)
        resetPage()
      },
      [resetPage, setRowsPerPage],
    ),
    rowsPerPageOptions,
  }
}
