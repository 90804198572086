import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import { IIntegrationType, IntegrationCodec } from 'app/codecs'
import { Button } from 'components'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { integrationTypeMapping } from 'config/integration-options'
import { TypeOf } from 'io-ts'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ActivateDeactivateSwitch } from '../ActivateDeactivateSwitch'
import DisabledField from '../DisabledField'

type Props = {
  integrationData: TypeOf<typeof IntegrationCodec>
  languages: {
    [languageName: string]: string
  }
  onSubmitForm: (values: {
    language: string
    subtitle: boolean
    sendInvite: boolean
    autoProcess: boolean
  }) => Promise<void>
  onClose: () => void
}

type FormFields = {
  subtitle: boolean
  language: string
  sendInvite: boolean
  autoProcess: boolean
}

const ZoomUpdateForm = ({
  integrationData,
  languages,
  onSubmitForm,
  onClose,
}: Props) => {
  const { t } = useTranslation()

  const { control, handleSubmit, watch } = useForm<FormFields>({
    defaultValues: {
      subtitle: integrationData.subtitle,
      language: integrationData.language,
      sendInvite: integrationData.sendInvite,
      autoProcess: integrationData.autoProcess,
    },
  })

  const language = watch('language')

  const submitButtonIsDisabled = !language

  return (
    <form
      onSubmit={handleSubmit(values => {
        const { language, subtitle, sendInvite, autoProcess } = values
        onSubmitForm({
          language: language,
          subtitle: subtitle,
          sendInvite,
          autoProcess,
        })
      })}
    >
      <Stack spacing={2}>
        <DisabledField
          title={t('labels.type_of_integration')}
          value={
            integrationTypeMapping[integrationData.type as IIntegrationType]
          }
        />

        <ControlledAutocomplete
          control={control}
          label={t('integrations.choose_default_language')}
          name="language"
          options={Object.keys(languages).map(key => ({
            label: languages[key],
            value: key,
          }))}
          required
        />
        <Stack>
          <ActivateDeactivateSwitch control={control} />

          <Controller
            name="sendInvite"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label={`${t('integrations.send_invite')}`}
                control={
                  <Checkbox
                    color="primary"
                    {...field}
                    size="small"
                    checked={field.value}
                  />
                }
              />
            )}
          />
          <Controller
            name="autoProcess"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label={`${t('integrations.auto_process_media')}`}
                control={
                  <Checkbox
                    color="primary"
                    {...field}
                    size="small"
                    checked={field.value}
                  />
                }
              />
            )}
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Button
            noSpacing
            variant="text"
            onClick={onClose}
            sx={{ textTransform: 'capitalize' }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            noSpacing
            withShadow
            type="submit"
            color="primary"
            variant="contained"
            disabled={submitButtonIsDisabled}
            sx={{ textTransform: 'capitalize' }}
          >
            {t('common.update')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default ZoomUpdateForm
