import { Box, Typography } from '@mui/material'
import { useAuthContext } from 'app/auth'
import { routes } from 'app/routes'
import background from 'assets/images/not-found.png'
import { Button } from 'components/Button'
import { LinkPrefetch } from 'components/InternalLink/InternalLink'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  label?: string
  text?: string

  button?: ReactNode
}

export const NotFound = (props: Props) => {
  const { t } = useTranslation()
  const { accessToken } = useAuthContext()
  if (!accessToken?.role) return null
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={4}
      pt={12}
      textAlign="center"
      color="textPrimary"
    >
      <Box
        mb={4}
        sx={{
          width: '320px',
          height: '213.96px',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Typography variant="h2" fontWeight={700} gutterBottom>
        {props.label || t('unauthorized.page_not_found')}
      </Typography>
      <Typography variant="h4" width={'312px'} mt={1.5}>
        {props.text || t('unauthorized.page_not_found_subtext')}
      </Typography>
      <Box mt={2}>
        {props.button || (
          <Button
            component={LinkPrefetch}
            sx={{ textTransform: 'capitalize' }}
            to={
              accessToken.role === 'SYSTEM_ADMIN'
                ? routes.dashboard
                : routes.myFiles
            }
            variant="contained"
          >
            {accessToken.role === 'SYSTEM_ADMIN'
              ? t('pages.dashboard')
              : t('pages.my_files')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
