import * as t from 'io-ts'
import { DateFromISOString } from 'lib/io-ts-codecs/DateFromISOString'

export const CreditsListCodecs = t.type({
  transactionId: t.string,
  transactionType: t.union([
    t.literal('ADD'),
    t.literal('SPEND'),
    t.literal('REDUCE'),
    t.literal('SPEND_TRANSLATION'),
  ]),
  creditsNumber: t.number,
  createdAt: DateFromISOString,
})

export const CreditTransferResultCodec = t.type({ creditsNumber: t.number })

export const CreditsCodec = t.number

export const CreditsFull = t.array(CreditsListCodecs)

export type CreditFull = t.TypeOf<typeof CreditsListCodecs>
