import { FormControl, FormHelperText, IconButton } from '@mui/material'
import { ReactComponent as Hide } from 'assets/icons/hide.svg'
import { ReactComponent as Visibility } from 'assets/icons/visibility.svg'
import { validation } from 'config/validation'
import { useState } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from '../../Input'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  control: Control<TFieldValues>
  label: string
  name: Path<TFieldValues>
  required: boolean
  placeholder?: string
  fullWidth?: boolean
}

export const FormPasswordInput = <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: Props<TFieldValues>,
) => {
  const [type, setType] = useState<string>('password')
  const { t } = useTranslation()

  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: {
      required: {
        value: props.required,
        message: t('validation.field_is_required'),
      },
      pattern: {
        value: validation.passwordRegex,
        message: t('validation.password_regex'),
      },
      minLength: {
        value: validation.passwordMinLength,
        message: t('validation.password_min_length', {
          min: validation.passwordMinLength,
        }),
      },
      maxLength: {
        value: validation.passwordMaxLength,
        message: t('validation.password_max_length', {
          max: validation.passwordMaxLength,
        }),
      },
    },
  })

  const onChangeInputType = () =>
    setType(prevValue => (prevValue === 'password' ? 'text' : 'password'))

  const isError = Boolean(error)

  return (
    <FormControl
      required={props.required}
      fullWidth={props.fullWidth}
      error={isError}
      variant="standard"
    >
      <Input
        type={type}
        error={isError}
        placeholder={props.placeholder}
        ref={ref}
        endAdornment={
          <IconButton
            style={{ marginRight: '14px', padding: '8px' }}
            onClick={onChangeInputType}
          >
            {type === 'password' ? (
              <Hide height="16px" />
            ) : (
              <Visibility height="16px" />
            )}
          </IconButton>
        }
        {...inputProps}
      />
      {error && error.message && (
        <FormHelperText error={false} sx={{ mt: '10px' }}>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}
