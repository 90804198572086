import {
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { ReactComponent as Dropdown } from 'assets/icons/drop-down.svg'
import { forwardRef, HTMLAttributes, ReactNode, SyntheticEvent } from 'react'

type Option = {
  value: string
  label: string
}

type Props = {
  value: string | null
  onChange: (value: string | null) => void

  label?: string
  variant?: TextFieldProps['variant']
  size?: TextFieldProps['size']
  required?: boolean
  placeholder?: string
  noAdornment?: boolean
  options: Array<Option>
  errorText?: string
  loading?: boolean
  disabled?: boolean
  loadingText?: ReactNode
  noOptionsText?: ReactNode
  onBlur?: () => void
  filterOptions?: (
    options: Option[],
    state: FilterOptionsState<Option>,
  ) => Option[]
  onInputChange?: (
    event: SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState,
  ) => ReactNode
  getOptionLabel?: (option: Option) => string
}

export const AutocompleteSingle = forwardRef<unknown, Props>((props, ref) => {
  const selectedOption = props.options.find(
    option => option.value === props.value,
  )

  return (
    <Autocomplete
      ref={ref}
      options={props.options}
      value={selectedOption ?? null}
      onChange={(_event, option) => {
        props.onChange(option?.value ?? null)
      }}
      popupIcon={props.noAdornment ? '' : <Dropdown />}
      onBlur={props.onBlur}
      getOptionLabel={props.getOptionLabel ?? (option => option.label)}
      renderOption={props.renderOption}
      loading={props.loading}
      loadingText={props.loadingText}
      noOptionsText={props.noOptionsText}
      filterOptions={props.filterOptions}
      onInputChange={props.onInputChange}
      fullWidth
      renderInput={params => (
        <TextField
          {...params}
          variant={props.variant}
          margin="none"
          label={props.label}
          required={props.required}
          error={props.errorText !== undefined}
          helperText={props.errorText}
          placeholder={props.placeholder}
          size={props.size}
          InputProps={{
            ...params.InputProps,
            sx: {
              fontWeight: 400,
              pt: '4px',
              '&:before': {
                borderBottomColor: '#CED7DE',
                '&:hover': {
                  borderBottomColor: '#CED7DE',
                },
              },
            },
          }}
        />
      )}
    />
  )
})
