import { ButtonBase, Tooltip } from '@mui/material'
import { ReactComponent as SynchronizeIcon } from 'assets/icons/synchronize-subtitles.svg'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const SynchronizeButton = ({
  taskId,
  edited,
  type,
  translation,
  onReset,
}: {
  taskId: string
  edited: boolean
  type: 'integration' | 'player'
  translation: string | null
  onReset: () => void
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const search = useMemo(() => {
    const params = new URLSearchParams({})

    if (translation) {
      params.set('language', translation)
    }

    return params
  }, [translation])

  const $sync = useMutation(
    'PATCH',
    `/api/tasks/:taskId/sync/${
      type === 'integration' ? 'subtitles' : 'player-subtitles'
    }`,
  )

  if (!edited) {
    return null
  }

  return (
    <Tooltip title={t('common.synchronize')}>
      <span>
        <ButtonBase
          sx={{ padding: '10px', borderRadius: '43px' }}
          disabled={$sync.isLoading}
          onClick={() =>
            $sync.mutate(
              {
                params: {
                  taskId,
                },
                search,
              },
              {
                onSuccess: () => {
                  onReset()
                },
                onError: () => {
                  enqueueSnackbar(
                    t('error_notification.error_unable_to_sync'),
                    {
                      variant: 'error',
                    },
                  )
                },
              },
            )
          }
        >
          <SynchronizeIcon color="#c1121f" />
        </ButtonBase>
      </span>
    </Tooltip>
  )
}
