import { Button as MuiButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const Button = (props: any) => {
  const theme = useTheme()
  const { children, withShadow, noSpacing, sx, minWidth, ...rest } = props

  return (
    <MuiButton
      {...rest}
      sx={{
        fontWeight: `${700}  `,
        fontSize: '16px',
        borderRadius: '43px',
        minWidth: minWidth ?? '124px',
        whiteSpace: 'nowrap',
        padding: '10px 32px',
        height: '42px',
        margin: noSpacing ? 0 : 1,
        '& .Mui-disabled': {
          opacity: '0.5  ',
        },

        ...(props.variant === 'text' && {
          color: `${theme.palette.primary.dark}  `,
          border: '1px solid',
          borderColor: 'info.main',
          '&:hover': {
            backgroundColor: `${theme.palette.primary.dark}  `,
            color: 'white  ',
          },
          '&:disabled': {
            borderColor: `${theme.palette.primary.dark}  `,
          },
        }),

        ...(props.variant === 'outlined' && {
          color: `${theme.customRed.active}`,
          borderColor: `${theme.customRed.active}`,
          '&:hover': {
            backgroundColor: `${theme.customRed.hover}  `,
            borderColor: `${theme.customRed.hover}`,
            color: 'white  ',
          },
          '&:disabled': {
            filter: 'grayscale(.8)',
            cursor: 'not-allowed',
          },
        }),
        ...(props.variant === 'contained' && {
          boxShadow: withShadow
            ? '0px 12px 24px rgba(74, 58, 255, 0.23) '
            : undefined,
          backgroundColor: `${theme.customRed.active} `,
          color: 'white  ',
          border: 'none',
          '&:hover': {
            backgroundColor: `${theme.customRed.hover} `,
            boxShadow: '0px 4px 24px rgba(74, 58, 255, 0.23)  Zz',
          },
          '&:disabled': {
            backgroundColor: `${theme.customRed.disabled}  `,
            color: 'white',
            opacity: 0.5,
            boxShadow: '0px 12px 24px rgba(74, 58, 255, 0.23)  ',
          },
          '&:focus': {
            backgroundColor: `${theme.customRed.focus}  `,
          },
        }),
        ...sx,
      }}
    >
      {children}
    </MuiButton>
  )
}
