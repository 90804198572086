export const audioExtensions = [
  'wav',
  'wave',
  'aiff',
  'aif',
  'aifc',
  'pcm',
  'm4a',
  'flac',
  'caf',
  'wma',
  'wmv',
  'mp3',
  'ogg',
  'oga',
  'mogg',
  'aac',
  '3gp',
  'm4r',
  'wma',
  'wmv',
]
