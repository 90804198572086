import * as t from 'io-ts'
import { DateFromISOString } from 'lib/io-ts-codecs/DateFromISOString'

export const OrganizationFullCodec = t.type({
  organizationId: t.string,
  logoUrl: t.union([t.string, t.null]),
  name: t.string,
  active: t.boolean,
  creditsNumber: t.number,
  createdAt: DateFromISOString,
  duration: t.union([t.number, t.null]),
  filesSize: t.string,
  fileUpload: t.boolean,
  accountId: t.string,
})

export const OrganizationsFullCodec = t.array(OrganizationFullCodec)

export type OrganizationFull = t.TypeOf<typeof OrganizationFullCodec>

export const OrganizationShortCodec = t.type({
  organizationId: t.string,
  name: t.string,
  accountId: t.string,
})

export const OrganizationsShortCodec = t.array(OrganizationShortCodec)

export type OrganizationsShort = t.TypeOf<typeof OrganizationsShortCodec>
