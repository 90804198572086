import * as t from 'io-ts'

import {
  IncorrectNewPasswordErrorCodeCodec,
  IncorrectPasswordErrorCodeCodec,
  UserNotFoundErrorCodeCodec,
} from '../errors'

export const ChangePasswordErrorCodeCodec = t.union([
  UserNotFoundErrorCodeCodec,
  IncorrectNewPasswordErrorCodeCodec,
  IncorrectPasswordErrorCodeCodec,
])
