import * as t from 'io-ts'

const PeriodTypeCodec = t.union([t.literal('MONTH'), t.literal('YEAR')])

export const CreditByDateCodec = t.type({
  date: t.string,
  credits: t.number,
})

export const CreditsByDateCodec = t.array(CreditByDateCodec)

export const CreditsChartCodec = t.type({
  periodType: PeriodTypeCodec,
  totalCreditsByPeriod: t.number,
  creditsByDate: CreditsByDateCodec,
})

export type CreditsByDate = t.TypeOf<typeof CreditsByDateCodec>
export type CreditsChart = t.TypeOf<typeof CreditsChartCodec>

export const UserByDateCodec = t.type({
  date: t.string,
  usersNumber: t.number,
})

export const UsersByDateCodec = t.array(UserByDateCodec)

export const UsersChartCodec = t.type({
  periodType: PeriodTypeCodec,
  totalUsersNumber: t.number,
  usersByDate: UsersByDateCodec,
})

export type UsersByDate = t.TypeOf<typeof UsersByDateCodec>
export type UsersChart = t.TypeOf<typeof UsersChartCodec>
