import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { FormInput, FormModal } from 'components/Form'
import { validation } from 'config/validation'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type FormValues = {
  integrationType: string
  patternType: 'exclude' | 'include'
  patternText: string
}

type Props = {
  onClose: () => void
  open: boolean
}

export const UserRuleModal = (props: Props) => {
  const { t } = useTranslation()
  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const $addRule = useMutation('POST', '/api/organizations/my/user-rules')

  const mutateAddRule = $addRule.mutate

  const onSubmit = (data: FormValues) => {
    mutateAddRule(
      {
        body: { ...data },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t('success_notification.pattern_added'), {
            variant: 'success',
          })
          query.invalidateQueries()
          props.onClose()
        },
        onError: (error: any) => {
          if (
            error.code in
            ['error_user_rules_limit_exceeded', 'error_pattern_not_unique']
          ) {
            enqueueSnackbar(t(`error_notification.${error?.code}`), {
              variant: 'error',
            })
          } else {
            enqueueSnackbar(t(`error_notification.error_user_rule`), {
              variant: 'error',
            })
          }
          props.onClose()
        },
      },
    )
  }

  return (
    <FormModal<FormValues>
      onSubmit={onSubmit}
      title={t('organization_settings.add_user_rule')}
      submitTitle={t('buttons.add')}
      onClose={props.onClose}
      open={props.open}
      isLoading={$addRule.isLoading}
      defaultValues={{
        integrationType: 'kaltura',
        patternType: 'exclude',
        patternText: '',
      }}
      renderForm={({ control }) => (
        <Grid container spacing={4}>
          <Grid item xs={12} mt={2}>
            <ControlledAutocomplete
              control={control}
              label={t('organization_settings.integration_type')}
              disabled
              name="integrationType"
              options={[{ label: 'Kaltura', value: 'kaltura' }]}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              control={control}
              label={t('organization_settings.pattern_type')}
              name="patternType"
              disabled
              options={[{ label: t('labels.exclude'), value: 'exclude' }]}
              required={true}
            />
          </Grid>
          <Grid item xs={12} mb={4}>
            <FormInput
              control={control}
              name="patternText"
              required
              fullWidth
              placeholder={t('organization_settings.pattern')}
              maxLength={validation.commonFieldMaxLength}
              minLength={validation.commonFieldMinLength}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
