import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ModalWrapper = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation()
  return (
    <Box p={3} minWidth="444px" sx={{ outline: 'none' }}>
      <Box mb={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h2">
              <strong>{t('integrations.update_integration')}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {children}
    </Box>
  )
}

export default ModalWrapper
