import { useEffect } from 'react'

type UseValidValueInput<T> = Readonly<{
  value: T
  defaultValue: T
  isValid: (value: T) => boolean
  setValue: (value: T) => void
}>

// Hooks like useQueryState and useLocalStorageState don't validate the value.
// It means if user enters unacceptable value in the URL or changes it in the LocalStorage, the app may fail because it doesn't know how to deal with unacceptable values.
export const useValidValue = <T,>({
  value,
  defaultValue,
  isValid,
  setValue,
}: UseValidValueInput<T>) => {
  useEffect(() => {
    if (!isValid(value)) {
      setValue(defaultValue)
    }
  }, [value, defaultValue, isValid, setValue])

  return isValid(value) ? value : defaultValue
}
