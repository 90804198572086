import { Box, Grid, Hidden, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  children: ReactNode
}

export const UnauthorizedLeftItem = ({ children }: Props) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ justifyContent: 'center', margin: 'auto', minHeight: '600px' }}>
      <Stack
        sx={{ maxWidth: '1320px' }}
        direction="row"
        justifyContent="space-between"
      >
        <Hidden mdDown>
          <Grid item xs={6} mt={10}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  color="white"
                  sx={{
                    fontSize: '48px',
                    fontFamily: 'Satoshi',
                    lineHeight: '1.5',
                    paddingBottom: '100px',
                  }}
                >
                  {t('unauthorized.main_text')}{' '}
                  <Box
                    component="b"
                    sx={{
                      position: 'relative',
                      fontWeight: '100',
                      display: 'inline-flex',
                      margin: 0,
                      zIndex: 1,
                      '&::after': {
                        position: 'absolute',
                        content: '""',
                        width: '100%',
                        height: 16,
                        bottom: 10,
                        backgroundColor: '#C1121F',
                        zIndex: -1,
                      },
                    }}
                  >
                    {t('unauthorized.word_underline')}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={4}>
          {children}
        </Grid>
      </Stack>
    </Box>
  )
}
