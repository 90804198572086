export const flagCodes: Record<string, string> = {
  Arabic: 'ps',
  Bulgarian: 'bg',
  Cantonese: 'cn',
  Catalan: 'es',
  Croatian: 'hr',
  Czech: 'cz',
  Danish: 'dk',
  Dutch: 'nl',
  English: 'gb',
  Finnish: 'fi',
  French: 'fr',
  German: 'de',
  Greek: 'gr',
  Hebrew: 'il',
  Hindi: 'in',
  Hungarian: 'hu',
  Indonesian: 'id',
  Italian: 'it',
  Japanese: 'jp',
  Korean: 'kp',
  Latvian: 'lv',
  Lithuanian: 'lt',
  Malay: 'my',
  Mandarin: 'cn',
  Norwegian: 'no',
  Polish: 'pl',
  Portuguese: 'pt',
  Romanian: 'ro',
  Russian: 'ru',
  Slovak: 'sk',
  Ukrainian: 'ua',
  Slovenian: 'sl',
  Spanish: 'es',
  Swedish: 'se',
  Turkish: 'tr',
  Basque: 'es',
  Bashkir: 'ru',
  Marathi: 'mr',
  Belarusian: 'by',
  Esperanto: 'un',
  Estonian: 'ee',
  Galician: 'es',
  Interlingua: 'un',
  Mongolian: 'mn',
  Tamil: 'in',
  Thai: 'th',
  Uyghur: 'cn',
  Vietnamese: 'vn',
  Welsh: 'gb',
}
