import * as t from 'io-ts'

import {
  IncorrectCredentialsErrorCodeCodec,
  UserIsInactiveErrorCodeCodec,
} from '../errors'

export const SigninCodec = t.type({
  accessToken: t.string,
})

export const RefreshCodec = t.type({
  accessToken: t.string,
})

export const AccessTokenCodec = t.type({
  role: t.union([
    t.literal('EDITOR'),
    t.literal('ADMIN'),
    t.literal('SYSTEM_ADMIN'),
    t.literal('GUEST'),
    t.literal('OWNER'),
  ]),
})

export type Signin = t.TypeOf<typeof SigninCodec>

export type Refresh = t.TypeOf<typeof RefreshCodec>

export const SigninErrorCodeCodec = t.union([
  UserIsInactiveErrorCodeCodec,
  IncorrectCredentialsErrorCodeCodec,
])
