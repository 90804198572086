import { FormHelperText, Grid, Link, Stack, Typography } from '@mui/material'
import { routes } from 'app/routes'
import { Button, Checkbox } from 'components'
import {
  Form,
  FormInput,
  FormPasswordInput,
  textFieldError,
  UnauthorizedFormContainer,
} from 'components/Form'
import { InternalLink } from 'components/InternalLink'
import { validation } from 'config/validation'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { UnauthorizedLeftItem } from '../UnauthorizedLeftItem'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  organizationName: string
  termsAndConditions: boolean
  gRecaptchaResponse: null | string
}

type Props = {
  onSubmit: (values: FormValues) => void
}

export const SignupForm = ({ onSubmit }: Props) => {
  const form = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      organizationName: '',
      termsAndConditions: false,
      gRecaptchaResponse: null,
    },
  })
  const { t } = useTranslation()

  const { control } = form

  return (
    <UnauthorizedLeftItem>
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <UnauthorizedFormContainer
          label={t('unauthorized_pages.sign_up')}
          helperText={
            <Stack direction="row">
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
              >
                {t('unauthorized_pages.already_member')}
              </Typography>
              <InternalLink to={routes.signin}>
                <Typography
                  variant="body2"
                  color="error.dark"
                  component="span"
                  ml={1}
                >
                  {t('unauthorized_pages.sign_in')}
                </Typography>
              </InternalLink>
            </Stack>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormInput
                name="firstName"
                control={control}
                maxLength={validation.commonFieldMaxLength}
                minLength={validation.commonFieldMinLength}
                required
                fullWidth
                placeholder={t('labels.first_name')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                name="lastName"
                control={control}
                maxLength={validation.commonFieldMaxLength}
                minLength={validation.commonFieldMinLength}
                required
                fullWidth
                placeholder={t('labels.last_name')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                name="organizationName"
                control={control}
                required
                fullWidth
                placeholder={t('labels.organization')}
                maxLength={validation.organisationFieldMaxLength}
                minLength={validation.commonFieldMinLength}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                name="email"
                control={control}
                required
                fullWidth
                placeholder={t('labels.email')}
                pattern={{
                  value: validation.emailRegex,
                  message: t('validation.enter_valid_email'),
                }}
                validate={{
                  email: email =>
                    !String(email).endsWith('@gmail.com') ||
                    t('error_notification.error_invalid_email_domain'),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormPasswordInput
                label={t('labels.password')}
                control={control}
                name="password"
                placeholder={t('labels.password')}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="termsAndConditions"
                rules={{
                  required: {
                    value: true,
                    message: t('validation.field_is_required'),
                  },
                }}
                render={({ field, fieldState }) => (
                  <Checkbox
                    {...textFieldError(fieldState.error)}
                    checked={field.value}
                    onChange={value => field.onChange(value)}
                    label={
                      <Typography>
                        {t('unauthorized_pages.accept')}{' '}
                        <Link
                          href="https://www.txtplay.ai/utility-pages/terms-and-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('unauthorized_pages.terms_and_conditions')}
                        </Link>
                      </Typography>
                    }
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Controller
                control={control}
                name="gRecaptchaResponse"
                rules={{
                  required: {
                    value: true,
                    message: t('validation.field_is_required'),
                  },
                }}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Stack spacing={1}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_KEY ?? ''}
                      onChange={value => onChange(value)}
                    />
                    <FormHelperText error={error !== undefined}>
                      {error?.message}
                    </FormHelperText>
                  </Stack>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Button
                  withShadow
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t('unauthorized_pages.sign_up')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </UnauthorizedFormContainer>
      </Form>
    </UnauthorizedLeftItem>
  )
}
