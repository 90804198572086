import { makeStyles } from 'tss-react/mui'

import { controlBoxStyles } from './control-box'

export const useStyles = makeStyles()(theme => ({
  ...controlBoxStyles(theme, '2px'),

  check: {
    width: 16,
    height: 16,
    padding: '2px',
    border: '1px solid #4AC398',
    borderRadius: '2px',
    color: '#4AC398',
    background: 'transparent',
  },

  iconDisabled: {
    filter: 'grayscale(1)',
  },
}))
