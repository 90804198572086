import useLocalStorageState, {
  LocalStorageState,
} from 'use-local-storage-state'

import { useValidValue } from './use-valid-value'

type UseLocalStorageValidatedInput<T> = Readonly<{
  key: string
  defaultValue: T
  isValid: (value: T) => boolean
}>

export const useLocalStorageValidated = <T,>({
  key,
  defaultValue,
  isValid,
}: UseLocalStorageValidatedInput<T>): LocalStorageState<T> => {
  const [_state, setState, actions] = useLocalStorageState(key, {
    defaultValue,
  })

  const state = useValidValue({
    value: _state,
    defaultValue,
    isValid,
    setValue: setState,
  })

  return [state, setState, actions]
}
