import {
  ButtonBase,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { CopyToClipboardButton, Switch } from 'components'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useBoolean } from 'lib/use-boolean'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
type Props = {
  shared: boolean
  taskId: string

  onShareChange: () => void
}

export const ShareButton = ({ shared, taskId, onShareChange }: Props) => {
  const link = `${process.env.REACT_APP_TXTPLAY_URL}/files/shared/${taskId}`

  const isSharable = useBoolean(shared)

  const $editAccessibility = useMutation('PATCH', '/api/tasks/:taskId/shared')

  const editAccessibility = $editAccessibility.mutate

  const { enqueueSnackbar } = useSnackbar()

  const { t } = useTranslation()

  const onSwitch = () => {
    editAccessibility(
      {
        params: {
          taskId,
        },
        body: {
          shared: isSharable.isFalse,
        },
      },
      {
        onSuccess: () => {
          isSharable.toggle()
          onShareChange()
        },
        onError: error => {
          if (error.type === 'client_error') {
            enqueueSnackbar(error.message, { variant: 'error' })
          }

          if (error.type === 'server_error') {
            enqueueSnackbar(t(`server_error.${error.status}`), {
              variant: 'error',
            })
          }
        },
      },
    )
  }

  const popupState = usePopupState({
    popupId: 'share',
    variant: 'popover',
  })

  return (
    <Box>
      <Tooltip title={t('buttons.share')}>
        <span>
          <ButtonBase
            {...bindTrigger(popupState)}
            sx={{ padding: '10px', borderRadius: '43px' }}
          >
            <ShareIcon color="#37637E" />
          </ButtonBase>
        </span>
      </Tooltip>
      <Popover
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
      >
        <Box sx={{ px: 3.5, py: 4, minWidth: 450, minHeight: 180 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h3">{t('labels.sharable_link')}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Switch
                onChange={onSwitch}
                isChecked={isSharable.isTrue}
                label={isSharable.isTrue ? 'on' : 'off'}
                labelPlacement="start"
              />
            </Stack>
          </Stack>
          <Typography variant="subtitle2" color="text.secondary">
            {t('labels.access_info')}
          </Typography>
          <Box position="relative">
            <TextField
              disabled={isSharable.isFalse}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                m: 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  pl: 1,
                  pr: 8,
                },
              }}
              inputProps={{
                sx: { minHeight: '40px', p: 0 },
              }}
              value={link}
            />
            <CopyToClipboardButton text={link} disabled={isSharable.isFalse} />
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
