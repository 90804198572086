import {
  FormControl,
  FormHelperText,
  InputBaseProps,
  Stack,
  Typography,
} from '@mui/material'
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  Path,
  useController,
  Validate,
  ValidationValueMessage,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Input } from '../../Input'

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  type?: InputBaseProps['type']
  control: Control<TFieldValues>
  name: Path<TFieldValues>
  required: boolean
  placeholder?: string
  fullWidth?: boolean
  maxLength?: number
  minLength?: number
  minRows?: number
  maxRows?: number
  disabled?: boolean
  multiline?: boolean
  helperText?: string
  label?: string
  validate?:
    | Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>
    | Record<
        string,
        Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>
      >
  pattern?: ValidationValueMessage<RegExp>
  readOnly?: boolean
}

export const FormInput = <TFieldValues extends FieldValues = FieldValues>(
  props: Props<TFieldValues>,
) => {
  const { t } = useTranslation()
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: {
      required: {
        value: props.required,
        message: t('validation.field_is_required'),
      },
      pattern: props.pattern,
      validate: {
        maxLength: value =>
          !(props.maxLength && value.trim().length >= props.maxLength + 1) ||
          t('validation.field_max_length', { length: props.maxLength }),
        minLength: value =>
          !(props.minLength && value.trim().length <= props.minLength) ||
          t('validation.field_min_length', { length: props.minLength }),

        ...props.validate,
      },
    },
  })

  const isError = Boolean(error)

  return (
    <Stack>
      {props.label && <Typography variant="h4">{props.label}</Typography>}
      <FormControl
        required={props.required}
        fullWidth={props.fullWidth}
        error={isError}
      >
        <Input
          type={props.type}
          error={isError}
          placeholder={props.placeholder}
          ref={ref}
          readOnly={props.readOnly}
          {...inputProps}
          disabled={props.disabled}
          multiline={props.multiline}
          minRows={props.minRows}
          maxRows={props.maxRows}
        />
        {(error || props.helperText) && (
          <FormHelperText error={isError} sx={{ mt: '8px', ml: 0 }}>
            {error?.message || props.helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Stack>
  )
}
