import { Box } from '@mui/material'
import { NotificationAlertDialog } from 'components'
import { FormInput, FormModal } from 'components/Form'
import { validation } from 'config/validation'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useBoolean } from 'lib/use-boolean'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type FormValues = {
  email: string
}

type Props = {
  open: boolean

  onClose: () => void
  onEmailChange: () => void
}

export const ChangeEmailModal = (props: Props) => {
  const { t } = useTranslation()
  const successAlert = useBoolean(false)
  const errorAlert = useBoolean(false)

  const [error, setError] = useState<null | string>(null)

  const $changeEmail = useMutation('POST', '/api/confirmations/email')

  const mutateChangeEmail = $changeEmail.mutate

  const onSubmit = ({ email }: FormValues) => {
    mutateChangeEmail(
      {
        body: {
          email,
        },
      },
      {
        onSuccess: () => {
          props.onClose()
          successAlert.setTrue()
        },
        onError: error => {
          props.onClose()

          if (error.type === 'client_error' && error.code) {
            setError(`error_notification.${error.code}`)
          } else {
            setError('error_notification.error_email_change_general')
          }

          errorAlert.setTrue()
        },
      },
    )
  }

  return (
    <>
      <NotificationAlertDialog
        variant="success"
        title={t('sent_email_verification_dialog.title')}
        text={t('sent_email_verification_dialog.text')}
        buttonLabel={t('buttons.got_it')}
        open={successAlert.isTrue}
        onClose={() => {
          successAlert.setFalse()
          props.onEmailChange()
        }}
      />
      <NotificationAlertDialog
        variant="error"
        title={t('error_notification.something_went_wrong')}
        text={t(error ?? '')}
        buttonLabel={t('buttons.got_it')}
        open={errorAlert.isTrue}
        onClose={() => {
          errorAlert.setFalse()
          setTimeout(() => setError(null), 500)
        }}
      />
      <FormModal<FormValues>
        onSubmit={onSubmit}
        title={t('labels.change_email')}
        submitTitle={t('buttons.change')}
        onClose={props.onClose}
        open={props.open}
        isLoading={$changeEmail.isLoading}
        defaultValues={{
          email: '',
        }}
        renderForm={({ control }) => (
          <Box mt={5}>
            <FormInput
              type="filled"
              control={control}
              name="email"
              required
              fullWidth
              placeholder={t('placeholders.new_email')}
              pattern={{
                value: validation.emailRegex,
                message: t('validation.enter_valid_email'),
              }}
            />
          </Box>
        )}
      />
    </>
  )
}
