import { CircularProgress } from '@mui/material'

export const NotificationCircularProgress = () => {
  return (
    <CircularProgress
      size={15}
      color="error"
      sx={{ position: 'absolute', top: 0, right: -5 }}
    />
  )
}
