const context = require.context('./', true, /.png$/)

const obj = {}
for (const key of context.keys()) {
  const countryCode = key
    .split('./')
    .pop() // remove the first 2 characters
    .slice(0, Math.max(0, key.length - 6)) // remove the file extension
  obj[countryCode] = context(key)
}

export default obj
