export const ArrowLeftIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fill="#919EAB"
        fillRule="evenodd"
        d="M16 9H4.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L4.414 11H16a1 1 0 1 0 0-2"
      />
    </svg>
  )
}

export const ArrowRightIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fill="#919EAB"
        fillRule="evenodd"
        d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414"
      />
    </svg>
  )
}
