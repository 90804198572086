import { FormControl, FormHelperText, TextField } from '@mui/material'
import { Autocomplete as MuiAutocomplete } from '@mui/material'
import { ReactComponent as Dropdown } from 'assets/icons/drop-down.svg'
import { forwardRef, useMemo } from 'react'

type Props = {
  value: string | Array<string> | null
  onChange: (value: string | Array<string>) => void
  multiple?: boolean
  sx?: Record<string, any>
  options: Array<{ value: string; label: string }>
  label?: string
  noAdornment?: boolean
  variant?: 'standard' | 'outlined'
  error?: boolean
  helperText?: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  margin?: 'dense' | 'normal' | 'none'
  getOptionDisabled?: ({
    value,
    label,
  }: {
    value: string
    label: string
  }) => boolean
}

export const Autocomplete = forwardRef(
  (
    {
      value,
      onChange,
      label,
      options,
      error,
      helperText,
      disabled,
      required,
      placeholder = '',
      noAdornment = false,
      variant = 'standard',
      multiple = false,
      margin = 'none',
      getOptionDisabled,
      sx = {},
    }: Props,
    ref,
  ) => {
    const currentValue = useMemo(() => {
      if (multiple && Array.isArray(value)) {
        if (value.length > 0) {
          return options.filter(option => value.includes(option.value))
        }

        return []
      }

      if (!multiple && typeof value === 'string') {
        return options.find(option => option.value === value) ?? null
      }

      return null
    }, [multiple, options, value])

    return (
      <FormControl error={error} fullWidth>
        <MuiAutocomplete
          sx={sx}
          disabled={disabled}
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={option => option.label}
          multiple={multiple}
          blurOnSelect={true}
          popupIcon={noAdornment ? '' : <Dropdown />}
          clearIcon={!noAdornment && ''}
          onChange={(_event, newValue) => {
            if (Array.isArray(newValue)) {
              const multipleValue = newValue.map(item => item.value)
              onChange(multipleValue)
            } else {
              onChange(newValue?.value ?? '')
            }
          }}
          options={options}
          ref={ref}
          ChipProps={{
            sx: {
              '& .MuiChip-deleteIcon': {
                color: '#fff',
                opacity: '0.5',
                fontSize: '16px',
              },
              '& .MuiChip-deleteIcon:hover': {
                opacity: 1,
                color: '#fff',
              },
              backgroundColor: 'text.primary',
              color: '#fff',
              fontSize: ({ typography }) => typography.body1.fontSize,
              fontFamily: ({ typography }) => typography.body1.fontFamily,
              fontWeight: ({ typography }) => typography.body1.fontWeight,
            },
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={error}
              margin={margin}
              placeholder={placeholder}
              label={label}
              size="small"
              variant={variant}
              sx={{
                '& .MuiInput-root::before': {
                  borderBottom: '1px solid #CED7DE',
                },
              }}
            />
          )}
          value={currentValue}
        />

        {helperText && (
          <FormHelperText sx={{ mt: 0.5, ml: 0 }}>{helperText}</FormHelperText>
        )}
      </FormControl>
    )
  },
)
