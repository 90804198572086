import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Autocomplete } from '../Autocomplete'
import { textFieldError } from '../Form'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  label?: string
  placeholder?: string
  control: Control<TFieldValues>
  name: Path<TFieldValues>
  required: boolean
  options: Array<{ value: string; label: string }>
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>
  multiple?: boolean
  disabled?: boolean
  margin?: 'dense' | 'normal' | 'none'
  sx?: Record<string, any>
}

export const ControlledAutocomplete = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  control,
  name,
  required,
  options,
  disabled,
  multiple = false,
  defaultValue,
  margin,
  placeholder,
  sx = {},
}: Props<TFieldValues>) => {
  const { t } = useTranslation()
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
    rules: {
      required: { message: t('validation.field_is_required'), value: required },
    },
  })

  return (
    <Autocomplete
      sx={sx}
      {...textFieldError(fieldState.error)}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      margin={margin}
      multiple={multiple}
      onChange={field.onChange}
      options={options}
      ref={field.ref}
      required={required}
      value={field.value}
    />
  )
}
