import './timeline.css'

import { Box } from '@mui/material'
import {
  Timeline,
  TimelineAction,
  TimelineState,
} from '@xzdarcy/react-timeline-editor'
import { ITranscriptionPhrase } from 'app/codecs'
import { formatTimeForFiles } from 'imgplay-domain/helpers'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

export type FineTuneChunk = {
  prevEndTime: number
  nextStartTime: number
  chunk: ITranscriptionPhrase
}

export type TunedWords = Array<{
  wordIndex: number
  startTime: number
  endTime: number
}>

type Props = {
  transcription: FineTuneChunk
  time: number

  onWordsTune: (words: TunedWords) => void
  onVideoTimeChange: (time: number) => void
}

const scale = 1
const startLeft = 50

export const FineTune = ({
  transcription,
  time,
  onVideoTimeChange,
  onWordsTune,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [width, setWidth] = useState(0)

  const timelineState = useRef<TimelineState>()

  useLayoutEffect(() => {
    if (ref.current !== null) {
      setWidth(ref.current.offsetWidth ?? 0)
    }
  }, [])

  const floor = Math.floor(
    transcription.nextStartTime - transcription.prevEndTime,
  )
  const ceil = Math.ceil(
    transcription.nextStartTime - transcription.prevEndTime,
  )

  const scaleWidth = width / ceil > 250 ? (width * 0.974_25) / ceil : 250

  const scaleSplitCount = width / ceil > 300 ? 20 : 10

  const maxScroll = (scaleWidth / scale) * ceil + startLeft * 2 - width

  const words = transcription.chunk.words

  const currentTime = time - Math.floor(transcription.prevEndTime)
  const endTime =
    Math.ceil(transcription.nextStartTime) -
    Math.floor(transcription.prevEndTime)

  // Update timeline time and scroll
  useEffect(() => {
    if (!timelineState.current) {
      return
    }
    const timeline = timelineState.current

    if (timeline.getTime() !== currentTime && currentTime <= endTime + 1) {
      timeline.setTime(currentTime)

      const scroll = currentTime * (scaleWidth / scale) + startLeft - width / 2

      if (scroll <= maxScroll) {
        timeline.setScrollLeft(scroll)
      }
    }
  }, [ceil, currentTime, endTime, maxScroll, scaleWidth, width])

  const [actions, setActions] = useState<TimelineAction[]>(
    words.map((item, idx, arr) => ({
      id: String(idx),
      start: item.startTime - Math.floor(transcription.prevEndTime),
      end: item.endTime - Math.floor(transcription.prevEndTime),
      minStart:
        arr[idx - 1]?.type === 'punctuation'
          ? (arr[idx - 2]?.endTime ?? transcription.prevEndTime) -
            Math.floor(transcription.prevEndTime)
          : (arr[idx - 1]?.endTime ?? transcription.prevEndTime) -
            Math.floor(transcription.prevEndTime),
      maxEnd:
        arr[idx + 1]?.type === 'punctuation'
          ? (arr[idx + 2]?.startTime ?? transcription.nextStartTime) -
            Math.floor(transcription.prevEndTime)
          : (arr[idx + 1]?.startTime ?? transcription.nextStartTime) -
            Math.floor(transcription.prevEndTime),
      effectId: item.type,
      flexible: item.type === 'word',
    })),
  )

  return (
    <Box position="relative">
      <Box className="timeline-editor-wrapper" ref={ref}>
        <Timeline
          ref={timelineState as any}
          onChange={newData => {
            const [timeline] = newData

            setActions(
              timeline.actions.map((item, idx, arr) => ({
                ...item,
                start:
                  item.effectId === 'word'
                    ? item.start
                    : arr[idx - 1]?.end ??
                      transcription.prevEndTime -
                        Math.floor(transcription.prevEndTime),
                end:
                  item.effectId === 'word'
                    ? item.end
                    : arr[idx - 1]?.end ??
                      transcription.prevEndTime -
                        Math.floor(transcription.prevEndTime),
                minStart:
                  arr[idx - 1]?.effectId === 'punctuation'
                    ? arr[idx - 2]?.end ??
                      transcription.prevEndTime -
                        Math.floor(transcription.prevEndTime)
                    : arr[idx - 1]?.end ??
                      transcription.prevEndTime -
                        Math.floor(transcription.prevEndTime),
                maxEnd:
                  arr[idx + 1]?.effectId === 'punctuation'
                    ? arr[idx + 2]?.start ??
                      transcription.nextStartTime -
                        Math.floor(transcription.prevEndTime)
                    : arr[idx + 1]?.start ??
                      transcription.nextStartTime -
                        Math.floor(transcription.prevEndTime),
              })),
            )
            onWordsTune(
              timeline.actions.map((item, idx, arr) => ({
                wordIndex: idx,
                startTime:
                  item.effectId === 'word'
                    ? item.start + Math.floor(transcription.prevEndTime)
                    : arr[idx - 1]?.end
                    ? arr[idx - 1].end + Math.floor(transcription.prevEndTime)
                    : transcription.prevEndTime,
                endTime:
                  item.effectId === 'word'
                    ? item.end + Math.floor(transcription.prevEndTime)
                    : arr[idx - 1]?.end
                    ? arr[idx - 1].end + Math.floor(transcription.prevEndTime)
                    : transcription.prevEndTime,
              })),
            )
          }}
          editorData={[
            {
              id: transcription.chunk.alternativeId,
              actions,
            },
          ]}
          effects={{
            word: {
              id: 'word',
              name: 'word',
            },
            punctuation: {
              id: 'punctuation',
              name: 'punctuation',
            },
          }}
          scale={scale}
          scaleSplitCount={scaleSplitCount}
          scaleWidth={scaleWidth}
          startLeft={startLeft}
          minScaleCount={floor}
          maxScaleCount={ceil}
          getScaleRender={scale =>
            formatTimeForFiles(scale + Math.floor(transcription.prevEndTime))
          }
          getActionRender={action =>
            action.effectId === 'word' ? (
              <div className="timeline-editor-action-text">
                {words[Number(action.id)].content}
              </div>
            ) : null
          }
          onDoubleClickAction={(_e, { action }) => {
            onVideoTimeChange(
              action.start + Math.floor(transcription.prevEndTime),
            )
          }}
          onActionResizing={({ start, end }) => {
            if (start > end || Math.abs(start - end) < 0.05) return false
          }}
        />
      </Box>
    </Box>
  )
}
