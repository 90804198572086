import { Box } from '@mui/material'
import { ReactComponent as Share } from 'assets/icons/share.svg'
import { Tooltip } from 'components/Tooltip'
import { useTranslation } from 'react-i18next'

export const SharedChip = () => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('tooltips.shared_file')}>
      <Box
        sx={{
          width: 28,
          height: 28,
          backgroundColor: 'secondary.dark',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          bottom: 4,
          right: 4,
        }}
      >
        <Share width="16px" height="16px" />
      </Box>
    </Tooltip>
  )
}
