import { Grid } from '@mui/material'
import { useAuthContext } from 'app/auth'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { FormInput, FormModal } from 'components/Form'
import { validation } from 'config/validation'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type Props = {
  onClose: () => void
  open: boolean
  refetch: () => void
}
type FormValues = {
  email: string
  firstName: string
  lastName: string
  roleName: 'EDITOR' | 'ADMIN'
}

export const OrganizationModal = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const $inviteUser = useMutation('POST', '/api/register/invite')
  const { accessToken } = useAuthContext()
  const currentRole = accessToken !== null && accessToken.role

  const rolesList = ['EDITOR', 'ADMIN']

  const onSubmit = ({
    email,
    firstName,
    lastName,
    roleName = 'EDITOR',
  }: FormValues) => {
    $inviteUser.mutate(
      { body: { email, firstName, lastName, roleName } },
      {
        onSuccess: () => {
          props.onClose()
          enqueueSnackbar(t('success_notification.invitation_was_sent'), {
            variant: 'success',
          })
          props.refetch()
        },
        onError: () => {
          enqueueSnackbar(t('error_notification.error_user_already_invited'), {
            variant: 'error',
          })
        },
      },
    )
  }

  return (
    <FormModal<FormValues>
      sx={{ marginTop: 4.5 }}
      title={t('labels.invite_user')}
      submitTitle={t('buttons.invite')}
      open={props.open}
      onClose={props.onClose}
      onSubmit={onSubmit}
      isLoading={$inviteUser.isLoading}
      renderForm={({ control }) => (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <FormInput
              control={control}
              maxLength={validation.commonFieldMaxLength}
              minLength={validation.commonFieldMinLength}
              name="email"
              fullWidth
              required
              placeholder={t('labels.email')}
              pattern={{
                value: validation.emailRegex,
                message: t('validation.enter_valid_email'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              fullWidth
              maxLength={validation.commonFieldMaxLength}
              minLength={validation.commonFieldMinLength}
              name="firstName"
              required
              placeholder={t('labels.first_name')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              fullWidth
              maxLength={validation.commonFieldMaxLength}
              minLength={validation.commonFieldMinLength}
              name="lastName"
              required
              placeholder={t('labels.last_name')}
            />
          </Grid>
          {currentRole === 'OWNER' && (
            <Grid item xs={12}>
              <ControlledAutocomplete
                sx={{
                  '.MuiInputLabel-asterisk': {
                    marginLeft: '-3px',
                  },
                }}
                control={control}
                label={t('labels.role')}
                name="roleName"
                margin="none"
                options={rolesList.map(role => ({
                  label: role,
                  value: role,
                }))}
                required
              />
            </Grid>
          )}
        </Grid>
      )}
    />
  )
}
