import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { DateFromISOString } from 'lib/io-ts-codecs/DateFromISOString'

import { TIntegrationType } from '../integrations'

export const TaskStatusCodec = t.union([
  t.literal('PROCESSING'),
  t.literal('DONE'),
  t.literal('ERROR'),
  t.literal('SCHEDULED'),
  t.literal('CREATED'),
  t.literal('SYNCHRONIZED'),
  t.literal('DOWNLOADED'),
  t.literal('DOWNLOADING'),
  t.literal('CHUNK_AUDIO'),
  t.literal('CHUNKING_AUDIO'),
  t.literal('SEND_TO_CHUNK_AUDIO'),
  t.literal('EXTRACT_AUDIO'),
  t.literal('EXTRACTING_AUDIO'),
  t.literal('SEND_TO_EXTRACT_AUDIO'),
  t.literal('TRANSCODE_VIDEO'),
  t.literal('TRANSCODING_VIDEO'),
  t.literal('SEND_TO_TRANSCODE_VIDEO'),
])
export type ITaskStatus = t.TypeOf<typeof TaskStatusCodec>

export const TaskLanguage = t.type({ name: t.string, code: t.string })
export type ITaskLanguage = t.TypeOf<typeof TaskLanguage>

export const TaskTableCodec = t.type({
  taskId: t.string,
  originalName: t.string,
  duration: t.union([t.number, t.null]),
  status: TaskStatusCodec,
  createdAt: DateFromISOString,
  sourceLanguage: TaskLanguage,
  translationLanguages: t.array(TaskLanguage),
  userEmail: t.union([t.string, t.undefined]),
  source: t.union([TIntegrationType, t.literal('file')]),
  thumbnailUrl: t.union([t.string, t.null]),
  shared: t.boolean,
  userId: t.union([t.string, t.undefined]),
  confidenceScore: t.union([t.number, t.null]),
  wordsNumber: t.union([t.number, t.null]),
  tags: t.array(t.string),
})

export const TaskTranscriptionCodec = t.type({
  results: t.array(
    t.type({
      alternatives: t.array(
        t.type({ content: t.string, language: t.string, speaker: t.string }),
      ),
      end_time: t.number,
      start_time: t.number,
      type: t.union([t.literal('punctuation'), t.literal('word')]),
    }),
  ),
})

export const WebTranscriptionCodec = t.type({
  webTranscription: t.type({
    paragraphs: t.array(
      t.type({
        end: t.number,
        start: t.number,
        speaker: t.string,
      }),
    ),
    words: t.array(
      t.type({
        id: t.union([t.number, t.undefined]),
        end: t.number,
        text: t.string,
        start: t.number,
      }),
    ),
  }),
})

export const TaskTranscriptionMetadata = t.type({
  language: TaskLanguage,
  subtitlesUrl: t.union([t.string, t.null]),
  syncWithThirdParty: t.boolean,
  syncWithPlayer: t.boolean,
  confidenceScore: t.union([t.number, t.null]),
  wordsNumber: t.union([t.number, t.null]),
  updatedAt: t.union([t.string, t.null]),
})

export const TaskDetailsCodec = t.type({
  taskId: t.string,
  userId: t.string,
  playUrl: t.string,
  downloadUrl: t.string,
  type: t.literal('SPEECHMATICS'),
  sourceLanguage: TaskLanguage,
  translationLanguages: t.array(TaskLanguage),
  duration: t.number,
  source: t.string,
  tags: t.array(t.string),
  originalName: t.string,
  shared: t.boolean,
  countryCode: t.union([t.string, t.null]),
  externalLink: t.union([t.string, t.null]),
  customDictionaryId: t.union([t.string, t.null]),
  customDictionaryName: t.union([t.string, t.null]),
  mimeType: t.union([t.string, t.null]),
  transcriptionMetadata: TaskTranscriptionMetadata,
})
export type ITaskDetails = t.TypeOf<typeof TaskDetailsCodec>

export const TaskDetailsShortCodec = t.type({
  createdAt: DateFromISOString,
  taskId: t.string,
  status: TaskStatusCodec,
  languageCode: t.string,
  duration: t.number,
})
export type ITaskDetailsShort = t.TypeOf<typeof TaskDetailsShortCodec>

export type Transcription = t.TypeOf<typeof TaskTranscriptionCodec>

export const TranscriptionWordCodec = t.intersection([
  t.type({
    type: t.union([t.literal('word'), t.literal('punctuation')]),
    content: t.string,
    startTime: t.number,
    endTime: t.number,
    language: t.string,
    confidence: t.union([t.number, t.null]),
  }),
  t.partial({
    isEos: t.boolean,
    speaker: t.string,
  }),
])

export const TranscriptionPhraseCodec = t.intersection([
  t.type({
    id: t.string,
    alternativeId: UUID,
    text: t.string,
    startTime: t.number,
    endTime: t.number,
    words: t.array(TranscriptionWordCodec),
  }),
  t.partial({
    speaker: t.string,
  }),
])

export const TranscriptionPhrasesCodec = t.array(TranscriptionPhraseCodec)

export const TranscriptionPhraseGroupsCodec = t.array(TranscriptionPhrasesCodec)

const TranscriptionChunksBaseCodec = t.type({
  firstPage: t.number,
  lastPage: t.number,
  currentPage: t.number,
})

export const TranscriptionSingleChunksCodec = t.intersection([
  TranscriptionChunksBaseCodec,
  t.type({
    phrases: TranscriptionPhrasesCodec,
  }),
])

export const TranscriptionChunkGroupsCodec = t.intersection([
  TranscriptionChunksBaseCodec,
  t.type({
    phraseGroups: TranscriptionPhraseGroupsCodec,
  }),
])

export const UpdatedChunkCodec = t.type({
  updatedChunks: TranscriptionPhrasesCodec,
  prevChunkAlternativeId: t.union([UUID, t.null]),
  nextChunkAlternativeId: t.union([UUID, t.null]),
})

export type ITranscriptionWord = t.TypeOf<typeof TranscriptionWordCodec>
export type ITranscriptionPhrase = t.TypeOf<typeof TranscriptionPhraseCodec>
export type ITranscriptionPhrases = t.TypeOf<typeof TranscriptionPhrasesCodec>
export type ITranscriptionPhraseGroups = t.TypeOf<
  typeof TranscriptionPhraseGroupsCodec
>
export type ITranscriptionSingleChunks = t.TypeOf<
  typeof TranscriptionSingleChunksCodec
>
export type ITranscriptionChunkGroups = t.TypeOf<
  typeof TranscriptionChunkGroupsCodec
>
export type IUpdatedChunk = t.TypeOf<typeof UpdatedChunkCodec>

const TaskShareStatusCodec = t.union([
  t.literal('PRIVATE'),
  t.literal('PUBLIC'),
])

const FileStatusChangelogCodec = t.type({
  eventLogType: t.literal('TASK_STATUS_UPDATED'),
  logInfo: t.type({
    fullName: t.null,
    avatar: t.null,
    oldStatus: TaskStatusCodec,
    newStatus: TaskStatusCodec,
  }),
})

const FileTextChangelogCodec = t.type({
  eventLogType: t.literal('TRANSCRIPTION_CHUNK_UPDATED'),
  logInfo: t.type({
    fullName: t.string,
    avatar: t.union([t.string, t.null]),
    oldText: t.string,
    newText: t.string,
  }),
})

const FileShareChangelogCodec = t.type({
  eventLogType: t.literal('TASK_SHARE_UPDATED'),
  logInfo: t.type({
    fullName: t.string,
    avatar: t.union([t.string, t.null]),
    oldSharedStatus: TaskShareStatusCodec,
    newSharedStatus: TaskShareStatusCodec,
  }),
})

const FileTranscriptionResetCodec = t.type({
  eventLogType: t.literal('TRANSCRIPTION_RESET'),
  logInfo: t.type({
    fullName: t.string,
    avatar: t.union([t.string, t.null]),
  }),
})

const FileChunkFineTuneCodec = t.type({
  eventLogType: t.literal('TRANSCRIPTION_CHUNK_FINE_TUNED'),
  logInfo: t.type({
    fullName: t.string,
    text: t.string,
    avatar: t.union([t.string, t.null]),
  }),
})

const FileSpeakerChangeCodec = t.type({
  eventLogType: t.literal('TRANSCRIPTION_SPEAKER_UPDATED'),
  logInfo: t.type({
    fullName: t.string,
    avatar: t.union([t.string, t.null]),
    oldSpeaker: t.string,
    newSpeaker: t.string,
    speakerChangedForAllChunks: t.boolean,
    speakerChangedForChunks: t.array(
      t.type({
        id: t.string,
        alternativeId: UUID,
        text: t.string,
      }),
    ),
  }),
})

const FileTagsChangeCodec = t.type({
  eventLogType: t.union([
    t.literal('TASK_TAG_CREATED'),
    t.literal('TASK_TAG_REMOVED'),
  ]),
  logInfo: t.type({
    fullName: t.string,
    tagName: t.string,
    avatar: t.union([t.string, t.null]),
  }),
})

export const FileEventLogCodec = t.intersection([
  t.type({
    eventLogId: t.string,
    createdAt: DateFromISOString,
  }),
  t.union([
    FileTextChangelogCodec,
    FileStatusChangelogCodec,
    FileShareChangelogCodec,
    FileTranscriptionResetCodec,
    FileChunkFineTuneCodec,
    FileSpeakerChangeCodec,
    FileTagsChangeCodec,
  ]),
])

export const FileEventLogTableCodec = t.array(FileEventLogCodec)

export type FileEventLog = t.TypeOf<typeof FileEventLogCodec>

export const FileSearchResultsCodec = t.array(
  t.type({
    chunkId: t.string,
    chunkIndex: t.number,
    page: t.number,
  }),
)

export type FileSearchResults = t.TypeOf<typeof FileSearchResultsCodec>
