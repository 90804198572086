import { MenuItem, Select, useTheme } from '@mui/material'

export type SelectRowsPerPageProps = Readonly<{
  rowsPerPage: number
  onRowsPerPageChange: (rowsPerPage: number) => void
  rowsOptions: ReadonlyArray<number>
}>

export const SelectRowsPerPage = ({
  rowsPerPage,
  onRowsPerPageChange,
  rowsOptions,
}: SelectRowsPerPageProps) => {
  const { palette } = useTheme()
  return (
    <Select
      inputProps={{
        sx: {
          color: palette.text.secondary,
          fontSize: 14,
        },
      }}
      onChange={event => onRowsPerPageChange(Number(event.target.value))}
      sx={{
        '.MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
        '&::before': {
          borderBottom: 'none',
        },
        '&::after': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '2px solid #8FA0FA',
        },
        '& > .MuiSelect-icon': {
          fill: palette.text.disabled,
        },
      }}
      value={rowsPerPage}
      variant="standard"
    >
      {rowsOptions.map(value => (
        <MenuItem key={value} value={value} dense={true}>
          {value}
        </MenuItem>
      ))}
    </Select>
  )
}
