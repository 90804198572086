import { IntegrationCodec } from 'app/codecs'
import { record, string, TypeOf } from 'io-ts'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import YouTubeUpdateForm from './YouTubeUpdateForm'

type Props = {
  onClose: () => void
  integrationData: TypeOf<typeof IntegrationCodec>
  refetch: () => void
}
type SubmitValues = {
  language: string
  subtitle: boolean
  sendInvite: boolean
  autoProcess: boolean
}

const YouTubeUpdate = ({ onClose, integrationData, refetch }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const $languages = useJsonQuery(
    'GET',
    '/api/tasks/languages',
    record(string, string),
  )

  const $updateIntegration = useMutation(
    'PUT',
    `/api/integrations/${integrationData.integrationId}`,
  )

  const handleSubmitForm = async (values: SubmitValues) => {
    await $updateIntegration.mutate(
      {
        body: {
          ...values,
        },
      },
      {
        onSuccess: () => {
          refetch()
          onClose()
          enqueueSnackbar(
            t('success_notification.integration_updated', { integrationData }),
            {
              variant: 'success',
            },
          )
        },
        onError: error => {
          if (error.type === 'client_error') {
            enqueueSnackbar(t(`error_notification.${error.code}`), {
              variant: 'error',
            })
          }

          if (error.type === 'server_error') {
            enqueueSnackbar(t(`server_error.${error.status}`), {
              variant: 'error',
            })
          }
        },
      },
    )
  }

  const languages = $languages.data

  if (!languages) {
    return null
  }

  return (
    <YouTubeUpdateForm
      integrationData={integrationData}
      onSubmitForm={handleSubmitForm}
      onClose={onClose}
      languages={languages}
    />
  )
}

export default YouTubeUpdate
