import {
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns'

export type PeriodOption = {
  label: string
  startDate: Date
  endDate: Date
}

const option = (label: string, start: Date, end: Date): PeriodOption => ({
  label,
  startDate: start,
  endDate: end,
})

type Unit = 'week' | 'month' | 'quarter' | 'year'

const startOf: Record<Unit, (date: Date | number) => Date> = {
  week: date => startOfWeek(date, { weekStartsOn: 1 }),
  month: startOfMonth,
  quarter: startOfQuarter,
  year: startOfYear,
}

const endOf: Record<Unit, (date: Date | number) => Date> = {
  week: date => endOfWeek(date, { weekStartsOn: 1 }),
  month: endOfMonth,
  quarter: endOfQuarter,
  year: endOfYear,
}

const add: Record<Unit, (date: Date | number, amount: number) => Date> = {
  week: addWeeks,
  month: addMonths,
  quarter: addQuarters,
  year: addYears,
}

const sub: Record<Unit, (date: Date | number, amount: number) => Date> = {
  week: subWeeks,
  month: subMonths,
  quarter: subQuarters,
  year: subYears,
}

const date = new Date()

const createDates = (unit: Unit) => ({
  this: {
    start: startOf[unit](date),
    end: endOf[unit](date),
  },
  last: {
    start: startOf[unit](sub[unit](date, 1)),
    end: endOf[unit](sub[unit](date, 1)),
  },
  next: {
    start: startOf[unit](add[unit](date, 1)),
    end: endOf[unit](add[unit](date, 1)),
  },
})

const today = date
const yesterday = subDays(date, 1)
const week = createDates('week')
const month = createDates('month')
const year = createDates('year')

export const periodOptions: PeriodOption[] = [
  option('today', today, today),
  option('yesterday', yesterday, yesterday),
  option('this_week', week.this.start, week.this.end),
  option('last_week', week.last.start, week.last.end),
  option('this_month', month.this.start, month.this.end),
  option('last_month', month.last.start, month.last.end),
  option('this_year', year.this.start, year.this.end),
  option('last_year', year.last.start, year.last.end),
]
