import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material'
import { ReactComponent as DropFile } from 'assets/icons/drop-file.svg'
import { Handler } from 'lib/react-handy-types'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { AfterUploadScreen } from './AfterUploadScreen'

type Props = {
  isLoading: boolean
  onChange: Handler<{ file: File | null }>
  disabled?: boolean
  accept?: Record<string, string[]>
  onThumbnailChange?: (file: File) => void
}

export const FileDropzone = ({
  onChange,
  disabled,
  isLoading,
  accept,
  onThumbnailChange,
}: Props) => {
  const [acceptedFile, setAcceptedFile] = useState<null | File>(null)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      setAcceptedFile(acceptedFiles[0])
      onChange({ file: acceptedFiles[0] })
    },
    onDropRejected: () => {
      enqueueSnackbar(t(`upload_file.file_requirements`), {
        variant: 'error',
      })
    },
    disabled,
    accept,
    maxSize: 10 * 1024 * 1024 * 1024, //10GB
    useFsAccessApi: false,
  })

  return (
    <Grid container spacing={2} mt={0.4}>
      {isLoading ? (
        <Grid item xs={12}>
          <LinearProgress color="primary" />
        </Grid>
      ) : (
        <>
          <Grid
            {...getRootProps({ className: 'dropzone disabled' })}
            item
            xs={12}
          >
            <Box
              mt={1}
              width="100%"
              border="1px dashed #AAA"
              borderRadius={2}
              py="20px"
              px="16px"
            >
              {!acceptedFile && (
                <Grid item xs={12} mt={2.5}>
                  <Grid container justifyContent="center">
                    <DropFile />
                  </Grid>
                </Grid>
              )}

              {acceptedFile ? (
                <AfterUploadScreen
                  onThumbnailChange={onThumbnailChange}
                  fileName={acceptedFile.name}
                />
              ) : (
                <>
                  <input {...getInputProps()} />
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} mb={1}>
                      <Typography
                        align="center"
                        color="text.secondary"
                        sx={{ fontWeight: 500 }}
                      >
                        {t(
                          `upload_file.${
                            isDragActive
                              ? 'drop_files_here'
                              : 'select_file_or_drag'
                          }`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    align="center"
                    color="text.disabled"
                    fontSize={'14px'}
                    sx={{ opacity: 0.7 }}
                  >
                    {t('upload_file.file_requirements')}
                  </Typography>
                </>
              )}
              <Grid
                item
                xs={12}
                alignContent="center"
                display="flex"
                justifyContent="center"
                mt={acceptedFile ? 9 : 2}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disabled}
                  onClick={() => {
                    // remove file
                    if (acceptedFile) {
                      setAcceptedFile(null)
                      onChange({ file: null })
                    }
                  }}
                  sx={{
                    borderRadius: '19px',
                    fontSize: '14px',
                    textTransform: 'inherit',
                  }}
                >
                  {t(
                    `upload_file.${
                      acceptedFile ? 'remove_file' : 'select_file'
                    }`,
                  )}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}
