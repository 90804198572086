import { Grid } from '@mui/material'
import { ProfileCodec } from 'app/codecs'
import { FormInput, FormModal } from 'components/Form'
import { validation } from 'config/validation'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  organization: string
}

type Props = {
  onClose: () => void
  open: boolean
  organizationName: string | undefined
}

export const EditProfileModal = ({
  organizationName = '',
  open,
  onClose,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const $profile = useJsonQuery('GET', '/api/users/me', ProfileCodec)
  const { t } = useTranslation()
  const { data, refetch } = $profile
  const $updateProfile = useMutation('PUT', '/api/users/me')

  if (!data) return null

  const onSubmit = (data: FormValues) => {
    $updateProfile.mutate(
      { body: { firstName: data.firstName, lastName: data.lastName } },
      {
        onSuccess: () => {
          enqueueSnackbar(t('success_notification.profile_was_updated'), {
            variant: 'success',
          })
          refetch()
          onClose()
        },
      },
    )
  }

  return (
    <FormModal<FormValues>
      onSubmit={onSubmit}
      title={t('labels.edit_profile')}
      submitTitle={t('buttons.save')}
      onClose={onClose}
      open={open}
      sx={{ marginTop: 4.5 }}
      isLoading={$updateProfile.isLoading}
      defaultValues={{
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        organization: organizationName,
      }}
      renderForm={({ control }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} mb={1}>
            <FormInput
              label={t('labels.first_name')}
              control={control}
              maxLength={validation.commonFieldMaxLength}
              minLength={validation.commonFieldMinLength}
              name="firstName"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <FormInput
              label={t('labels.last_name')}
              control={control}
              maxLength={validation.commonFieldMaxLength}
              minLength={validation.commonFieldMinLength}
              name="lastName"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <FormInput
              label={t('labels.email')}
              control={control}
              maxLength={validation.commonFieldMaxLength}
              minLength={validation.commonFieldMinLength}
              name="email"
              required
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label={t('labels.organization')}
              control={control}
              name="organization"
              required={false}
              disabled
              fullWidth
              placeholder={organizationName}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
