import { IIntegrationType } from 'app/codecs'

export const integrationTypeMapping: Record<IIntegrationType, string> = {
  google: 'YouTube',
  ibm: 'IBM',
  '23v': '23v',
  kaltura: 'Kaltura',
  brightcove: 'Brightcove',
  zoom: 'Zoom',
}

export const nativeIntegrationsList = [
  'kaltura',
  'file',
  '23v',
  'google',
  'ibm',
  'brightcove',
  'zoom',
]
