import { Grid, Paper, SxProps, Theme, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  helperButton?: ReactNode
  label: string
  helperText?: ReactNode
  sx?: SxProps<Theme>
  children: ReactNode
}

export const UnauthorizedFormContainer = (props: Props) => {
  return (
    <Paper
      sx={{
        p: theme => theme.spacing(4),
        m: '0 auto',
        ...props.sx,
      }}
    >
      <Grid spacing={2} container>
        {props.helperButton && (
          <Grid item xs={12} pb={1}>
            {props.helperButton}
          </Grid>
        )}
        <Grid item xs={12} pb={props.helperText ? 0 : 1}>
          <Typography variant="h1" sx={{ fontWeight: 500 }}>
            {props.label}
          </Typography>
        </Grid>
        {props.helperText && (
          <Grid item xs={12} pb={1}>
            {props.helperText}
          </Grid>
        )}
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  )
}
