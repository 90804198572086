import 'react-day-picker/dist/style.css'
import './styles/date-range-picker.css'

import { Box, InputAdornment, Popover, Stack } from '@mui/material'
import { Input } from 'components/Input'
import { endOfDay, format as formatDate, startOfDay } from 'date-fns'
import { useUuid } from 'lib/use-uuid'
import { memo, MouseEvent, useEffect, useState } from 'react'
import { DayPicker, Matcher } from 'react-day-picker'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon, ArrowRightIcon } from './components/Arrows'
import { CalendarInfo } from './components/CalendarInfo'
import { ReactComponent as CalendarIcon } from './icons/calendar.svg'

export type Value = {
  startDate: Date | null
  endDate: Date | null
}

type AnchorDirectionShape = 'left' | 'right'

type Props = {
  value: Value
  onChange: (value: Value) => void
  isDisabled?: boolean
  isOutsideRange?: Matcher | Matcher[]
  anchorDirection?: AnchorDirectionShape
  format?: string
  withCalendarInfo?: boolean
}

const DateRangePickerBase = ({
  value,
  onChange,
  isDisabled = false,
  isOutsideRange = () => false,
  anchorDirection = 'left',
  format = 'dd MMM yyyy',
  withCalendarInfo = true,
}: Props) => {
  const { t } = useTranslation()

  const [focusedInput, setFocusedInput] = useState<HTMLElement | null>(null)
  const [selectedValue, setSelectedValue] = useState<Value>(value)

  const id = useUuid()
  const open = Boolean(focusedInput)

  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    const input = event.currentTarget
    const inputWrapper = input.parentElement!
    const dateRangeBox = inputWrapper.parentElement!

    if (!isDisabled) {
      setFocusedInput(dateRangeBox)
    }
  }

  const handleClose = () => {
    setFocusedInput(null)
  }

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  const applyIsDisabled =
    selectedValue.startDate === null ||
    selectedValue.endDate === null ||
    selectedValue.startDate.getTime() > selectedValue.endDate.getTime()

  return (
    <>
      <Stack direction="row" spacing={2} flexWrap="nowrap">
        <Input
          aria-describedby={id}
          id="startDate"
          readOnly
          placeholder={t('placeholders.start_date')}
          value={value.startDate ? formatDate(value.startDate, format) : ''}
          onClick={handleClick}
          endAdornment={
            <InputAdornment position="end">
              <CalendarIcon />
            </InputAdornment>
          }
          sx={{ width: 150, fontWeight: 400, borderBottomColor: '#CED7DE' }}
          disabled={isDisabled}
        />

        <Input
          aria-describedby={id}
          id="endDate"
          readOnly
          placeholder={t('placeholders.end_date')}
          value={value.endDate ? formatDate(value.endDate, format) : ''}
          onClick={handleClick}
          endAdornment={
            <InputAdornment position="end">
              <CalendarIcon />
            </InputAdornment>
          }
          sx={{ width: 150, fontWeight: 400, borderBottomColor: '#CED7DE' }}
          disabled={isDisabled}
        />
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={focusedInput}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchorDirection,
        }}
        transformOrigin={{
          vertical: -14,
          horizontal: anchorDirection,
        }}
      >
        <Box>
          <CalendarInfo
            withSideBar={withCalendarInfo}
            onSelectPeriodOption={periodOption => {
              setSelectedValue({
                startDate: startOfDay(periodOption.startDate),
                endDate: endOfDay(periodOption.endDate),
              })
            }}
            value={selectedValue}
            onChangeValue={setSelectedValue}
            onClickCancel={() => {
              setSelectedValue(value)
              setFocusedInput(null)
            }}
            applyIsDisabled={applyIsDisabled}
            onClickApply={() => {
              if (applyIsDisabled) {
                return
              }
              onChange(selectedValue)
              setFocusedInput(null)
            }}
          >
            <DayPicker
              components={{
                IconLeft: ArrowLeftIcon,
                IconRight: ArrowRightIcon,
              }}
              mode="range"
              selected={{
                from: selectedValue.startDate ?? undefined,
                to: selectedValue.endDate ?? undefined,
              }}
              disabled={isOutsideRange}
              onSelect={newValue => {
                setSelectedValue({
                  startDate: newValue?.from ? startOfDay(newValue.from) : null,
                  endDate: newValue?.to ? endOfDay(newValue.to) : null,
                })
              }}
              weekStartsOn={1}
              numberOfMonths={2}
              defaultMonth={selectedValue.startDate ?? undefined}
            />
          </CalendarInfo>
        </Box>
      </Popover>
    </>
  )
}

export const DateRangePicker = memo(DateRangePickerBase)

export type DateRangePickerValue = Value
export type DateRangePickerProps = Props
