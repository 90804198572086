import { createRequest, createRequestMethods } from 'lib/request'
import { globalAccessToken } from 'lib/use-auth'

export const { get, post, put, del, patch, options } = createRequestMethods(
  createRequest(requestInit => {
    // TODO: Consider replacing with an injectable service.
    const headers = new Headers(requestInit.headers)

    if (globalAccessToken !== null) {
      headers.set('Authorization', `Bearer ${globalAccessToken}`)
    }

    return {
      ...requestInit,
      headers,
    }
  }),
)
