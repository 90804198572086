import { Box, InputBase as MuiInputBase, InputBaseProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { forwardRef } from 'react'

export const InputBase = forwardRef((props: InputBaseProps, ref) => {
  const { fullWidth = true, ...rest } = props
  const theme = useTheme()
  return (
    <Box
      sx={{
        '& .MuiInputBase-root': {
          'label + &': {
            marginTop: theme.spacing(2),
          },
          borderBottom: '1px solid #CED7DE',
        },
        '& .Mui-error': {
          borderBottom: `2px solid ${theme.palette.error.main}`,
        },
        '& .MuiInputBase-input': {
          color: '#003049',

          position: 'relative',
          fontSize: theme.typography.pxToRem(16),
        },
      }}
    >
      <MuiInputBase ref={ref} {...rest} fullWidth={fullWidth} />
    </Box>
  )
})

export const Input = forwardRef((props: InputBaseProps, ref) => {
  const { fullWidth = true, ...rest } = props
  return <InputBase fullWidth={fullWidth} ref={ref} {...rest} />
})
