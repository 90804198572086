import { Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import { ReactComponent as Logo } from 'assets/icons/main-logo.svg'

export const nav = () => {
  window.location.href = 'https://www.txtplay.ai/'
}

const ResponsiveAppBar = () => {
  return (
    <AppBar
      position="static"
      sx={{
        height: 60,
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: { xs: 3.5, md: 6 },
        justifyContent: {
          md: 'center',
          xs: 'center',
        },
        background:
          'linear-gradient(90deg, rgba(49,101,133,1) 0%, rgba(43,93,128,1) 50%, rgba(36,82,117,1) 100%)',
      }}
    >
      <Box onClick={nav} sx={{ cursor: 'pointer' }}>
        <Logo />
      </Box>
    </AppBar>
  )
}
export default ResponsiveAppBar
