import { Grid, Paper, Typography } from '@mui/material'
import { Button } from 'components'
import { Form, FormPasswordInput } from 'components/Form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormValues = {
  password: string
}

type Props = {
  onSubmit: (values: FormValues) => void
}

export const InvitationForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation()
  const form = useForm<FormValues>({
    defaultValues: { password: '' },
  })

  const { control } = form

  return (
    <Paper
      sx={{
        p: theme => theme.spacing(4),
        margin: '0 auto',
        maxWidth: { xl: '25%', md: '400px' },
      }}
    >
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h1" sx={{ fontWeight: 500 }}>
                  {t('common.accept_invite')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{t('common.enter_new_password')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormPasswordInput
              control={control}
              label={t('labels.password')}
              name="password"
              required
              fullWidth
              placeholder={t('labels.password')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={6} sm={4}>
                <Button
                  withShadow
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  {t('buttons.sign_up')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  )
}
