import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import { IIntegrationType, IntegrationCodec } from 'app/codecs'
import { Button, Switch } from 'components'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { FormInput } from 'components/Form'
import { integrationTypeMapping } from 'config/integration-options'
import { TypeOf } from 'io-ts'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import validator from 'validator'

import { ActivateDeactivateSwitch } from '../ActivateDeactivateSwitch'
import DisabledField from '../DisabledField'

type Props = {
  onClose: () => void
  integrationData: TypeOf<typeof IntegrationCodec>
  languages: {
    [languageName: string]: string
  }
  onSubmitForm: (values: {
    language: string
    subtitle: boolean
    sendInvite: boolean
    onPremiseApiEndpoint?: string
  }) => void
}
type FormFields = {
  language: string
  subtitle: boolean
  sendInvite: boolean
  onPremiseApiEndpoint: string
  customServer: boolean
}

const KalturaUpdateForm = ({
  onClose,
  onSubmitForm,
  integrationData,
  languages,
}: Props) => {
  const { t } = useTranslation()

  const { control, handleSubmit, watch } = useForm<FormFields>({
    defaultValues: {
      language: integrationData.language,
      subtitle: integrationData.subtitle,
      sendInvite: integrationData.sendInvite,
      onPremiseApiEndpoint: integrationData.onPremiseConfig?.apiEndpoint,
      customServer: Boolean(integrationData.onPremiseConfig?.apiEndpoint),
    },
  })

  const isCustomServer = watch('customServer')

  const { secret, partnerId, language, type } = integrationData
  if (!secret || !partnerId || !language) {
    return null
  }

  return (
    <form
      onSubmit={handleSubmit(
        ({ language, subtitle, sendInvite, onPremiseApiEndpoint }) => {
          onSubmitForm({ language, subtitle, sendInvite, onPremiseApiEndpoint })
        },
      )}
    >
      <Stack spacing={2}>
        <DisabledField
          title={t('labels.type_of_integration')}
          value={integrationTypeMapping[type as IIntegrationType]}
        />

        <DisabledField
          title={t('labels.administrator_secret')}
          value={secret}
        />

        <DisabledField title={t('labels.partner_id')} value={partnerId} />

        <ControlledAutocomplete
          control={control}
          label={t('integrations.choose_default_language')}
          name="language"
          options={Object.keys(languages).map(key => ({
            label: languages[key],
            value: key,
          }))}
          required
        />
        <Controller
          name="customServer"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch
              onChange={onChange}
              isChecked={value}
              label={t('labels.custom_server')}
              labelPlacement="end"
            />
          )}
        />

        {isCustomServer && (
          <Stack direction="column">
            <FormInput
              required
              name="onPremiseApiEndpoint"
              control={control}
              placeholder="https://videos.kaltura.com"
              label={t('labels.service_url')}
              validate={{
                url: value =>
                  validator.isURL(value as string) || t(`validation.valid_url`),
              }}
            />
          </Stack>
        )}

        <Stack>
          <ActivateDeactivateSwitch control={control} />

          <Controller
            name="sendInvite"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label={`${t('integrations.send_invite')}`}
                control={
                  <Checkbox
                    color="primary"
                    {...field}
                    size="small"
                    checked={field.value}
                  />
                }
              />
            )}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button
            noSpacing
            variant="text"
            onClick={onClose}
            sx={{ textTransform: 'capitalize' }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            noSpacing
            withShadow
            type="submit"
            color="primary"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
          >
            {t('common.update')}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default KalturaUpdateForm
