import { useQueryState } from 'react-router-use-location-state'

import { useValidValue } from './use-valid-value'

const nullValue = '__null'

const fromNullable = <T,>(value: T | null): T | typeof nullValue => {
  return value === null ? nullValue : value
}

const toNullable = <T,>(value: T | typeof nullValue): T | null => {
  return value === nullValue ? null : value
}

type UseQueryStateValidatedInput<T> = Readonly<{
  key: string
  defaultValue: T
  isValid: (value: T) => boolean
}>

/**
 * Like useQueryState, but doesn't allow an invalid value to be set.
 */
export const useQueryStateValidatedNullable = <T,>({
  key,
  defaultValue,
  isValid,
}: UseQueryStateValidatedInput<T>) => {
  const [_state, setState] = useQueryState(key, fromNullable(defaultValue), {
    stripDefaults: false, // It doesn't work when `true` in react-router-use-location-state@3.0.2
  })

  const state = useValidValue({
    value: _state,
    defaultValue: fromNullable(defaultValue),
    isValid: v => v === nullValue || isValid(v),
    setValue: setState,
  })

  return [
    toNullable(state),
    (newValue: T | null) => {
      setState(newValue)
    },
  ] as const
}

/**
 * Like useQueryState, but doesn't allow an invalid value to be set.
 */
export const useQueryStateValidated = <T,>({
  key,
  defaultValue,
  isValid,
}: UseQueryStateValidatedInput<T>) => {
  const [_state, setState] = useQueryState(key, defaultValue, {
    stripDefaults: false, // It doesn't work when `true` in react-router-use-location-state@3.0.2
  })

  const state = useValidValue({
    value: _state,
    defaultValue,
    isValid,
    setValue: setState,
  })

  return [state, setState] as const
}
