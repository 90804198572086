import { Stack, Typography } from '@mui/material'
import { memo } from 'react'

const DisabledField = memo(
  ({ title, value }: { title: string; value: string }) => (
    <Stack direction="column">
      <Typography variant="body2" sx={{ opacity: 0.5 }}>
        {title}
      </Typography>
      <Typography
        component="span"
        sx={{
          borderBottom: 'solid 1px',
          borderColor: 'info.main',
          color: 'info.main',
          fontSize: 18,
          overflowX: 'auto',
        }}
      >
        {value}
      </Typography>
    </Stack>
  ),
)

export default DisabledField
