import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import {
  IIntegrationType,
  IntegrationsCodec,
  TIntegrationType,
} from 'app/codecs'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import YouTubeBlackLogo from 'assets/images/yt_logo_black.png'
import YouTubeWhiteLogo from 'assets/images/yt_logo_white.png'
import { Button, Modal, Switch, Tooltip } from 'components'
import { ControlledAutocomplete } from 'components/ControlledAutocomplete/ControlledAutocomplete'
import { FormInput } from 'components/Form'
import { integrationTypeMapping } from 'config/integration-options'
import * as d from 'io-ts'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import validator from 'validator'

type Props = {
  onClose: () => void
  open: boolean
  integrationType: d.TypeOf<typeof TIntegrationType> | ''
  refetchIntegrations: Function
}

type FormValues = {
  type: d.TypeOf<typeof TIntegrationType> | ''
  subtitle: boolean
  language: string
  secret: string
  partnerId: string
  sendInvite: boolean
  autoProcess: boolean
  customServer: boolean
  onPremiseApiEndpoint: string
  clientId: string
  clientSecret: string
  accountId: string
}

export const CreateIntegrationModal = ({
  integrationType,
  onClose,
  open,
  refetchIntegrations,
}: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [logo, setLogo] = useState<string>(YouTubeBlackLogo)

  const $languages = useJsonQuery(
    'GET',
    '/api/tasks/languages',
    d.record(d.string, d.string),
  )

  const $integrate = useMutation('POST', `/api/integrations`, IntegrationsCodec)

  const defaultValues = {
    type: integrationType,
    partnerId: '',
    subtitle: true,
    sendInvite: true,
    autoProcess: true,
    language: '',
    secret: '',
    onPremiseApiEndpoint: '',
    clientId: '',
    clientSecret: '',
    accountId: '',
  }

  const { control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues,
  })

  const watchAllFields = watch()
  const integrate = $integrate.mutate

  const isDisableSubmitButton: boolean = useMemo(() => {
    if (
      watchAllFields.type === '23v' &&
      watchAllFields.language &&
      watchAllFields.clientId &&
      watchAllFields.clientSecret
    ) {
      return false
    }

    if (
      watchAllFields.type === 'kaltura' &&
      watchAllFields.secret &&
      watchAllFields.partnerId &&
      watchAllFields.language
    ) {
      return false
    }
    if (
      watchAllFields.type === 'ibm' &&
      watchAllFields.clientSecret &&
      watchAllFields.clientId &&
      watchAllFields.language
    ) {
      return false
    }
    if (
      watchAllFields.type === 'brightcove' &&
      watchAllFields.clientSecret &&
      watchAllFields.clientId &&
      watchAllFields.accountId &&
      watchAllFields.language
    ) {
      return false
    }
    if (watchAllFields.type === 'google' && watchAllFields.language) {
      return false
    }

    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (watchAllFields.type === 'zoom' && watchAllFields.language) {
      return false
    }

    return true
  }, [
    watchAllFields.type,
    watchAllFields.language,
    watchAllFields.clientId,
    watchAllFields.clientSecret,
    watchAllFields.secret,
    watchAllFields.partnerId,
    watchAllFields.accountId,
  ])

  const { data } = $languages
  if (!data) {
    return null
  }

  const onSubmit = (data: Omit<FormValues, 'customServer'>) => {
    const search = getIntegrationParams(data)
    integrate(
      { search },
      {
        onSuccess: res => {
          if (res.url) {
            window.open(res.url, '_blank')
          }
          refetchIntegrations()
          enqueueSnackbar(t('success_notification.integration_added'), {
            variant: 'success',
          })
          onClose()
        },
        onError: error => {
          if (error.type === 'client_error') {
            enqueueSnackbar(t(`error_notification.${error.code}`), {
              variant: 'error',
            })
          }

          if (error.type === 'server_error') {
            enqueueSnackbar(t(`server_error.${error.status}`), {
              variant: 'error',
            })
          }
        },
      },
    )
  }

  const getIntegrationParams = (data: Omit<FormValues, 'customServer'>) => {
    if (data.type === '23v') {
      return new URLSearchParams({
        type: '23v',
        subtitle: data.subtitle.toString(),
        autoProcess: data.autoProcess.toString(),
        language: data.language,
        clientSecret: data.clientSecret,
        clientId: data.clientId,
      })
    }
    if (data.type === 'google') {
      return new URLSearchParams({
        type: 'google',
        subtitle: data.subtitle.toString(),
        sendInvite: data.sendInvite.toString(),
        language: data.language,
      })
    }
    if (data.type === 'zoom') {
      return new URLSearchParams({
        type: 'zoom',
        subtitle: data.subtitle.toString(),
        sendInvite: data.sendInvite.toString(),
        language: data.language,
        autoProcess: data.autoProcess.toString(),
      })
    }
    if (data.type === 'ibm') {
      return new URLSearchParams({
        type: 'ibm',
        subtitle: data.subtitle.toString(),
        language: data.language,
        clientSecret: data.clientSecret,
        clientId: data.clientId,
        autoProcess: data.autoProcess.toString(),
      })
    }
    if (data.type === 'brightcove') {
      return new URLSearchParams({
        type: 'brightcove',
        subtitle: data.subtitle.toString(),
        sendInvite: data.sendInvite.toString(),
        language: data.language,
        clientSecret: data.clientSecret,
        clientId: data.clientId,
        accountId: data.accountId,
      })
    }
    return new URLSearchParams({
      type: 'kaltura',
      secret: data.secret,
      subtitle: data.subtitle.toString(),
      sendInvite: data.sendInvite.toString(),
      language: data.language,
      partnerId: data.partnerId,
      ...(watchAllFields.customServer && {
        onPremiseApiEndpoint: data.onPremiseApiEndpoint,
      }),
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box p={3} width="444px">
        <Box mb={4}>
          <Grid container rowSpacing={5}>
            <Grid item xs={11}>
              <Typography variant="h2">
                {t('pages.create_integration')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              justifyContent="flex-end"
              alignItems="center"
              display="flex"
            >
              <Tooltip
                title={
                  <Stack>
                    {t('integrations.supported_features')}
                    <Box>- {t('integrations.automatic_transcribing')}</Box>
                    {integrationType !== 'ibm' && integrationType !== '23v' && (
                      <Box>- {t('integrations.automatic_user_invites')}</Box>
                    )}
                    {integrationType === 'kaltura' && (
                      <Box>
                        - {t('integrations.custom_server_configuration')}
                      </Box>
                    )}
                  </Stack>
                }
              >
                <InfoIcon />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>

        <form
          onSubmit={handleSubmit(values => {
            onSubmit(values)
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="column">
                <Typography variant="h4">
                  {t('integrations.choose_type_integration')}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    opacity: 0.5,
                    borderBottom: 'dashed 1px lightgray',
                    color: 'gray',
                    fontSize: 18,
                  }}
                >
                  {
                    integrationTypeMapping[
                      watchAllFields.type as IIntegrationType
                    ]
                  }
                </Typography>
              </Stack>
            </Grid>

            {watchAllFields.type === 'kaltura' && (
              <Grid item xs={12}>
                <FormInput
                  placeholder={t('labels.administrator_secret')}
                  control={control}
                  name="secret"
                  fullWidth
                  required
                />
              </Grid>
            )}
            {watchAllFields.type === 'kaltura' && (
              <Grid item xs={12}>
                <FormInput
                  placeholder={t('labels.partner_id')}
                  control={control}
                  name="partnerId"
                  fullWidth
                  required
                />
              </Grid>
            )}
            {(watchAllFields.type === 'ibm' ||
              watchAllFields.type === '23v' ||
              watchAllFields.type === 'brightcove') && (
              <Grid item xs={12}>
                <FormInput
                  placeholder={
                    watchAllFields.type === 'ibm' ||
                    watchAllFields.type === 'brightcove'
                      ? t('labels.client_id')
                      : t('labels.consumer_key')
                  }
                  control={control}
                  name="clientId"
                  fullWidth
                  required
                />
              </Grid>
            )}
            {(watchAllFields.type === 'ibm' ||
              watchAllFields.type === '23v' ||
              watchAllFields.type === 'brightcove') && (
              <Grid item xs={12}>
                <FormInput
                  placeholder={
                    watchAllFields.type === 'ibm' ||
                    watchAllFields.type === 'brightcove'
                      ? t('labels.client_secret')
                      : t('labels.consumer_secret')
                  }
                  control={control}
                  name="clientSecret"
                  fullWidth
                  required
                />
              </Grid>
            )}
            {watchAllFields.type === 'brightcove' && (
              <Grid item xs={12}>
                <FormInput
                  label=""
                  placeholder={t('placeholders.account_id')}
                  control={control}
                  name="accountId"
                  fullWidth
                  required
                />
              </Grid>
            )}

            {watchAllFields.type && (
              <Grid container item xs={12}>
                <Typography variant="h4">
                  {t('integrations.choose_default_language')}*
                </Typography>
                <ControlledAutocomplete
                  control={control}
                  label={''}
                  name="language"
                  options={Object.keys(data).map(key => ({
                    label: data[key],
                    value: key,
                  }))}
                  required
                />
              </Grid>
            )}

            {watchAllFields.type === 'kaltura' && (
              <Grid container item xs={12}>
                <Controller
                  name="customServer"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      onChange={onChange}
                      isChecked={value}
                      label={t('labels.custom_server')}
                      labelPlacement="end"
                    />
                  )}
                />
              </Grid>
            )}

            {watchAllFields.type === 'kaltura' &&
              watchAllFields.customServer && (
                <Grid container item xs={12} direction="column">
                  <FormInput
                    required
                    placeholder="https://videos.kaltura.com"
                    name="onPremiseApiEndpoint"
                    control={control}
                    label={t('labels.service_url')}
                    validate={{
                      url: value =>
                        validator.isURL(value as string) ||
                        t(`validation.valid_url`),
                    }}
                  />
                </Grid>
              )}
            <Stack mt={1} pl={2}>
              {watchAllFields.type !== 'ibm' &&
                watchAllFields.type !== '23v' && (
                  <Controller
                    name="sendInvite"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label={`${t('integrations.send_invite')}`}
                        control={
                          <Checkbox
                            color="primary"
                            {...field}
                            size="small"
                            checked={field.value}
                          />
                        }
                      />
                    )}
                  />
                )}
              {(watchAllFields.type === '23v' ||
                watchAllFields.type === 'zoom') && (
                <Controller
                  name="autoProcess"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      label={`${t('integrations.auto_process_media')}`}
                      control={
                        <Checkbox
                          color="primary"
                          {...field}
                          size="small"
                          checked={field.value}
                        />
                      }
                    />
                  )}
                />
              )}
            </Stack>

            <Grid
              container
              spacing={2}
              mt={1.4}
              pl={2}
              pb={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={5}>
                <Button
                  noSpacing
                  variant="text"
                  fullWidth
                  onClick={onClose}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t('buttons.discard')}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  noSpacing
                  withShadow
                  type="submit"
                  color="primary"
                  variant={
                    watchAllFields.type === 'google' ? 'text' : 'contained'
                  }
                  fullWidth
                  disabled={isDisableSubmitButton}
                  sx={{ textTransform: 'capitalize' }}
                  onMouseEnter={() => {
                    if (watchAllFields.type === 'google') {
                      setLogo(YouTubeWhiteLogo)
                    }
                  }}
                  onMouseLeave={() => {
                    if (watchAllFields.type === 'google') {
                      setLogo(YouTubeBlackLogo)
                    }
                  }}
                >
                  {watchAllFields.type === 'google' ? (
                    <img alt="YouTube" src={logo} height={18} />
                  ) : (
                    t('common.create')
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  )
}
