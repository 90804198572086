import ButtonBase from '@mui/material/ButtonBase'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

import { PeriodOption } from '../../utils/periodOptions'

type Props = {
  isSelected: boolean
  value: PeriodOption
  onSelect: (option: PeriodOption) => void
}

const useStyles = makeStyles()(theme => ({
  root: {
    ...(theme.typography.button as object),
    display: 'block',
    width: '100%',
    textAlign: 'left',
    padding: '6px 32px 6px 16px',
    color: '#212b36',
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
}))

export const PeriodOptionButton = ({ isSelected, value, onSelect }: Props) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const onClick = useCallback(() => {
    onSelect(value)
  }, [onSelect, value])

  return (
    <ButtonBase
      className={classes.root}
      disableRipple
      style={{
        fontWeight: isSelected ? 700 : 300,
      }}
      onClick={onClick}
    >
      {t(`periods.${value.label}`)}
    </ButtonBase>
  )
}
