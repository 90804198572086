import { SnackbarProvider } from 'notistack'
import { FC, ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'
const useStyles = makeStyles()({
  containerRoot: {
    width: 'inherit',
  },
  variantSuccess: {
    backgroundColor: '#4AC398 !important',
  },
  variantError: {
    backgroundColor: '#E03C31 !important',
  },
})

export const NotificationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { classes } = useStyles()
  return (
    <SnackbarProvider
      action={() => null}
      autoHideDuration={3500}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={classes}
    >
      {children}
    </SnackbarProvider>
  )
}
