import { format } from 'date-fns'

export const formatCreationDate = (date: Date) =>
  format(date, 'dd MMMM yyyy, hh:mm aa')

export const fromOptional = (
  value: string | number | null | undefined,
): string => {
  if (value === undefined || value === null) {
    return '-'
  }

  return String(value)
}
