import { IGeneratePreSignedUrls } from 'app/codecs/multipart-upload'
import { throwError } from 'lib/rest-query/common'

// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
class Uploader {
  chunkSize: number
  threadsQuantity: number
  file: File
  fileName: string
  language: string
  numberOfParts: number
  aborted: boolean
  uploadedSize: number
  progressCache: any
  activeConnections: any
  parts: Array<{
    signedUrl: string
    partNumber: number
  }>
  uploadedParts: Array<{
    eTag: string
    partNumber: number
  }>
  fileId: string | null
  fileKey: string | null
  tags: string[]
  translationLanguageCodes: string[]
  accessToken?: string
  onProgressFn: (track: {
    sent: number
    total: number
    percentage: number
  }) => void
  onErrorFn: (error: any) => void
  constructor(options: {
    fileName: string
    file: File
    chunkSize?: number
    threadsQuantity?: number
    accessToken?: string
    numberOfParts: number
    language: string
    tags: string[]
    translationLanguageCodes: string[]
  }) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.numberOfParts = options.numberOfParts
    this.chunkSize = options.chunkSize || 1024 * 1024 * 50
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
    this.file = options.file
    this.fileName = options.fileName
    this.language = options.language
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.activeConnections = {}
    this.parts = []
    this.uploadedParts = []
    this.fileId = null
    this.fileKey = null
    this.accessToken = options.accessToken
    this.tags = options.tags
    this.translationLanguageCodes = options.translationLanguageCodes
    this.onProgressFn = () => {}
    this.onErrorFn = () => {}
  }

  // starting the multipart upload request
  start() {
    this.initialize()
  }

  async initialize() {
    try {
      // initializing the multipart request
      const videoInitializationUploadInput = {
        partsNumber: this.numberOfParts,
        mimeType: this.file.type,
        fileName: encodeURIComponent(this.fileName),
      }

      const initializeResponse = await fetch('api/files/multipart', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(videoInitializationUploadInput),
      })

      try {
        if (initializeResponse.status === 400) {
          const json = await initializeResponse.json()
          throwError({
            type: 'client_error',
            message: json.message,
            code: json.code,
          })
        }

        const initializeData: IGeneratePreSignedUrls =
          await initializeResponse.json()

        if (initializeResponse.ok && initializeData) {
          const AWSFileDataOutput = initializeData

          this.fileId = AWSFileDataOutput.uploadId
          this.fileKey = AWSFileDataOutput.fileKey

          const newParts = initializeData.parts

          this.parts.push(...newParts)

          this.sendNext()
        }
      } catch (error) {
        await this.complete(error)
      }
    } catch (error) {
      await this.complete(error)
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (this.parts.length === 0) {
      if (!activeConnections) {
        // @ts-ignore
        this.complete()
      }

      return
    }

    const part = this.parts.pop()
    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext()
        })
        .catch(error => {
          this.parts.push(part)

          this.complete(error)
        })
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error: any) {
    if (this.aborted) {
      return
    }
    if (error) {
      this.onErrorFn(error)
      return
    }
    try {
      await this.sendCompleteRequest()
    } catch (error) {
      this.onErrorFn(error)
    }
  }

  getFileDuration(file: File) {
    return new Promise<number>(resolve => {
      const objectURL = URL.createObjectURL(file)
      const mySound = new Audio(objectURL)
      mySound.addEventListener(
        'canplaythrough',
        () => {
          URL.revokeObjectURL(objectURL)
          resolve(mySound.duration)
          mySound.remove()
        },
        false,
      )
    })
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const duration = await this.getFileDuration(this.file)

      const videoFinalizationMultiPartInput = {
        uploadId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
        filename: encodeURIComponent(this.fileName),
        originalFilename: this.file.name,
        mimeType: this.file.type,
        fileSize: this.file.size,
        duration: Math.floor(duration),
      }

      const finalize = await fetch('api/files/multipart/finalize', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(videoFinalizationMultiPartInput),
      })

      try {
        if (finalize.status === 400) {
          const json = await finalize.json()

          throwError({
            type: 'client_error',
            message: json.message,
            code: json.code,
          })
        }

        const finalizeData: { fileId: string } = await finalize.json()

        this.fileId = finalizeData.fileId

        const createTask = await fetch('api/tasks', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            mediaId: this.fileId,
            languageCode: this.language,
            tags: this.tags,
            thumbnailId: undefined,
            translationLanguageCodes: this.translationLanguageCodes,
          }),
        })

        try {
          if (createTask.status === 400) {
            const json = await createTask.json()
            throwError({
              type: 'client_error',
              message: json.message,
              code: json.code,
            })
          }
        } catch (error: any) {
          throwError(error)
        }
      } catch (error: any) {
        throwError(error)
      }
    }
  }

  sendChunk(
    chunk: Blob,
    part: { signedUrl: string; partNumber: number },
    sendChunkStarted: () => void,
  ) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then(status => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'))
            return
          }

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: number, event: { type: string; loaded: any }) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      })
    }
  }

  // uploading a part through its pre-signed URL
  upload(
    file: Blob,
    part: { signedUrl: any; partNumber: number },
    sendChunkStarted: () => void,
  ) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest())

        sendChunkStarted()

        const progressListener = this.handleProgress.bind(
          this,
          part.partNumber - 1,
        )

        xhr.upload.addEventListener('progress', progressListener)
        xhr.addEventListener('error', progressListener)
        xhr.addEventListener('abort', progressListener)
        xhr.addEventListener('loadend', progressListener)

        xhr.open('PUT', part.signedUrl)
        // "Access-Control-Allow-Origin": "*",
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        xhr.setRequestHeader('Access-Control-Expose-Headers', 'ETag')

        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag')

            if (ETag) {
              const uploadedPart = {
                partNumber: part.partNumber,
                // removing the " enclosing characters from
                // the raw ETag
                eTag: ETag.replaceAll('"', ''),
              }

              this.uploadedParts.push(uploadedPart)

              resolve(xhr.status)
              delete this.activeConnections[part.partNumber - 1]
            }
          }
        })

        // eslint-disable-next-line unicorn/prefer-add-event-listener
        xhr.onerror = error => {
          reject(error)
          delete this.activeConnections[part.partNumber - 1]
        }

        xhr.addEventListener('abort', () => {
          reject(new Error('Upload canceled by user'))
          delete this.activeConnections[part.partNumber - 1]
        })

        xhr.send(file)
      }
    })
  }

  onProgress(onProgress: any) {
    this.onProgressFn = onProgress
    return this
  }

  onError(onError: any) {
    this.onErrorFn = onError
    return this
  }

  abort() {
    for (const id of Object.keys(this.activeConnections).map(Number)) {
      this.activeConnections[id].abort()
    }

    this.aborted = true
  }
}

export default Uploader
