import { Box, ButtonBase, Chip, Stack, Typography } from '@mui/material'
import { useAuthContext } from 'app/auth'
import { ITaskStatus } from 'app/codecs'
import { routes } from 'app/routes'
import { ReactComponent as ActionsIcon } from 'assets/icons/actions.svg'
import { InternalLink } from 'components/InternalLink'
import {
  bindHover,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

export const tagsColors = [
  '#ECEFFD',
  '#E1F5EE',
  '#FCEEE1',
  '#F5E1FC',
  '#ECF4FD',
  '#FCE1E1',
  '#FCF5E1',
  '#E1E4FC',
  '#EFFCE1',
  '#FCFCE1',
  '#ECF9FD',
  '#F5EFE1',
  '#E1FCF7',
] as const

type Props = {
  taskId: string
  status: ITaskStatus
  originalName: string
  tags: string[]
  initialSearch: string
  userId?: string
  isOwnFile?: boolean
}

export const TaskName = ({
  taskId,
  status,
  originalName,
  userId,
  isOwnFile,
  tags,
  initialSearch,
}: Props) => {
  const { accessToken } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const popupState = usePopupState({
    popupId: `tags-${taskId}`,
    variant: 'popover',
  })

  const filteredTags = useMemo(
    () => tags?.filter(tag => tag.length > 0),
    [tags],
  )

  const nameWithoutExtension = useMemo(
    () =>
      originalName.includes('.')
        ? originalName.split('.').slice(0, -1).join('.')
        : originalName,
    [originalName],
  )

  const accessForbidden = useMemo(
    () =>
      accessToken === null ||
      (accessToken.role === 'EDITOR' &&
        accessToken.userId !== userId &&
        !isOwnFile),
    [accessToken, isOwnFile, userId],
  )

  const ValidatedName = () => (
    <Typography
      variant="body1"
      lineHeight="normal"
      noWrap
      align="left"
      overflow="hidden"
      textOverflow="ellipsis"
      sx={{ cursor: accessForbidden ? 'not-allowed' : 'pointer' }}
      onClick={() => {
        if (accessForbidden) {
          enqueueSnackbar(t('error_notification.error_access_forbidden'), {
            variant: 'error',
          })
        }
      }}
    >
      {nameWithoutExtension.length > 30
        ? [...nameWithoutExtension.slice(0, 30), '...']
        : nameWithoutExtension || t('labels.no_title')}
    </Typography>
  )

  const Tags = ({ tags }: { tags: string[] }) => (
    <Stack direction="row" spacing={1}>
      {tags.slice(0, 3).map((tag, idx) => (
        <Chip
          key={tag + idx}
          label={tag}
          size="small"
          sx={{
            padding: '2px 8px',
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 500,
            backgroundColor: tagsColors[idx % tagsColors.length],
          }}
        />
      ))}
      {tags.length > 3 && (
        <>
          <span {...bindHover(popupState)}>
            <ButtonBase>
              <ActionsIcon />
            </ButtonBase>
          </span>

          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 16,
              horizontal: -16,
            }}
            anchorReference="anchorPosition"
          >
            <Box sx={{ px: 3.5, py: 4, minWidth: 350, minHeight: 150 }}>
              <Typography variant="h3" mb={1}>
                {t('labels.tags')}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                flexWrap="wrap"
              >
                {filteredTags.slice(3).map((tag, idx) => (
                  <Chip
                    key={tag + idx}
                    label={tag}
                    size="small"
                    sx={{
                      backgroundColor:
                        tagsColors[(idx + 3) % tagsColors.length],
                    }}
                  />
                ))}
              </Stack>
            </Box>
          </HoverPopover>
        </>
      )}
    </Stack>
  )

  if (accessToken === null) {
    return null
  }

  if (accessForbidden || (status !== 'DONE' && status !== 'SYNCHRONIZED')) {
    return (
      <Stack spacing={1} sx={{ maxWidth: '350px' }}>
        <ValidatedName />
        {filteredTags.length > 0 && <Tags tags={filteredTags} />}
      </Stack>
    )
  }

  return (
    <Stack sx={{ maxWidth: '350px' }}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <Box display="flex" flexShrink={1} flexWrap="wrap">
            <InternalLink
              to={{
                pathname: generatePath(routes.file, { fileId: taskId }),
                search:
                  initialSearch.length > 0
                    ? `initialSearch=${initialSearch}`
                    : undefined,
              }}
            >
              <ValidatedName />
            </InternalLink>
          </Box>
          <Typography
            variant="subtitle1"
            component="sup"
            sx={{
              textTransform: 'uppercase',
              opacity: 0.4,
              fontWeight: 700,
            }}
            noWrap
            align="left"
          >
            {originalName.includes('.') ? originalName.split('.').pop() : ''}
          </Typography>
        </Stack>
        {filteredTags.length > 0 && <Tags tags={filteredTags} />}
      </Stack>
    </Stack>
  )
}
