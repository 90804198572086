import { Grid, Typography } from '@mui/material'
import background from 'assets/icons/background-image.png'
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg'
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg'
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import ResponsiveAppBar from './ResponsiveAppBar'

const footer = [
  {
    label: 'Facebook',
    icon: <FacebookIcon />,
  },
  {
    label: 'Twitter',
    icon: <TwitterIcon />,
  },
  {
    label: 'LinkedIn',
    icon: <LinkedInIcon />,
  },
  {
    label: 'Youtube',
    icon: <YoutubeIcon />,
  },
]

type Props = {
  children: ReactNode
}

export const AppUnauthorizedLayout = ({ children }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <ResponsiveAppBar />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          minHeight: '100vh',
          backgroundImage: `linear-gradient(rgba(0, 48, 73, 0.6), rgba(0, 48, 73, 0.6)), url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          pl: theme => ({
            xs: theme.spacing(3),
            lg: theme.spacing(8),
          }),
          pr: theme => ({
            xs: theme.spacing(3),
            lg: theme.spacing(7.5),
          }),
        }}
      >
        {children}
      </Grid>

      <Grid
        container
        alignItems="center"
        sx={{ px: { lg: 8, xs: 2 }, py: { md: 5, xs: 3 } }}
        flexWrap={{ xs: 'wrap-reverse', md: 'wrap' }}
        spacing={3}
      >
        <Grid item xs={12} md={6}>
          <Typography textAlign={{ xs: 'center', md: 'left' }}>
            {t('unauthorized.copyright')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={{ md: 3, xs: 2 }}
            justifyContent={{ md: 'flex-end', xs: 'center' }}
          >
            {footer.map(item => (
              <Grid key={item.label} item>
                <Grid container alignItems="center">
                  {item.icon}
                  <Typography sx={{ ml: 1.5 }}>{item.label}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
