import { Box, Typography } from '@mui/material'

type Props = {
  firstName: string
  lastName: string
  role: string
}

export const UserDetails = (props: Props) => {
  return (
    <Box>
      <Typography variant="h3" noWrap align="left" lineHeight={1.4}>
        {props.firstName} {'  '} {props.lastName}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ textTransform: 'uppercase', opacity: 0.4 }}
        noWrap
        align="left"
      >
        {props.role}
      </Typography>
    </Box>
  )
}
