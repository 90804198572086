import { FormEvent, ReactChild } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'

type Props<T extends FieldValues> = {
  children: ReactChild
  form: UseFormReturn<T>
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
}

export const Form = <T extends FieldValues>(props: Props<T>) => {
  return (
    <FormProvider {...props.form}>
      <form noValidate autoComplete="off" onSubmit={props.onSubmit}>
        {props.children}
      </form>
    </FormProvider>
  )
}
