import { Box, lighten, Stack, Tooltip, Typography } from '@mui/material'
import { ReactComponent as Camera } from 'assets/icons/camera.svg'
import { ReactComponent as Mic } from 'assets/icons/mic.svg'
import { PhotoCamera } from 'assets/SVGComponents'
import { audioExtensions } from 'config/audio-extensions'
import { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const getBase64 = (file: File | undefined, cb: Function) => {
  let reader = new FileReader()
  if (file) {
    reader.readAsDataURL(file)
    reader.addEventListener('load', function () {
      cb(reader.result)
    })
  }
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  reader.onerror = function (error) {
    console.log('Error:', error)
  }
}

type Props = {
  fileName: string
  onThumbnailChange?: (file: File) => void
}

export const AfterUploadScreen = ({ fileName, onThumbnailChange }: Props) => {
  const [b64, setB64] = useState<string | null>(null)
  const thumbnailRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  const fileNameExt = fileName.split('.').pop()

  return (
    <Stack direction="row">
      <Tooltip
        placement="bottom"
        title={t(`tooltips.upload_${b64 ? 'another' : 'thumbnail'}`)}
      >
        <Box
          sx={{
            width: 101,
            height: 76,
            background: theme => lighten(theme.palette.secondary.main, 0.8),
            textAlign: 'center',
            lineHeight: '83px',
            borderRadius: '6px',
            position: 'relative',
            cursor: 'pointer',
            backgroundImage: `url(${b64})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
          onClick={() => {
            if (thumbnailRef) {
              thumbnailRef.current?.click()
            }
          }}
        >
          {!b64 &&
            (fileNameExt && audioExtensions.includes(fileNameExt) ? (
              <Mic width="32px" height="32px" />
            ) : (
              <Camera width="32px" height="18.67px" />
            ))}
          <input
            name="thumbnail"
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            ref={thumbnailRef}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0]
              if (file) {
                getBase64(file, (result: string) => {
                  setB64(result)
                })
                if (onThumbnailChange) {
                  onThumbnailChange(file)
                }
              }
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              width: '31px',
              height: '31px',
              backgroundColor: theme => theme.palette.secondary.main,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: -8,
              bottom: -8,
              paddingTop: '2px',
            }}
          >
            <PhotoCamera />
          </Box>
        </Box>
      </Tooltip>
      <Stack direction="column" marginLeft="20px" sx={{ flex: 1 }}>
        <Typography variant="h4">{t('upload_file.file_name')}</Typography>
        <Typography sx={{ wordBreak: 'break-all', fontWeight: '400' }}>
          {fileName}
        </Typography>
      </Stack>
    </Stack>
  )
}
