import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { TagCodecArray } from 'app/codecs/tag'
import { ReactComponent as Search } from 'assets/icons/find.svg'
import { Input, Pagination, Switch, TableBodyCell } from 'components'
import { Select } from 'components/Select'
import { array, Mixed, string, type, TypeOf } from 'io-ts'
import { RestMethod } from 'lib/request'
import { useJsonQuery } from 'lib/rest-query'
import { concatQueryParams } from 'lib/rest-query/common'
import { usePagination } from 'lib/tables/use-pagination'
import { useSorting } from 'lib/tables/use-sorting'
import debounce from 'lodash.debounce'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

export const HeaderCodec = type({ 'x-total-count': string })

type Head = {
  field?: string
  label: string
  width?: string
  align?: string
}

type Additions<C extends Mixed> = {
  data: Array<TypeOf<C>> | undefined
  refetch: () => void
}

type Param = {
  name: string
  value: string
}

type SuggestionsSortingColumn =
  | 'duration'
  | 'email'
  | 'createdAt'
  | 'firstName'
  | 'originalName'
  | 'lastName'
  | 'creditsNumber'
  | 'fileSize'
const suggestionSortingColumns = [
  'duration',
  'email',
  'createdAt',
  'firstName',
  'originalName',
  'lastName',
  'creditsNumber',
  'fileSize',
] as const

type Props<C extends Mixed> = {
  head: Array<Head>
  url: string
  codec: C
  searchFeat: boolean
  rowId: (row: TypeOf<C>) => string
  renderRowCell: (row: TypeOf<C>) => Array<ReactNode>
  params?: Array<Param>
  letsRefetch?: boolean
  plain?: boolean
  optionalButtons?: ReactNode
  tabs?: string[]
  value?: number
  showRemovedUsers?: boolean
  refetchInterval?: number
  requestMethod?: RestMethod
  tagsFilter?: boolean
  includeTranscriptSearch?: boolean
  additions?: (additions: Additions<C>) => ReactNode
  setTab?: (str: string) => void
  setValue?: (n: number) => void
  a11yProps?: (index: number) => {
    id: string
    'aria-controls': string
  }
  onSearchChange?: (search: string) => void
}

const MIN_SEARCH_LENGTH = 2

export const DataGrid = <C extends Mixed>({
  url,
  codec,
  params,
  letsRefetch,
  plain,
  optionalButtons,
  tabs,
  value,
  showRemovedUsers,
  head,
  searchFeat,
  refetchInterval,
  requestMethod,
  tagsFilter,
  includeTranscriptSearch,
  additions,
  setValue,
  setTab,
  a11yProps,
  rowId,
  renderRowCell,
  onSearchChange,
}: Props<C>) => {
  const {
    page,
    setPage,
    resetPage,
    rowsPerPage,
    setRowsPerPage,
    rowsPerPageOptions,
  } = usePagination({
    rowsPerPageStorageKey: 'data-grid-rows-per-page',
  })

  const { orderBy, direction, setOrderBy, setDirection } =
    useSorting<SuggestionsSortingColumn>({
      initialOrderBy: null,
      initialDirection: 'DESC',
      sortingColumns: suggestionSortingColumns,
    })

  const [search, setSearch] = useState('')
  const [tags, setTags] = useState<string[]>([])
  const [transcriptSearch, setTranscriptSearch] = useState<string>('true')
  const [removedUsers, setRemovedUsers] = useState<null | boolean>(null)

  const theme = useTheme()
  const { t } = useTranslation()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  const dataGridParams = useMemo(() => {
    const dataGridParams = new URLSearchParams()
    if (params) {
      for (const param of params) dataGridParams.set(param.name, param.value)
    }

    dataGridParams.set('skip', (page * rowsPerPage - rowsPerPage).toString())
    dataGridParams.set('limit', rowsPerPage.toString())

    if (
      includeTranscriptSearch &&
      transcriptSearch === 'true' &&
      search !== ''
    ) {
      dataGridParams.set('includeTranscriptionSearch', transcriptSearch)
    }

    if (search !== '' && search.length >= MIN_SEARCH_LENGTH) {
      dataGridParams.set('search', search)
      return dataGridParams
    }

    if (orderBy && direction) {
      dataGridParams.set('sort', orderBy)
      dataGridParams.set('direction', direction)
    }

    if (removedUsers !== null) {
      dataGridParams.set('withRemoved', String(removedUsers))
      return dataGridParams
    }

    return dataGridParams
  }, [
    params,
    search,
    includeTranscriptSearch,
    transcriptSearch,
    page,
    rowsPerPage,
    orderBy,
    direction,
    removedUsers,
  ])

  const $gridData = useJsonQuery(
    requestMethod ?? 'GET',
    concatQueryParams(url, dataGridParams),
    array(codec),
    {
      rawBody:
        tagsFilter && tags.length > 0
          ? {
              tagNames: tags,
            }
          : undefined,
      headersCodec: HeaderCodec,
      options: {
        keepPreviousData: true,
        ...(search === '' && { refetchInterval }),
      },
    },
  )

  const $allTags = useJsonQuery(
    'GET',
    '/api/organizations/tags',
    TagCodecArray,
    {
      options: { enabled: tagsFilter },
    },
  )

  const { data, refetch, isLoading, fetchStatus } = $gridData

  useEffect(() => {
    refetch()
  }, [letsRefetch, refetch])

  const rows = useMemo(
    () =>
      data?.body.map(row => ({
        id: rowId(row),
        cell: renderRowCell(row),
      })) || [],
    [data, renderRowCell, rowId],
  )

  const handleSearch = useMemo(() => {
    return debounce(setSearch, 400)
  }, [setSearch])

  return (
    <Box mt={2}>
      {tabs && a11yProps && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs
            value={value}
            onChange={(_e, n) => {
              tabs && resetPage()
              setTab && setTab(n === 1 ? 'true' : 'false')
              setValue && setValue(value === 1 ? 0 : 1)
            }}
            textColor="inherit"
            sx={{
              '.MuiTabs-indicator': { backgroundColor: '#4AC398' },
              '.MuiTab-root': {
                fontWeight: 400,
                minWidth: 154,
                textTransform: 'capitalize',
              },
              '.Mui-selected': {
                fontWeight: 500,
              },
            }}
          >
            {tabs.map((tab, idx) => {
              return (
                <Tab
                  key={tab + idx}
                  label={tab}
                  disableRipple
                  {...a11yProps(idx)}
                  value={idx}
                />
              )
            })}
          </Tabs>
        </Box>
      )}
      {searchFeat && (
        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          rowGap={2}
          direction={mdDown ? 'column-reverse' : 'row'}
        >
          <Stack
            direction="row"
            spacing={3}
            alignItems="start"
            justifyContent="start"
          >
            <Stack
              minWidth={320}
              border={'1px #F1F1F1 solid'}
              boxShadow={'0px 4px 8px rgba(8, 15, 52, 0.06)'}
              borderRadius={2}
              direction="row"
            >
              {includeTranscriptSearch && (
                <>
                  <Box width={170}>
                    <Select
                      value={transcriptSearch}
                      onChange={value => {
                        setTranscriptSearch(value)
                        onSearchChange?.(value === 'true' ? search : '')
                      }}
                      options={[
                        {
                          label: 'Incl. transcript',
                          value: 'true',
                        },
                        {
                          label: 'Excl. transcript',
                          value: 'false',
                        },
                      ]}
                      sx={{
                        height: 40,
                        px: 1.5,
                        svg: {
                          top: 'unset',
                          right: 12,
                          marginLeft: 1.5,
                        },
                      }}
                      componentsProps={{
                        root: {
                          style: { border: 'none' },
                        },
                      }}
                      fullWidth
                    />
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                </>
              )}
              <Input
                placeholder={t('placeholders.start_typing_for_search')}
                onChange={e => {
                  handleSearch(e.target.value)
                  onSearchChange?.(
                    transcriptSearch === 'true' ? e.target.value : '',
                  )
                }}
                fullWidth
                startAdornment={<Search />}
                inputProps={{ style: { paddingLeft: 20 } }}
                sx={{
                  height: 40,
                  pr: 1,
                  svg: {
                    marginLeft: 2,
                  },
                }}
                componentsProps={{
                  root: {
                    style: { border: 'none' },
                  },
                }}
              />
            </Stack>
            {showRemovedUsers && (
              <Switch
                isChecked={removedUsers ?? false}
                onChange={() => setRemovedUsers(prevValue => !prevValue)}
                label={t('Inputs.show_removed_users')}
                labelPlacement="end"
              />
            )}
            {tagsFilter && (
              <Box width={300}>
                <Autocomplete
                  multiple
                  fullWidth
                  options={$allTags.data ?? []}
                  value={tags}
                  onChange={(_e, value) => setTags(value)}
                  sx={{ '.MuiInputBase-root': { paddingBottom: 0 } }}
                  limitTags={1}
                  renderTags={(options, getTagProps) =>
                    options.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={option + index}
                        label={option}
                        size="small"
                        sx={{
                          fontWeight: 400,
                        }}
                      />
                    ))
                  }
                  renderInput={params => {
                    const { InputLabelProps, InputProps, ...rest } = params
                    return (
                      <Box
                        minWidth={300}
                        border={'1px #F1F1F1 solid'}
                        boxShadow={'0px 4px 8px rgba(8, 15, 52, 0.06)'}
                        borderRadius={2}
                        px={2}
                      >
                        <Input
                          multiline
                          {...InputProps}
                          {...rest}
                          sx={{
                            minHeight: 40,
                          }}
                          componentsProps={{
                            root: {
                              style: { border: 'none', paddingTop: 0 },
                            },
                          }}
                          placeholder={
                            tags.length === 0
                              ? t('placeholders.all_tags')
                              : undefined
                          }
                        />
                      </Box>
                    )
                  }}
                />
              </Box>
            )}
          </Stack>
          <Box mb={mdDown ? 2 : 0} minWidth={320}>
            {optionalButtons}
          </Box>
        </Stack>
      )}
      {rows.length === 0 && !isLoading ? (
        <Typography sx={{ pt: 3 }} variant="h3">
          {t('tables.no_results')}
        </Typography>
      ) : (
        <Box
          sx={{
            overflow: 'auto',
            position: 'relative',
          }}
        >
          {fetchStatus !== 'idle' && search !== '' && (
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                }}
              >
                <CircularProgress color="secondary" />
              </Box>
            </Box>
          )}
          <Table
            sx={{
              whiteSpace: 'nowrap',
              borderCollapse: 'separate',
              borderSpacing: '0px 4px',
            }}
          >
            <TableHead>
              <TableRow sx={{ borderRadius: '8px', borderBottom: '0px' }}>
                {head.map(cell => (
                  <TableCell
                    width={cell.width}
                    key={uuidV4()}
                    colSpan={1}
                    align={plain || cell.align === 'left' ? 'left' : 'center'}
                    sx={{
                      borderBottom: 0,
                      paddingX: 1,
                    }}
                  >
                    {cell.field ? (
                      <TableSortLabel
                        sx={{
                          position: 'relative',
                          '& .MuiTableSortLabel-icon': {
                            position: 'absolute',
                            right: '-30px',
                          },
                        }}
                        active={orderBy === cell.field}
                        direction={direction === 'ASC' ? 'asc' : 'desc'}
                        onClick={() => {
                          setOrderBy(cell.field as SuggestionsSortingColumn)
                          if (orderBy === cell.field) {
                            if (direction === 'ASC') {
                              setDirection('DESC')
                            } else {
                              setDirection(null)
                              setOrderBy(null)
                            }
                          } else {
                            setDirection('ASC')
                          }
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          textTransform="capitalize"
                        >
                          {cell.label}
                        </Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        textTransform="capitalize"
                      >
                        {cell.label}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow
                  key={row.id}
                  sx={
                    plain
                      ? {}
                      : {
                          background: '#FFFFFF',
                          border: '3px solid #000000',
                          boxSizing: 'border-box',
                          boxShadow: '0px 4px 8px rgba(8, 15, 52, 0.06)',
                          borderRadius: '8px',
                          minHeight: '72px',
                          maxHeight: '92px',
                          padding: '8px',
                          'td:first-of-type': {
                            paddingLeft: 1,
                            paddingTop: 1,
                            paddingBottom: 1,
                            paddingRight: 1,
                          },

                          '&:hover': {
                            '.MuiTableCell-root': {
                              borderTop: '1px solid #F2994A',
                              borderBottom: '1px solid #F2994A',
                              '&:first-of-type': {
                                borderLeft: '1px solid #F2994A',
                                borderBottomLeftRadius: '8px',
                                borderTopLeftRadius: '8px',
                              },
                              '&:last-child': {
                                borderRight: '1px solid #F2994A',
                                borderBottomRightRadius: '8px',
                                borderTopRightRadius: '8px',
                              },
                            },
                          },
                        }
                  }
                >
                  {row.cell.map(cell => (
                    <TableBodyCell key={uuidV4()} plain={plain}>
                      {cell}
                    </TableBodyCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      <Stack
        width={1}
        justifyContent="flex-end"
        direction="row"
        mt={3}
        mb={3}
        spacing={2}
      >
        {data &&
          Number(data.headers['x-total-count']) > rowsPerPageOptions[0] && (
            <Pagination
              page={page}
              isFetching={fetchStatus !== 'idle' && search !== ''}
              rowsPerPage={rowsPerPage}
              total={Number(data.headers['x-total-count'])}
              onChange={setPage}
              onRowsPerPageChange={setRowsPerPage}
              rowsOptions={rowsPerPageOptions}
            />
          )}
      </Stack>
      {additions && data && additions({ data: data.body, refetch })}
    </Box>
  )
}
