import * as t from 'io-ts'

export const UploadAvatarCodec = t.type({
  fileId: t.string,
})

export const UploadThumbnailCodec = t.type({
  fileId: t.string,
  taskId: t.union([t.string, t.undefined]),
})
