import * as t from 'io-ts'

export const TagCodec = t.type({
  tagId: t.string,
  tagName: t.string,
})

export const TagCodecArray = t.array(t.string)

export type TagsCodecArray = t.TypeOf<typeof TagCodecArray>
