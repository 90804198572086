import { Box, Button, InputLabel, Stack } from '@mui/material'
import { endOfDay, startOfDay } from 'date-fns'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { DateRangePickerValue } from '../../index'
import { DateInput } from './components/DateInput'
import { PeriodOptionButton } from './components/PeriodOptionButton'
import { PeriodOption, periodOptions } from './utils/periodOptions'

type Props = {
  children: ReactNode
  value: DateRangePickerValue
  applyIsDisabled: boolean
  withSideBar: boolean
  onSelectPeriodOption: (periodOption: PeriodOption) => void
  onChangeValue: (value: DateRangePickerValue) => void
  onClickCancel: () => void
  onClickApply: () => void
}

export const CalendarInfo = ({
  value,
  children,
  applyIsDisabled,
  withSideBar,
  onSelectPeriodOption,
  onChangeValue,
  onClickCancel,
  onClickApply,
}: Props) => {
  const { t } = useTranslation()
  const selectedPeriodOption =
    periodOptions.find(
      option =>
        value.startDate &&
        value.endDate &&
        option.startDate.getTime() === value.startDate.getTime() &&
        option.endDate.getTime() === value.endDate.getTime(),
    ) || null

  return (
    <Stack direction="row" spacing={1}>
      {withSideBar && (
        <Box
          sx={{
            padding: '20px 0',
            backgroundColor: '#f9f9fa',
            minWidth: '127px',
          }}
        >
          {periodOptions.map(option => (
            <PeriodOptionButton
              key={option.label}
              value={option}
              isSelected={option === selectedPeriodOption}
              onSelect={onSelectPeriodOption}
            />
          ))}
        </Box>
      )}

      <Stack direction="column" justifyContent="space-between" spacing={2}>
        {children}
        <Stack
          direction="row"
          spacing={3}
          justifyContent="space-between"
          alignItems="flex-end"
          p={2}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box>
              <InputLabel
                htmlFor="inputFrom"
                sx={{ fontSize: 14, fontWeight: 500, color: '#8aa1b0' }}
              >
                {t('labels.from')}*
              </InputLabel>
              <DateInput
                id="inputFrom"
                value={value.startDate}
                onChange={date => {
                  onChangeValue({
                    ...value,
                    startDate: date ? startOfDay(date) : null,
                  })
                }}
              />
            </Box>
            <Box>
              <InputLabel
                htmlFor="inputTo"
                sx={{ fontSize: 14, fontWeight: 500, color: '#8aa1b0' }}
              >
                {t('labels.to')}*
              </InputLabel>
              <DateInput
                id="inputTo"
                value={value.endDate}
                onChange={date => {
                  onChangeValue({
                    ...value,
                    endDate: date ? endOfDay(date) : null,
                  })
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              variant="outlined"
              sx={{ textTransform: 'none', fontWeight: 700 }}
              onClick={onClickCancel}
              color="error"
              disableElevation
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: 'none', fontWeight: 700 }}
              onClick={onClickApply}
              disabled={applyIsDisabled}
              disableElevation
            >
              {t('buttons.apply')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
