import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'

import { Button } from '../Button'

type Props = {
  title: string
  confirmationMessage: string
  cancelLabel?: string
  confirmationLabel: string
  onConfirm: () => void
  onClose: () => void
  open: boolean
}

export const InputConfirm = (props: Props) => {
  return (
    <Dialog maxWidth="xs" open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <Typography variant="h2">
          <strong>{props.title}</strong>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2">{props.confirmationMessage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid justifyContent="space-between" container>
              <Grid item>
                <Button variant="text" onClick={props.onClose}>
                  {'Cancel'}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.onConfirm}
                >
                  {props.confirmationLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
