import { Grid, Typography } from '@mui/material'
import { routes } from 'app/routes'
import { KeyboardBackspace } from 'assets/SVGComponents'
import { Button } from 'components'
import { Form, FormInput, UnauthorizedFormContainer } from 'components/Form'
import { InternalLink } from 'components/InternalLink'
import { validation } from 'config/validation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormValues = {
  email: string
}

type Props = {
  onSubmit: (values: FormValues) => void
}

export const RecoverPasswordForm = ({ onSubmit }: Props) => {
  const form = useForm<FormValues>({
    defaultValues: { email: '' },
  })

  const { t } = useTranslation()

  const { control } = form

  return (
    <UnauthorizedFormContainer
      helperButton={
        <InternalLink to={routes.signin}>
          <Typography
            variant="body2"
            sx={{
              margin: '0px',
              display: 'flex',
              alignItems: 'center',
              color: '#87A1B2',
              height: 8,
            }}
          >
            <KeyboardBackspace style={{ marginRight: 7 }} />{' '}
            {t('buttons.back_to_sign_in')}
          </Typography>
        </InternalLink>
      }
      label="Forgot password"
      helperText={
        <Typography variant="body2" color="textSecondary">
          {t('common.reset_instructions')}
        </Typography>
      }
      sx={{ maxWidth: '400px' }}
    >
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="email"
              required
              fullWidth
              placeholder={t('labels.email')}
              pattern={{
                value: validation.emailRegex,
                message: t('validation.enter_valid_email'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={6} sm={4}>
                <Button
                  withShadow
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t('buttons.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </UnauthorizedFormContainer>
  )
}
