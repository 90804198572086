import { Tooltip, Typography } from '@mui/material'

type Props = {
  email?: string
}

export const EmailCell = ({ email }: Props) => {
  if (!email) {
    return <Typography align="left">-</Typography>
  }

  return (
    <Tooltip title={email}>
      <Typography
        textOverflow="ellipsis"
        overflow="hidden"
        variant="body2"
        sx={{ maxWidth: '250px' }}
        align="left"
      >
        {email}
      </Typography>
    </Tooltip>
  )
}
