import { Grid, Stack, Typography } from '@mui/material'
import { routes } from 'app/routes'
import { Button } from 'components'
import {
  Form,
  FormInput,
  FormPasswordInput,
  UnauthorizedFormContainer,
} from 'components/Form'
import { InternalLink } from 'components/InternalLink'
import { validation } from 'config/validation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { UnauthorizedLeftItem } from '../UnauthorizedLeftItem'

type FormValues = {
  email: string
  password: string
}

type Props = {
  onSubmit: (values: FormValues) => void
}

export const SigninForm = ({ onSubmit }: Props) => {
  const form = useForm<FormValues>({
    defaultValues: { email: '', password: '' },
  })
  const { t } = useTranslation()
  const { control } = form

  return (
    <UnauthorizedLeftItem>
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <UnauthorizedFormContainer
          label={t('unauthorized_pages.sign_in')}
          helperText={
            <Stack direction="row">
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
              >
                {t('unauthorized_pages.no_profile')}
              </Typography>
              <InternalLink to={routes.signup}>
                <Typography
                  variant="body2"
                  color="error.dark"
                  component="span"
                  ml={1}
                >
                  {t('unauthorized_pages.sign_up')}
                </Typography>
              </InternalLink>
            </Stack>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormInput
                control={control}
                name="email"
                required
                fullWidth
                placeholder={t('labels.email')}
                pattern={{
                  value: validation.emailRegex,
                  message: t('validation.enter_valid_email'),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormPasswordInput
                control={control}
                label={t('labels.password')}
                name="password"
                placeholder={t('labels.password')}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InternalLink to={routes.recoverPassword}>
                <Typography color="error.dark" align="right" variant="body2">
                  {t('unauthorized_pages.forgot_password')}
                </Typography>
              </InternalLink>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid>
                  <Button
                    withShadow
                    type="submit"
                    color="primary"
                    fullWidth
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {t('unauthorized_pages.sign_in')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </UnauthorizedFormContainer>
      </Form>
    </UnauthorizedLeftItem>
  )
}
