import { Avatar } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  src: string | undefined
  variant?: 'rounded' | 'square' | 'circular'
  sx?: Record<string, any>
}

export const StyledAvatar = ({ variant, sx, src, children }: Props) => {
  return (
    <Avatar
      sx={[{ backgroundColor: '#E9ECFE', color: '#8FA0FA' }, sx ?? {}]}
      src={src ?? undefined}
      variant={variant}
    >
      {children}
    </Avatar>
  )
}
