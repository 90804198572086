import { generatePath } from 'react-router-dom'

export type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS'

export type FatalError =
  | { type: 'network_error'; error: TypeError }
  | { type: 'failed_to_decode_json'; json: unknown; errors: string[] }
  | { type: 'failed_to_parse_json'; response: Response; error: Error }
  | { type: 'server_error'; status: number }
  | { type: 'failed_to_decode_headers'; headers: Headers; errors: string[] }

export type MutationError =
  | FatalError
  | { type: 'not_found'; message?: string; code?: string }
  | { type: 'client_error'; message?: string; code?: string }

export type ExtractRouteParams<Path extends string> = NonNullable<
  Parameters<typeof generatePath<Path>>[1]
>

export const throwError = (error: MutationError) => {
  throw error
}

export const concatQueryParams = (
  url: string,
  params?: URLSearchParams,
): string => {
  if (params === undefined) {
    return url
  }
  const paramsString = params.toString()
  return paramsString.length === 0 ? url : `${url}?${paramsString}`
}

export const valueOrDefault = (
  value: string | number | null | undefined,
  defaultValue?: string | number,
) => {
  if (value !== undefined && value !== null) {
    return value
  }

  if (defaultValue !== undefined) {
    return defaultValue
  }

  return '-'
}
