import {
  Box,
  Grid,
  Hidden,
  List,
  ListItemButton,
  MenuItem,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useAuthContext } from 'app/auth'
import { Roles } from 'app/codecs'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { ReactComponent as Logo } from 'assets/icons/main-logo.svg'
import { InternalLink } from 'components/InternalLink'
import { AuthUserRoles } from 'lib/use-auth/codecs'
import { FC, SVGProps } from 'react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import {
  AdminDrawerItems,
  AdminEditorMenuItems,
  EditorDrawerItems,
  IMenuItem,
  OwnerDrawerItems,
  OwnerMenuItems,
  SystemAdminDrawerItems,
  SystemAdminMenuItems,
} from './menuDrawerItems'

type Props = {
  userRole: Roles
  open: boolean
  onClose: () => void
  onOpen: () => void
}

const drawerWidth = 200

type MenuItems = {
  id: string
  title: string
  to: string
  Icon: FC<SVGProps<SVGSVGElement>>
}

const drawerItemsByRoles: Record<string, any> = {
  EDITOR: EditorDrawerItems,
  OWNER: OwnerDrawerItems,
  ADMIN: AdminDrawerItems,
  SYSTEM_ADMIN: SystemAdminDrawerItems,
}

const useStyles = makeStyles()({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: ' 0px 0px 0px 1px rgba(34, 34, 34, 0.06)',
  },
  icon: {
    fill: 'inherit',
    '& > path': {
      fill: 'inherit',
    },
  },
})

const DrawerListItem = ({
  pathname,
  id,
  title,
  to,
  Icon,
}: {
  pathname: string
  id: string
  title: string
  to: string
  Icon: FC<SVGProps<SVGSVGElement>>
}) => {
  const { palette } = useTheme()
  return (
    <ListItemButton
      disableGutters
      sx={{ height: '36px' }}
      selected={pathname === to}
    >
      <InternalLink
        sx={{
          width: '100%',
          paddingInline: 1,
          height: '36px',
          borderRadius: '0px',
          lineHeight: '36px',
          margin: 0,
          textTransform: 'capitalize',
          '&:focus': {
            backgroundColor: palette.background.default,
            color: '#000000',
          },
          '&:hover': {
            backgroundColor: palette.info.light,
          },
          svg: {
            fill: palette.primary.light,
            '& > path': {
              fill: palette.primary.light,
            },
          },
        }}
        to={to}
      >
        <Box color="inherit" display="flex" alignItems="center">
          <Icon style={{ width: '20px', height: '20px' }} />{' '}
          <Box ml={1.2}>{title}</Box>
        </Box>
      </InternalLink>
    </ListItemButton>
  )
}

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

export const ContentDrawer: FC<Props> = ({
  onClose,
  onOpen,
  open,
  userRole,
}) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const { signOut, accessToken } = useAuthContext()
  const { t } = useTranslation()

  const menuItemStyles = {
    width: '100%',
    paddingLeft: '8px',
    borderRadius: '0px',
    '&:focus': {
      backgroundColor: theme.palette.background.default,
      color: '#000000',
    },
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
  }
  const location = useLocation()
  const prevLocation = useRef(location.pathname)

  const suitableStyles = open
    ? {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : !open && !mdDown
    ? {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
        [theme.breakpoints.up('md')]: {
          width: 0,
        },
      }
    : {}

  useEffect(() => {
    if (mdDown) {
      return onClose()
    }

    onOpen()
  }, [mdDown, onClose, onOpen])

  useEffect(() => {
    if (prevLocation.current !== location.pathname && mdDown) {
      prevLocation.current = location.pathname
      onClose()
    }
  }, [location.pathname, onClose, mdDown])

  if (accessToken === null) {
    return null
  }

  const items: Record<AuthUserRoles, IMenuItem[]> = {
    SYSTEM_ADMIN: SystemAdminMenuItems,
    OWNER: OwnerMenuItems,
    ADMIN: AdminEditorMenuItems,
    EDITOR: AdminEditorMenuItems,
  }

  return (
    <SwipeableDrawer
      variant={'temporary'}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      ModalProps={{ keepMounted: true }}
      sx={{
        ...suitableStyles,
        '.MuiDrawer-paper': suitableStyles,
      }}
      className={classes.drawer}
    >
      <Grid container>
        <Grid item xs={12}>
          <Hidden mdDown>
            <Box width={1} minHeight="65px" />
          </Hidden>
          <Hidden lgUp>
            <Box
              width={1}
              minHeight="65px"
              display="flex"
              justifyContent="center"
            >
              <Logo />
            </Box>
          </Hidden>
        </Grid>
        <Grid item xs={12}>
          <List disablePadding>
            {drawerItemsByRoles[userRole].map((item: MenuItems) => (
              <DrawerListItem
                key={item.id}
                pathname={location.pathname}
                {...item}
              />
            ))}

            {items[accessToken.role].map(({ Icon, to, id, title }) => (
              <InternalLink to={to} sx={{ width: '100%' }} key={id}>
                <MenuItem
                  selected={to === location.pathname}
                  sx={{
                    ...menuItemStyles,
                  }}
                  key={id}
                  value={to}
                >
                  <Icon color="#37637E" />
                  <Box ml={1} textTransform={'capitalize'}>
                    {t(`pages.${title}`)}
                  </Box>
                </MenuItem>
              </InternalLink>
            ))}
            <MenuItem
              sx={{
                ...menuItemStyles,
              }}
              onClick={signOut}
            >
              <Box
                color="inherit"
                alignItems="center"
                display="flex"
                textTransform={'capitalize'}
              >
                <LogoutIcon style={{ width: 20, height: 20 }} />
                <Box ml={1}>{t('menu_items.log_out')}</Box>
              </Box>
            </MenuItem>
          </List>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  )
}
