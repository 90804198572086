import {
  Box,
  CircularProgress,
  Stack,
  TableContainer,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useVirtualizer } from '@tanstack/react-virtual'
import {
  FileSearchResults,
  TranscriptionChunkGroupsCodec,
  TranscriptionSingleChunksCodec,
} from 'app/codecs'
import { EditableTableCell, VideoPlayer } from 'components'
import { HeaderCodec } from 'components/DataGrid/DataGrid'
import { SpeakerChangeModal } from 'components/Form/SpeakerChangeModal'
import { formatTimeForFiles } from 'imgplay-domain/helpers'
import { UUID } from 'io-ts-types'
import { useInfiniteQuery } from 'lib/rest-query/rest-infinite-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import videojs, { VideoJsPlayer } from 'video.js'
import { NoEntriesPlaceholder } from 'views/FileHistory/components'

import { ChunksTableLoader, FineTune } from './components'
import { FineTuneChunk, TunedWords } from './components/FineTune'
import { renderWords } from './helpers'

type Props = {
  taskId: string
  fileSrc: string
  grouped: boolean
  duration: number
  searchText: string
  searchIndex: number | null
  searchResults: FileSearchResults | null
  language: {
    name: string
    code: string
  } | null
  translation: string | null
  subtitlesSrc?: string
  mimeType?: string
  editable?: boolean
  updatedAt?: string | null
  onEdit?: () => void
}

type SpeakerChange = {
  chunkIds: UUID[]
  speaker: string
  open: boolean
}

export const VideoEditor = (props: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [paused, setPaused] = useState<boolean>(false)
  const [time, setTime] = useState<null | number>(null)
  const [fineTune, setFineTune] = useState<FineTuneChunk | null>(null)
  const [tunedWords, setTunedWords] = useState<TunedWords | null>(null)
  const [pageParam, setPageParam] = useState<null | number>(null)
  const [prevTranslation, setPrevTranslation] = useState<null | string>(
    props.translation,
  )

  const requestUrl = `/api/tasks/${props.editable ? '' : 'shared/'}${
    props.taskId
  }/transcription/${props.grouped ? 'chunk-groups' : 'chunks'}`

  const $chunks = useInfiniteQuery(
    'GET',
    requestUrl,
    props.grouped
      ? TranscriptionChunkGroupsCodec
      : TranscriptionSingleChunksCodec,
    {
      headersCodec: HeaderCodec,
      params: {
        chunksLimit: '200',
        ...(time && { searchStartTime: String(time) }),
        ...(props.translation && { language: props.translation }),
      },
      ...(pageParam !== null && { initialPageParam: pageParam }),
      options: {
        enabled: !paused,
        keepPreviousData: false,
        getNextPageParam: page =>
          page.body.currentPage === page.body.lastPage
            ? undefined
            : page.body.currentPage,
        getPreviousPageParam: page =>
          page.body.currentPage === page.body.firstPage
            ? undefined
            : page.body.currentPage - 2,
      },
    },
  )

  const [speakerModal, setSpeakerModal] = useState<SpeakerChange>({
    chunkIds: [],
    speaker: '',
    open: false,
  })
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const videoRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [currentVideoTime, setCurrentVideoTime] = useState(0)

  const {
    data,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = $chunks

  const $fineTune = useMutation(
    'PATCH',
    '/api/tasks/:taskId/transcription/chunks/:chunkId/fine-tune-time',
  )

  const rows =
    data?.pages.flatMap(page => {
      if ('phraseGroups' in page.body) {
        return page.body.phraseGroups
      }
      return page.body.phrases.map(phrase => [phrase])
    }) ?? []

  const total = Number(data?.pages.at(-1)?.headers['x-total-count'] ?? 0)

  // Initialize the player
  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js')

      videoElement.classList.add(
        'vjs-default-skin',
        'vjs-big-play-centered',
        'vjs-show-big-play-button-on-pause',
      )
      videoRef.current?.appendChild(videoElement)

      const options: videojs.PlayerOptions = {
        sources: [{ src: props.fileSrc, type: props.mimeType ?? 'video/mp4' }],
        ...(props.subtitlesSrc &&
          props.language && {
            tracks: [
              {
                src: props.subtitlesSrc,
                srclang: props.language.code,
                label: props.language.name,
                mode: 'showing',
              },
            ],
          }),
      }

      const player = (playerRef.current = videojs(videoElement, {
        autoplay: false,
        controls: true,
        html5: {
          nativeTextTracks: false,
          featuresNativeTextTracks: true,
        },
        fluid: true,
        aspectRatio: '16:9',
        playbackRates: [0.5, 1, 1.5, 2],
        controlBar: {
          children: [
            'progressControl',
            'playToggle',
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'customControlSpacer',
            'subtitlesButton',
            'playbackRateMenuButton',
            'volumePanel',
            'fullscreenToggle',
          ],
          volumePanel: { inline: false },
        },
        plugins: {
          seekButtons: {
            forward: 10,
            back: 10,
          },
        },
        ...options,
      }))

      player.on('timeupdate', () => {
        setCurrentVideoTime(player.currentTime())
      })
    }
  }, [
    playerRef,
    props.fileSrc,
    props.language,
    props.mimeType,
    props.subtitlesSrc,
  ])

  // Add subtitles to the player
  useEffect(() => {
    if (playerRef.current && props.language) {
      const player = playerRef.current
      const tracks = player.remoteTextTracks()

      player.removeRemoteTextTrack(tracks[0] as any)

      player.addRemoteTextTrack(
        {
          src: props.subtitlesSrc,
          srclang: props.language.code,
          label: props.language.name,
          mode: 'showing',
        },
        false,
      )
    }
  }, [props.language, props.subtitlesSrc])

  // Bind play/pause to the space button
  useLayoutEffect(() => {
    const handleSpaceClick = (event: KeyboardEvent) => {
      if (event.key === ' ' && playerRef.current !== null) {
        event.preventDefault()
        const player = playerRef.current

        player.paused() ? player.play() : player.pause()
      }
    }

    document.addEventListener('keydown', handleSpaceClick)

    return () => {
      document.removeEventListener('keydown', handleSpaceClick)
    }
  }, [])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  // Refetch data on transcription reset
  useEffect(() => {
    if (props.updatedAt === null) {
      refetch()
    }
  }, [props.updatedAt, refetch])

  //Initialize rows virtualizer
  const count = rows.length + Number(hasNextPage)

  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 56,
  })

  // Scroll to offset to avoid instant previous page fetching
  useEffect(() => {
    const element = document.querySelector('.MuiTableContainer-root')

    if (
      element !== null &&
      element.scrollTop < 50 &&
      hasPreviousPage &&
      !isFetchingPreviousPage
    ) {
      element.scrollTop = 55
    }
  })

  const firstPage =
    data?.pages && data.pages.length > 0
      ? data?.pages[0].body?.currentPage - 1
      : 0

  const lastPage =
    data?.pages && data.pages.length > 0
      ? data.pages[data.pages.length - 1].body?.currentPage - 1
      : 0

  const fetchedPages = useMemo(
    () => data?.pages.flatMap(page => page.body.currentPage) ?? [],
    [data?.pages],
  )

  // Handle search
  const currentSearch =
    props.searchResults === null || props.searchIndex === null
      ? null
      : props.searchResults?.[props.searchIndex] ?? null

  useEffect(() => {
    if (currentSearch && !isFetching) {
      if (time) {
        setTime(null)
      }

      if (fetchedPages.includes(currentSearch.page - 1)) {
        fetchNextPage()
      } else if (fetchedPages.includes(currentSearch.page + 1)) {
        fetchPreviousPage()
      } else {
        setPageParam(currentSearch.page - 1)
      }
    }
  }, [
    currentSearch,
    fetchNextPage,
    fetchPreviousPage,
    fetchedPages,
    isFetching,
    time,
  ])

  // Navigate on time click
  useEffect(() => {
    const player = playerRef.current

    if (player !== null) {
      player.controlBar
        .getChild('progressControl')
        ?.getChild('seekBar')
        ?.on('mouseup', () => {
          if (total > 1 && !currentSearch) {
            setPageParam(null)
            setTime(player.currentTime())
            setCurrentVideoTime(player.currentTime())
          }
        })
    }
  }, [currentSearch, queryClient, requestUrl, total])

  // Find row index for search
  const searchRowIndex = rows.findIndex(row =>
    row.some(phrase => phrase.alternativeId === currentSearch?.chunkId),
  )

  // Scroll to searched word
  useEffect(() => {
    if (searchRowIndex !== -1 && !isFetching) {
      rowVirtualizer.scrollToIndex(searchRowIndex)
    }
  }, [isFetching, rowVirtualizer, searchRowIndex])

  useEffect(() => {
    if (props.translation !== prevTranslation) {
      const player = playerRef.current

      setTime(null)
      setPageParam(null)
      setFineTune(null)
      setTunedWords(null)
      setSpeakerModal({
        chunkIds: [],
        speaker: '',
        open: false,
      })

      if (player !== null) {
        setTime(player.currentTime())
        setCurrentVideoTime(player.currentTime())
      }

      setPrevTranslation(props.translation)
    }
  }, [prevTranslation, props.translation, total])

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <Stack spacing={3} direction={{ xs: 'column-reverse', md: 'row' }}>
        <Box
          sx={{
            overflow: { xs: undefined, lg: 'auto' },
            resize: { xs: undefined, lg: 'horizontal' },
            minWidth: { xs: undefined, md: 300 },
            width: { xs: '100%', md: '50%', lg: '60%', xl: '70%' },
          }}
        >
          {(isFetching && !isFetchingNextPage && !isFetchingPreviousPage) ||
          (currentSearch &&
            !fetchedPages.includes(currentSearch.page) &&
            (isFetchingNextPage || isFetchingPreviousPage)) ||
          (props.searchText.length > 0 && props.searchResults === null) ||
          fetchedPages.length === 0 ? (
            <ChunksTableLoader shared={!props.editable} />
          ) : props.searchText.length > 0 &&
            props.searchResults?.length === 0 ? (
            <NoEntriesPlaceholder text={t('tables.no_results')} />
          ) : (
            <TableContainer
              ref={containerRef}
              sx={{
                maxHeight: props.editable
                  ? `calc(100vh - ${fineTune ? 460 : 360}px)`
                  : 'calc(100vh - 350px)',
                width: '100%',
              }}
              onScroll={event => {
                const element = event.target as HTMLElement
                const offset = 55

                if (
                  hasPreviousPage &&
                  !isFetchingPreviousPage &&
                  element.scrollTop < 50
                ) {
                  if (time) {
                    setPaused(true)
                    setTime(null)
                    setPageParam(firstPage - 1)
                    queryClient.clear()
                    setTimeout(() => setPaused(false), 100)
                  } else {
                    fetchPreviousPage()
                  }
                }
                if (
                  hasNextPage &&
                  !isFetchingNextPage &&
                  Math.abs(
                    element.scrollHeight -
                      element.clientHeight -
                      element.scrollTop -
                      offset,
                  ) < 100
                ) {
                  if (time) {
                    setPaused(true)
                    setTime(null)
                    setPageParam(lastPage + 1)
                    queryClient.clear()
                    setTimeout(() => setPaused(false), 100)
                    element.scrollTop = 55
                  } else {
                    fetchNextPage()
                  }
                }
              }}
              onKeyDown={event => {
                if (event.key === ' ') {
                  event.preventDefault()
                }
              }}
            >
              <div
                style={{
                  height: rowVirtualizer.getTotalSize(),
                  width: '100%',
                  position: 'relative',
                }}
              >
                {rowVirtualizer.getVirtualItems().map(virtualRow => {
                  const isLoader =
                    (hasPreviousPage && virtualRow.index === 0) ||
                    (hasNextPage && virtualRow.index > rows.length - 1)
                  const row = rows[virtualRow.index]
                  return (
                    <Stack
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={rowVirtualizer.measureElement}
                      direction="row"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                    >
                      {isLoader ? (
                        <>
                          <Box
                            sx={{
                              p: 1,
                              wordBreak: 'break-word',
                              position: 'relative',
                              width: 100,
                            }}
                          />
                          <Box
                            display="flex"
                            flex={1}
                            p={2}
                            alignItems="center"
                            justifyContent="center"
                            height={55}
                          >
                            {(isFetchingPreviousPage || isFetchingNextPage) && (
                              <CircularProgress size={32} color="inherit" />
                            )}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box
                            sx={{
                              p: 1,
                              wordBreak: 'break-word',
                              position: 'relative',
                              width: 100,
                            }}
                          >
                            <Typography
                              variant="inherit"
                              color="#87a1b2"
                              sx={{
                                cursor: 'pointer',
                              }}
                              onDoubleClick={() => {
                                playerRef.current?.currentTime(row[0].startTime)
                                if (playerRef.current?.paused()) {
                                  playerRef.current.play()
                                }
                              }}
                              fontSize={12}
                            >
                              {formatTimeForFiles(row[0].startTime)}
                            </Typography>
                            <Typography
                              variant="inherit"
                              fontSize={14}
                              lineHeight="22px"
                              fontWeight={700}
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                setSpeakerModal({
                                  open: true,
                                  chunkIds: row.map(
                                    chunk => chunk.alternativeId,
                                  ),
                                  speaker: row[0].speaker ?? '',
                                })
                              }
                            >
                              {row[0].speaker}
                            </Typography>
                          </Box>
                          {props.editable ? (
                            <EditableTableCell
                              key={row.map(item => item.text).join(' ')}
                              taskId={props.taskId}
                              chunkIds={row.map(item => item.alternativeId)}
                              text={row.map(item => item.text).join('\u202F')}
                              grouped={props.grouped}
                              fineTuneMode={
                                row[0].alternativeId ===
                                fineTune?.chunk.alternativeId
                              }
                              allowEdit={
                                row[0].alternativeId ===
                                  fineTune?.chunk.alternativeId || !fineTune
                              }
                              disableButton={$fineTune.isLoading}
                              translation={props.translation}
                              onTranscriptionUpdate={async () => {
                                refetch()
                                props.onEdit?.()
                              }}
                              onFineTuneOpen={async () => {
                                setFineTune({
                                  prevEndTime: Array.isArray(
                                    rows[virtualRow.index - 1],
                                  )
                                    ? rows[virtualRow.index - 1][0].endTime
                                    : 0,
                                  nextStartTime: Array.isArray(
                                    rows[virtualRow.index + 1],
                                  )
                                    ? rows[virtualRow.index + 1][0].startTime
                                    : props.duration,
                                  chunk: row[0],
                                })
                              }}
                              onFineTuneClose={async () => {
                                setFineTune(null)
                              }}
                              onFineTuneUpdate={async () => {
                                if (tunedWords !== null && fineTune !== null) {
                                  $fineTune.mutate(
                                    {
                                      params: {
                                        taskId: props.taskId,
                                        chunkId: fineTune?.chunk.alternativeId,
                                      },
                                      body: tunedWords,
                                      search: props.translation
                                        ? new URLSearchParams({
                                            language: props.translation,
                                          })
                                        : undefined,
                                    },
                                    {
                                      onSuccess: async () => {
                                        setFineTune(null)
                                        setTunedWords(null)
                                        enqueueSnackbar(
                                          t(
                                            'success_notification.phrase_was_tuned',
                                          ),
                                          { variant: 'success' },
                                        )
                                        props.onEdit?.()
                                        await refetch()
                                      },
                                      onError: error => {
                                        if (error.type === 'client_error') {
                                          enqueueSnackbar(
                                            t(
                                              `error_notification.${error.code}`,
                                            ),
                                            { variant: 'error' },
                                          )
                                        }
                                      },
                                    },
                                  )
                                } else {
                                  setFineTune(null)
                                }
                              }}
                            >
                              {renderWords(
                                row,
                                playerRef,
                                props.searchText,
                                currentSearch?.chunkId,
                              )}
                            </EditableTableCell>
                          ) : (
                            <Box display="flex" flex={1} p={2}>
                              {renderWords(
                                row,
                                playerRef,
                                props.searchText,
                                currentSearch?.chunkId,
                              )}
                            </Box>
                          )}
                        </>
                      )}
                    </Stack>
                  )
                })}
              </div>
            </TableContainer>
          )}
        </Box>
        <Box flex={1} minWidth={{ md: 300 }} mb={4}>
          <VideoPlayer videoRef={videoRef} />
        </Box>
        {speakerModal.open && (
          <SpeakerChangeModal
            taskId={props.taskId}
            chunkIds={speakerModal.chunkIds}
            onDiscard={() => {
              setSpeakerModal({ open: false, speaker: '', chunkIds: [] })
              refetch()
            }}
            open={speakerModal.open}
            translation={props.translation}
            defaultValues={{
              speaker: speakerModal.speaker,
              updateCurrent: false,
            }}
          />
        )}
      </Stack>
      {fineTune !== null && (
        <FineTune
          key={fineTune.chunk.alternativeId}
          time={currentVideoTime}
          transcription={fineTune}
          onVideoTimeChange={time => {
            playerRef.current?.currentTime(time)
          }}
          onWordsTune={words => {
            setTunedWords(words)
          }}
        />
      )}
    </>
  )
}
