import i18n, { i18n as I18n } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

type Config = {
  /**
   * Logs info level to console output. Helps finding issues with loading not working.
   */
  debug: boolean
}

const DETECTION_OPTIONS = {
  order: ['cookie', 'navigator'],
  caches: ['cookie'],
}

export const initI18n = async ({ debug }: Config): Promise<I18n> => {
  await i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      load: 'languageOnly',
      supportedLngs: ['en'],
      fallbackLng: 'en',
      detection: DETECTION_OPTIONS,
      returnNull: false,
      debug,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?version=${
          process.env.REACT_APP_BUILD_TIMESTAMP || '0'
        }`,
      },
    })

  return i18n
}
