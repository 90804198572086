import { routes } from 'app/routes'
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg'
import { ReactComponent as Files } from 'assets/icons/files.svg'
import { ReactComponent as Dictionaries } from 'assets/icons/language-configuration.svg'
import { ReactComponent as Organizations } from 'assets/icons/organisations.svg'
import { ReactComponent as Profile } from 'assets/icons/profile.svg'
import { ReactComponent as Settings } from 'assets/icons/settings.svg'
import { ReactComponent as Users } from 'assets/icons/users.svg'
import { FunctionComponent, SVGProps } from 'react'
import { v4 as uuid4 } from 'uuid'

export type IMenuItem = {
  id: string
  title: string
  to: (typeof routes)[keyof typeof routes]
  Icon: FunctionComponent<SVGProps<any>>
  selected?: boolean
}

export const SystemAdminDrawerItems = [
  {
    id: uuid4(),
    title: 'dashboard',
    to: routes.dashboard,
    Icon: Dashboard,
  },
  {
    id: uuid4(),
    title: 'organizations',
    to: routes.organizations,
    Icon: Organizations,
  },
  {
    id: uuid4(),
    title: 'configuration',
    to: routes.configuration,
    Icon: Settings,
  },
]

export const EditorDrawerItems: IMenuItem[] = [
  {
    id: uuid4(),
    title: 'files',
    to: routes.myFiles,
    Icon: Files,
  },
]

export const OwnerDrawerItems = [
  {
    id: uuid4(),
    title: 'files',
    to: routes.myFiles,
    Icon: Files,
  },
  {
    id: uuid4(),
    title: 'users',
    to: routes.users,
    Icon: Users,
  },
  {
    id: uuid4(),
    title: 'integrations',
    to: routes.integrations,
    Icon: Users,
  },
]

export const AdminDrawerItems = [
  {
    id: uuid4(),
    title: 'files',
    to: routes.myFiles,
    Icon: Files,
  },
  {
    id: uuid4(),
    title: 'users',
    to: routes.users,
    Icon: Users,
  },
  {
    id: uuid4(),
    title: 'integrations',
    to: routes.integrations,
    Icon: Users,
  },
]

export const OwnerMenuItems: IMenuItem[] = [
  {
    id: uuid4(),
    title: 'my_profile',
    to: routes.profile,
    selected: false,
    Icon: Profile,
  },
  {
    id: uuid4(),
    title: 'organization',
    to: routes.organizationSettings,
    selected: false,
    Icon: Settings,
  },
  {
    id: uuid4(),
    title: 'dictionary',
    to: routes.customDictionary,
    Icon: Dictionaries,
  },
]

export const AdminEditorMenuItems: IMenuItem[] = [
  {
    id: uuid4(),
    title: 'my_profile',
    to: routes.profile,
    selected: false,
    Icon: Profile,
  },
  {
    id: uuid4(),
    title: 'dictionary',
    to: routes.customDictionary,
    Icon: Dictionaries,
  },
]

export const SystemAdminMenuItems: IMenuItem[] = [
  {
    id: uuid4(),
    title: 'my_profile',
    to: routes.profile,
    selected: false,
    Icon: Profile,
  },
]
