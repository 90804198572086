import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import constate from 'constate'
import { isRight } from 'fp-ts/lib/Either'
import jwtDecode from 'jwt-decode'
import { isClientError } from 'lib/request'
import { useSyncAcrossTabs } from 'lib/use-sync-across-tabs'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { RefreshTokenInput } from '../../app/auth-api'
import { TAccessToken } from './codecs'

export let globalAccessToken: string | null = null

export type SignInInput = {
  email: string
  password: string
}

type IssueTokenInput = {
  email: string
  password: string
  clientSecret: string
}

const queryKey = 'refresh-token'

type Input = {
  clientSecret: string
  issueToken: (input: IssueTokenInput) => Promise<{
    accessToken: string
  }>
  refreshToken: (input: RefreshTokenInput) => Promise<{
    accessToken: string
  }>
  signOut: () => Promise<Response>
}

export const createUseAuth = ({
  issueToken,
  refreshToken,
  signOut,
  clientSecret,
}: Input) => {
  const useAuth = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const $issueToken = useMutation(issueToken, {
      onSuccess: data => {
        queryClient.setQueryData([queryKey], data)
        $issueToken.reset()
      },
    })

    const [refetchInterval, setRefetchInterval] = useState<number | false>(
      false,
    )

    const $refreshToken = useQuery(
      [queryKey],
      async () => refreshToken({ clientSecret }),
      {
        onError: error => {
          if (
            isClientError(error) &&
            error.code === 'error_incorrect_credentials'
          ) {
            setRefetchInterval(false)
          }
        },
        onSuccess: data => {
          const decoded = TAccessToken.decode(jwtDecode(data.accessToken))
          if (isRight(decoded)) {
            const expirationTime = decoded.right.exp * 1000 - Date.now()
            setRefetchInterval(expirationTime / 2)
          }
        },
        refetchInterval,
        refetchIntervalInBackground: refetchInterval !== false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        retry: false,
      },
    )

    const $signOut = useMutation(signOut, {
      onSuccess: () => {
        void queryClient.invalidateQueries([queryKey])
      },
    })

    const token = useMemo(() => {
      if ($refreshToken.data === undefined || $refreshToken.isError) {
        return null
      }
      const { accessToken } = $refreshToken.data
      const tokenData = TAccessToken.decode(jwtDecode(accessToken))
      if (isRight(tokenData)) {
        return { accessToken, ...tokenData.right }
      }
      return null
    }, [$refreshToken.data, $refreshToken.isError])

    globalAccessToken = token?.accessToken ?? null

    const { isLoading } = $refreshToken

    const mutateIssueToken = $issueToken.mutate
    const signIn = useCallback(
      (input: SignInInput, onError?: (error: any) => void) => {
        mutateIssueToken(
          {
            clientSecret,
            email: input.email,
            password: input.password,
          },
          {
            onSuccess: () => navigate('/'),
            onError: (error: any) => onError && onError(error),
          },
        )
      },
      [mutateIssueToken, navigate],
    )

    const signOutFunction = useSyncAcrossTabs('logout', $signOut.mutate)

    return {
      $issueToken,
      accessToken: token,
      isLoading,
      signIn,
      signOut: signOutFunction,
    }
  }

  const [AuthProvider, useAuthContext] = constate(useAuth)

  return { AuthProvider, useAuthContext }
}
