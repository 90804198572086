import { Link, LinkProps } from '@mui/material'
import { allPageRoutes } from 'app/pages'
import { createLinkPreload } from 'lib/lazy-routing'
import { forwardRef, ReactNode } from 'react'
import { LinkProps as RouterLinkProps } from 'react-router-dom'

export const LinkPrefetch = createLinkPreload(allPageRoutes)

type Props = LinkProps & {
  color?: 'primary' | 'secondary'
  to: RouterLinkProps['to']
  children: ReactNode
}

export const InternalLink = forwardRef<HTMLAnchorElement, Props>(
  ({ to, color, ...rest }: Props, ref) => {
    return (
      <Link
        sx={{ display: 'flex', flexDirection: 'row' }}
        color="textPrimary"
        ref={ref}
        component={LinkPrefetch}
        to={to}
        underline="none"
        {...rest}
      />
    )
  },
)
