import { useAuthContext } from 'app/auth'
import {
  pageRoutesAdmin,
  pageRoutesEditor,
  pageRoutesOwner,
  pageRoutesSystemAdmin,
  pageRoutesUnauth,
} from 'app/pages'
import { routes } from 'app/routes'
import {
  AppAuthorizedLayout,
  AppUnauthorizedLayout,
} from 'components/AppLayout'
import { Loader } from 'components/Loader'
import { NotFound } from 'components/NotFound'
import { RuntimeErrorDialog } from 'components/RuntimeErrorDialog'
import { createRoutes } from 'lib/lazy-routing'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate, Route, Routes } from 'react-router-dom'

const pageRoutes = {
  EDITOR: pageRoutesEditor,
  OWNER: pageRoutesOwner,
  ADMIN: pageRoutesAdmin,
  SYSTEM_ADMIN: pageRoutesSystemAdmin,
}

const PageIndex = () => {
  const { accessToken, isLoading } = useAuthContext()

  if (isLoading) {
    return <Loader />
  }

  if (accessToken === null) {
    return (
      <AppUnauthorizedLayout>
        <Suspense fallback={<Loader />}>
          <Routes>
            {createRoutes(pageRoutesUnauth)}
            <Route path="*" element={<Navigate replace to={routes.signin} />} />
          </Routes>
        </Suspense>
      </AppUnauthorizedLayout>
    )
  }

  const { role } = accessToken

  const initialPage =
    role === 'SYSTEM_ADMIN' ? routes.dashboard : routes.myFiles

  return (
    <AppAuthorizedLayout>
      <ErrorBoundary FallbackComponent={RuntimeErrorDialog}>
        <Suspense fallback={<Loader />}>
          <Routes>
            {createRoutes(pageRoutes[role])}
            <Route
              path={routes.signin}
              element={<Navigate replace to={initialPage} />}
            />
            <Route
              path={routes.signup}
              element={<Navigate replace to={initialPage} />}
            />
            <Route index element={<Navigate replace to={initialPage} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </AppAuthorizedLayout>
  )
}

export default PageIndex
