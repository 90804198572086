import {
  Divider,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
  Typography,
} from '@mui/material'
import { forwardRef, ReactNode } from 'react'

import { ReactComponent as ArrowDownIcon } from '../../assets/icons/drop-down.svg'
import { InputBase } from '../Input/Input'

type Option =
  | {
      label: string
      value: string
      type?: 'option'
    }
  | { type: 'divider' }
  | {
      type: 'header'
      label: string
    }

type Props = {
  options: Array<Option>
  value?: string
  placeholder?: string
  disabled?: boolean
  fullWidth?: boolean
  label?: ReactNode
  inputProps?: SelectProps['inputProps']
  componentsProps?: SelectProps['componentsProps']
  sx?: SelectProps['sx']
  onBlur?: () => void
  onChange: (value: string) => void
}

export const Select = forwardRef((props: Props, ref) => {
  const {
    onBlur = () => {},
    fullWidth,
    value,
    disabled,
    placeholder,
    options,
    label,
    inputProps,
    componentsProps,
    sx,
    onChange,
  } = props

  return (
    <MuiSelect
      ref={ref}
      sx={
        sx ?? {
          borderBottomColor: '#CED7DE',
          maxHeight: '458px',
          height: '30px',
          mb: 1.75,
        }
      }
      fullWidth={fullWidth}
      value={value}
      onBlur={onBlur}
      label={label}
      input={<InputBase disabled={disabled} />}
      inputProps={inputProps}
      componentsProps={componentsProps}
      disabled={disabled}
      placeholder={placeholder}
      onChange={option => onChange(option.target.value as string)}
      displayEmpty={true}
      IconComponent={ArrowDownIcon}
      renderValue={
        value === ''
          ? () => (
              <Typography sx={{ lineHeight: '1.2' }} color="textSecondary">
                {placeholder}
              </Typography>
            )
          : undefined
      }
      MenuProps={{
        MenuListProps: {
          disablePadding: true,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {options.map((option, idx) =>
        option.type === 'divider' ? (
          <Divider key={option.type + idx} sx={{ my: 1 }} />
        ) : option.type === 'header' ? (
          <ListSubheader key={option.label}>{option.label}</ListSubheader>
        ) : (
          <MenuItem
            sx={{
              color: theme => theme.palette.text.primary,
              borderRadius: 0,
              '&:hover': {
                backgroundColor: 'info.light',
              },
            }}
            key={option.label}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ),
      )}
    </MuiSelect>
  )
})
