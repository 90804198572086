import { ButtonBase, Menu, MenuItem, Stack, Tooltip } from '@mui/material'
import {
  IIntegrationType,
  IntegrationCodec,
  IntegrationListCodec,
} from 'app/codecs'
import { ReactComponent as SynchronizeIcon } from 'assets/icons/synchronize-subtitles.svg'
import { DropDownArrow } from 'assets/SVGComponents'
import { integrationTypeMapping } from 'config/integration-options'
import { array } from 'io-ts'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const SynchronizeMenu = ({
  taskId,
  integration,
  player,
  translation,
  integrationName,
  onReset,
}: {
  taskId: string
  integration: boolean
  player: boolean
  translation: string | null
  integrationName: string
  onReset: () => void
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const popupState = usePopupState({
    popupId: 'syncSubtitles',
    variant: 'popover',
  })

  const search = useMemo(() => {
    const params = new URLSearchParams({})

    if (translation) {
      params.set('language', translation)
    }

    return params
  }, [translation])

  const $integrationsList = useJsonQuery(
    'GET',
    '/api/integrations',
    array(IntegrationListCodec),
  )

  const $syncIntegration = useMutation(
    'PATCH',
    '/api/tasks/:taskId/sync/subtitles',
  )
  const $syncPlayer = useMutation(
    'PATCH',
    '/api/tasks/:taskId/sync/player-subtitles',
  )

  const integrationId = useMemo(() => {
    if (!$integrationsList.data || $integrationsList.data.length === 0) {
      return null
    }

    return (
      $integrationsList.data.find(provider => provider.type === integrationName)
        ?.integrationId ?? null
    )
  }, [$integrationsList.data, integrationName])

  const $integration = useJsonQuery(
    'GET',
    `/api/integrations/${integrationId}`,
    IntegrationCodec,
    {
      options: {
        enabled: integrationId !== null,
      },
    },
  )

  if (
    (!player && !integration) ||
    !$integrationsList.data ||
    $integrationsList.data.length === 0 ||
    !$integration.data
  ) {
    return null
  }

  return (
    <>
      <Tooltip title={t('common.synchronize')}>
        <span>
          <ButtonBase
            {...bindTrigger(popupState)}
            sx={{ padding: '10px', borderRadius: '43px' }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <SynchronizeIcon color="#c1121f" />
              <DropDownArrow
                stroke="#c1121f"
                style={{
                  transform: popupState.isOpen
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                }}
              />
            </Stack>
          </ButtonBase>
        </span>
      </Tooltip>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ mt: 1, px: 2 }}
      >
        {integrationId && (
          <Tooltip
            title={
              $integration.data.subtitle
                ? ''
                : t('integrations.activate_to_sync')
            }
          >
            <span>
              <MenuItem
                onClick={() => {
                  $syncIntegration.mutate(
                    {
                      params: { taskId },
                      search,
                    },
                    {
                      onSuccess: () => {
                        popupState.close()
                        onReset()
                      },
                      onError: () => {
                        enqueueSnackbar(
                          t('error_notification.error_unable_to_sync'),
                          { variant: 'error' },
                        )
                      },
                    },
                  )
                }}
                disabled={
                  !integration ||
                  $syncIntegration.isLoading ||
                  !$integration.data.subtitle
                }
              >
                {t('menu_items.sync_integration', {
                  integrationName:
                    integrationTypeMapping[integrationName as IIntegrationType],
                })}
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <MenuItem
          onClick={() => {
            $syncPlayer.mutate(
              {
                params: { taskId },
                search,
              },
              {
                onSuccess: () => {
                  popupState.close()
                  onReset()
                },
                onError: () => {
                  enqueueSnackbar(
                    t('error_notification.error_unable_to_sync'),
                    { variant: 'error' },
                  )
                },
              },
            )
          }}
          disabled={!player || $syncPlayer.isLoading}
        >
          {t('menu_items.sync_subtitles')}
        </MenuItem>
      </Menu>
    </>
  )
}
