import { Button } from 'components/Button'
import { useBoolean } from 'lib/use-boolean'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

type CopyToClipboardButtonProps = {
  text: string
  disabled: boolean
}

export const CopyToClipboardButton = ({
  text,
  disabled,
}: CopyToClipboardButtonProps) => {
  const isCopied = useBoolean(false)
  const { t } = useTranslation()
  return (
    <CopyToClipboard text={text} onCopy={isCopied.setTrue}>
      <Button
        noSpacing
        variant="outlined"
        disabled={disabled}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          borderRadius: '8px',
          textTransform: 'capitalize ',
          minWidth: '95px',
          maxHeight: '40px',
          minHeight: '40px',

          backgroundColor: 'white',
        }}
      >
        {isCopied.isTrue ? t('buttons.copied') : t('buttons.copy')}
      </Button>
    </CopyToClipboard>
  )
}
