import {
  Box,
  ButtonBase,
  MenuItem,
  Select as MuiSelect,
  Stack,
  Typography,
} from '@mui/material'
import { ITaskLanguage } from 'app/codecs'
import flags from 'assets/languages'
import flags2x from 'assets/languages/languages@2x'
import { flagCodes } from 'config/flag-name-code'
import { localizeLanguageName } from 'imgplay-domain/format'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BackIcon } from '../../assets/icons/back.svg'
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/drop-down.svg'
import { InputBase } from '../Input/Input'

type Props = {
  sourceLanguage: ITaskLanguage
  translations: ITaskLanguage[]
  onTranslationChange: (value: string) => void
}

const imgStyles = {
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover',
} as const

export const TranslationSelect = forwardRef(
  ({ sourceLanguage, translations, onTranslationChange }: Props, ref) => {
    const { i18n, t } = useTranslation()
    const [value, setValue] = useState<string>(sourceLanguage.code)

    const options = [sourceLanguage, ...translations]

    const findLanguageName = (language: string) =>
      options.find(option => option.code === language)?.name ?? ''

    return (
      <Stack
        display="inline-flex"
        direction="row"
        alignSelf="flex-start"
        alignItems="center"
        spacing={2}
        sx={{
          py: 1.5,
          px: 2,
          mb: 1,
          borderRadius: '4px',
          backgroundColor: '#F3F7F9',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography variant="body1" fontWeight={700} lineHeight="normal">
          {t('integrations.language')}:
        </Typography>

        <Box height={22}>
          <MuiSelect
            ref={ref}
            sx={{
              minHeight: '22px',
              maxHeight: '22px',
              height: '22px',
              lineHeight: 'normal',
              borderBottom: 'none !important',
              '& .MuiSelect-select': {
                paddingBottom: '0 !important',
              },
              '& .MuiSelect-icon': {
                top: 'unset',
              },
            }}
            fullWidth={false}
            value={value}
            input={
              <InputBase
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    py: 0,
                  },
                }}
              />
            }
            onChange={event => {
              setValue(event.target.value)
              onTranslationChange(
                event.target.value === sourceLanguage.code
                  ? ''
                  : event.target.value,
              )
            }}
            displayEmpty={true}
            IconComponent={ArrowDownIcon}
            renderValue={option => (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Box
                  width="21.517px"
                  height="18.207px"
                  overflow="hidden"
                  borderRadius="3.034px"
                  border="0.759px solid #CED7DE"
                  boxShadow={'0px 10.621px 31.862px 0px rgba(8, 15, 52, 0.06);'}
                >
                  <img
                    style={imgStyles}
                    loading="lazy"
                    // @ts-ignore
                    src={flags[flagCodes[findLanguageName(option)]]}
                    srcSet={`${
                      // @ts-ignore
                      flags2x[flagCodes[findLanguageName(option)]]
                    } 2x`}
                    alt={option}
                  />
                </Box>
                <Typography
                  variant="body1"
                  lineHeight="normal"
                  fontWeight={700}
                >
                  {localizeLanguageName(i18n.language, option)}
                </Typography>
              </Stack>
            )}
            MenuProps={{
              MenuListProps: {
                disablePadding: true,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: -12,
                horizontal: 'left',
              },
              sx: {
                '& .MuiPaper-root': {
                  borderRadius: '8px',
                  border: '1px solid var(--Lines-Athens-Gray, #E6EAEE)',
                  boxShadow: '0px 4px 8px 0px rgba(8, 15, 52, 0.06)',
                },
                '& .MuiList-root': {
                  py: 0.5,
                },
              },
            }}
          >
            {options.map((option, idx) => (
              <MenuItem
                sx={{
                  color: theme => theme.palette.text.primary,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: 'info.light',
                  },
                }}
                divider={idx < options.length - 1}
                key={option.code}
                value={option.code}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Box
                    width="21.517px"
                    height="18.207px"
                    overflow="hidden"
                    borderRadius="3.034px"
                    border="0.759px solid #CED7DE"
                    boxShadow={
                      '0px 10.621px 31.862px 0px rgba(8, 15, 52, 0.06);'
                    }
                  >
                    <img
                      style={imgStyles}
                      loading="lazy"
                      // @ts-ignore
                      src={flags[flagCodes[option.name]]}
                      // @ts-ignore
                      srcSet={`${flags2x[flagCodes[option.name]]} 2x`}
                      alt={option.code}
                    />
                  </Box>
                  <Typography variant="body2">
                    {localizeLanguageName(i18n.language, option.code)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </MuiSelect>
        </Box>

        {value !== sourceLanguage.code && (
          <ButtonBase
            onClick={() => {
              setValue(sourceLanguage.code)
              onTranslationChange('')
            }}
            sx={{
              borderBottom: '1px solid #003049',
            }}
          >
            <Typography variant="body2" fontWeight={700}>
              <BackIcon /> {t('labels.back_to_original_file')}
            </Typography>
          </ButtonBase>
        )}
      </Stack>
    )
  },
)
