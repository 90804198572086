import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery as useReactInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { useAuthContext } from 'app/auth'
import { isLeft } from 'fp-ts/Either'
import { Mixed, TypeOf } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'
import { useEffect, useMemo, useRef, useState } from 'react'

import {
  concatQueryParams,
  MutationError,
  RestMethod,
  throwError,
} from './common'

type Result<
  C extends Mixed,
  H extends Mixed | undefined = undefined,
> = H extends Mixed
  ? {
      body: TypeOf<C>
      headers: TypeOf<H>
    }
  : TypeOf<C>

export const useInfiniteQuery = <
  C extends Mixed,
  H extends Mixed | undefined = undefined,
>(
  method: RestMethod,
  url: string,
  codec: C,
  {
    rawBody,
    headersCodec,
    options,
    params,
    skipName,
    initialPageParam,
  }: {
    rawBody?: {} | null
    headersCodec?: H | null
    params?: Record<string, string> | URLSearchParams
    options?: UseInfiniteQueryOptions<Result<C, H>, MutationError>
    skipName?: string
    initialPageParam?: any
  } = {},
) => {
  const body = rawBody ? JSON.stringify(rawBody) : undefined
  const requestParams =
    JSON.stringify(params) + (initialPageParam ? `(${initialPageParam})` : '')

  const { accessToken } = useAuthContext()
  const token = accessToken === null ? null : accessToken.accessToken

  const tokenReference = useRef(token)
  useEffect(() => {
    tokenReference.current = token
  }, [token])

  const prevData = useRef<InfiniteData<Result<C, H>> | null>(null)
  const [page, setPage] = useState<undefined | number>()

  const queryKey = ['INF', method, url, body ?? requestParams]

  const queryParams = useMemo(() => {
    const queryParams = new URLSearchParams(params)

    return queryParams
  }, [params])

  const queryFn = async ({ pageParam }: QueryFunctionContext) => {
    const headers = new Headers({
      'Content-Type': 'application/json',
    })

    setPage(pageParam)

    const token = tokenReference.current

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    if (pageParam) {
      queryParams.set(skipName ?? 'pageSkip', pageParam)
    }

    try {
      const response = await window.fetch(concatQueryParams(url, queryParams), {
        method,
        body,
        headers,
      })

      if (response.ok) {
        try {
          const json: unknown = await response.json()
          const decodedJson = codec.decode(json)
          if (isLeft(decodedJson)) {
            const errors = formatValidationErrors(decodedJson.left)
            return throwError({ type: 'failed_to_decode_json', json, errors })
          }

          if (headersCodec) {
            const decodedHeaders = headersCodec.decode(
              Object.fromEntries(response.headers.entries()),
            )

            if (isLeft(decodedHeaders)) {
              const errors = formatValidationErrors(decodedHeaders.left)
              return throwError({
                type: 'failed_to_decode_headers',
                headers: response.headers,
                errors,
              })
            }

            return {
              body: decodedJson.right,
              headers: decodedHeaders.right,
            }
          }

          return decodedJson.right
        } catch (error: any) {
          if (error.type === undefined) {
            throwError({
              type: 'failed_to_parse_json',
              response,
              error,
            })
          }

          throw error
        }
      }

      if (response.status >= 500) {
        throwError({ type: 'server_error', status: response.status })
      }

      if (response.status === 404) {
        throwError({ type: 'not_found' })
      }
      if (response.status >= 400) {
        const json = await response.json()
        throwError({
          type: 'client_error',
          message: json.message,
          code: json.code,
        })
      }
    } catch (error: any) {
      if (error.type === undefined) {
        throwError({ type: 'network_error', error })
      }

      throw error
    }
  }

  const result = useReactInfiniteQuery<Result<C, H>, MutationError>(
    queryKey,
    ({ pageParam = initialPageParam ?? 0, ...rest }) =>
      queryFn({ pageParam, ...rest }),
    options,
  )

  const { data } = result

  if (data) {
    prevData.current = data
  }

  return { ...result, pageParam: page }
}
