import { TableCell } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  plain?: boolean
}

export const TableBodyCell = (props: Props) => {
  return (
    <TableCell
      sx={
        props.plain
          ? { paddingX: '0px' }
          : {
              fontSize: theme => theme.typography.pxToRem(16),
              borderTop: ({ palette }) => `1px solid ${palette.info.light}`,
              borderBottom: ({ palette }) => `1px solid ${palette.info.light}`,
              maxHeight: '92px',
              overflow: 'hidden',
              paddingX: '4px',
              '&:first-of-type': {
                borderLeft: ({ palette }) => `1px solid ${palette.info.light}`,
                borderBottomLeftRadius: '8px',
                borderTopLeftRadius: '8px',
              },
              '&:last-child': {
                borderRight: ({ palette }) => `1px solid ${palette.info.light}`,
                borderBottomRightRadius: '8px',
                borderTopRightRadius: '8px',
              },
            }
      }
      align={props.plain ? 'left' : 'center'}
      size={'small'}
    >
      {props.children}
    </TableCell>
  )
}
