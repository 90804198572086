import { isLeft } from 'fp-ts/lib/Either'
import { Mixed, TypeOf } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'

import { isRequestError, requestError } from './error'
import { getValidationMessage } from './get-validation-message'

export const decodeJson = <TCodec extends Mixed>(codec: TCodec) => {
  return async (response: Response): Promise<TypeOf<TCodec>> => {
    try {
      const json = await response.json()
      const decodedJson = codec.decode(json)
      if (isLeft(decodedJson)) {
        const errors = formatValidationErrors(decodedJson.left)
        throw requestError({
          json,
          message: getValidationMessage(errors),
          type: 'decode_body',
        })
      }
      return decodedJson.right
    } catch (error) {
      if (isRequestError(error)) {
        throw error
      }
      if (error instanceof Error) {
        throw requestError({
          error,
          response,
          type: 'parse_json',
        })
      }
      // This shouldn't happen
      throw error
    }
  }
}
