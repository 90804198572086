import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { routes } from 'app/routes'
import background from 'assets/images/not-found.png'
import { Button } from 'components/Button'
import { LinkPrefetch } from 'components/InternalLink/InternalLink'
import { useTranslation } from 'react-i18next'

export const NotFoundUnauthenticated = () => {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        p: 5,
        m: 2,
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'nowrap',
        margin: '0 auto',
      }}
    >
      <Box
        mb={4}
        sx={{
          margin: '0 auto',
          width: '600px',
          height: '50vh',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      />
      <Typography
        variant="h2"
        color="text.primary"
        fontWeight={700}
        align="center"
        gutterBottom
      >
        {t('unauthorized.page_not_found')}
      </Typography>
      <Typography align="center" variant="h4" mt={1.5} mb={3}>
        {t('unauthorized.page_not_found_subtext')}
      </Typography>

      <Button
        component={LinkPrefetch}
        sx={{ textTransform: 'capitalize', maxWidth: 10 }}
        to={routes.signin}
        variant="contained"
      >
        {t('pages.sign_in')}
      </Button>
    </Paper>
  )
}
