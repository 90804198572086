import { FormControlLabel, Stack, Switch as MuiSwitch } from '@mui/material'
import { forwardRef } from 'react'

type SwitchProps = {
  isChecked: boolean
  onChange: (checked: boolean) => void

  label?: string
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end'
  disabled?: boolean
}

export const Switch = forwardRef(
  (
    { onChange, isChecked, label, labelPlacement, disabled }: SwitchProps,
    ref,
  ) => {
    return (
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          value="end"
          ref={ref}
          control={
            <MuiSwitch
              sx={{
                '& .Mui-checked .MuiSwitch-thumb': {
                  backgroundColor: 'secondary.dark',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: 'info.main',
                },
                '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                  backgroundColor: 'info.main',
                },
              }}
              onChange={(_e, checked) => onChange(checked)}
              checked={isChecked}
              disabled={disabled}
            />
          }
          label={label || ''}
          labelPlacement={labelPlacement || 'start'}
        />
      </Stack>
    )
  },
)
