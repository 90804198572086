import { createUseAuth } from 'lib/use-auth'

import { issueToken, refreshToken, signOut } from './auth-api/index'
import { env } from './env'

export const { AuthProvider, useAuthContext } = createUseAuth({
  clientSecret: env.REACT_APP_APPLICATION_ID,
  issueToken,
  refreshToken,
  signOut,
})
