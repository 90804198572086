import { Grid } from '@mui/material'
import { Switch } from 'components/Switch'
import { UUID } from 'io-ts-types'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormInput } from '../FormInput'
import { FormModal } from '../FormModal'

type Props = {
  defaultValues: FormValues
  open: boolean
  taskId: string
  chunkIds: UUID[]
  translation: string | null
  onDiscard: () => void
  isLoading?: boolean
}

type FormValues = {
  speaker: string
  updateCurrent: boolean
}

export const SpeakerChangeModal = (props: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const search = useMemo(() => {
    const params = new URLSearchParams({})

    if (props.translation) {
      params.set('language', props.translation)
    }

    return params
  }, [props.translation])

  const $updateSpeaker = useMutation(
    'PATCH',
    `/api/tasks/:taskId/transcription/speaker`,
  )

  const onSubmit = (values: FormValues) => {
    $updateSpeaker.mutate(
      {
        params: { taskId: props.taskId },
        body: {
          oldSpeakerName: props.defaultValues.speaker,
          newSpeakerName: values.speaker,
          chunkIds: values.updateCurrent ? props.chunkIds : [],
        },
        search,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t('success_notification.speaker_was_changed'), {
            variant: 'success',
          })
          props.onDiscard()
        },
        onError: error => {
          if (error.type === 'client_error') {
            enqueueSnackbar(
              t('error_notification.error_phrase_change_failed'),
              {
                variant: 'error',
              },
            )
          }

          if (error.type === 'server_error') {
            enqueueSnackbar(t(`server_error.${error.status}`), {
              variant: 'error',
            })
          }
        },
      },
    )
  }

  return (
    <FormModal<FormValues>
      onSubmit={onSubmit}
      defaultValues={props.defaultValues}
      title={t('common.edit_commentator')}
      submitTitle={t('buttons.save')}
      onClose={props.onDiscard}
      open={props.open}
      isLoading={props.isLoading ?? false}
      renderForm={({ control }) => (
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          onKeyDown={event => {
            if (event.key === ' ') {
              event.stopPropagation()
            }
          }}
        >
          <Grid item xs={12}>
            <FormInput
              type="filled"
              control={control}
              label={t('common.commentators_name')}
              name="speaker"
              required
              fullWidth
              minLength={0}
              maxLength={30}
              placeholder={t('custom_dictionary.name')}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="updateCurrent"
              render={({ field }) => (
                <Switch
                  {...field}
                  isChecked={field.value}
                  label={t('common.update_current_speaker')}
                  labelPlacement="end"
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
