import { Typography } from '@mui/material'
import { IIntegrationType, TIntegrationType } from 'app/codecs'
import { integrationTypeMapping } from 'config/integration-options'
import { TypeOf } from 'io-ts'

type Props = {
  source: TypeOf<typeof TIntegrationType> | 'file'
}

export const SourceCell = ({ source }: Props) => {
  return (
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {integrationTypeMapping[source as IIntegrationType] || 'File'}
    </Typography>
  )
}
