import 'videojs-seek-buttons'
import 'video.js/dist/video-js.min.css'
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css'
import './styles.css'

import { RefObject } from 'react'

type Props = {
  videoRef: RefObject<HTMLDivElement>
}

export const VideoPlayer = ({ videoRef }: Props) => {
  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  )
}
