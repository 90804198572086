import { Grid } from '@mui/material'
import { FormInput, FormModal } from 'components/Form'
import { validation } from 'config/validation'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const formatDurationToSeconds = ({
  hours,
  minutes,
  seconds,
}: {
  hours?: number
  minutes?: number
  seconds?: number
}) => {
  return (
    Number(hours ?? 0) * 3600 + Number(minutes ?? 0) * 60 + Number(seconds ?? 0)
  )
}

type FormValues = {
  hours: number
  minutes: number
  seconds: number
}

type Props =
  | {
      type: 'ADD'
      organizationId: string
      onClose: () => void
      open: boolean
      refetch: () => void
    }
  | {
      type: 'SET'
      onClose: () => void
      open: boolean
      refetch: () => void
    }

export const SetDefaultCreditLimitModal = (props: Props) => {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const $addCredit = useMutation(
    'POST',
    '/api/organizations/:organizationId/credits',
  )

  const $setDefaultCreditLimit = useMutation(
    'PUT',
    '/api/organizations/my/default-credits-limit',
  )

  const MINIMUM_CREDIT = 60

  const mutateAddCredit = $addCredit.mutate

  const mutateSetDefault = $setDefaultCreditLimit.mutate

  const onSubmit = (data: FormValues) => {
    const creditsSum = formatDurationToSeconds(data)
    if (props.type === 'ADD') {
      if (creditsSum < MINIMUM_CREDIT) {
        enqueueSnackbar(
          t('validation.min_credits_number', { min: MINIMUM_CREDIT }),
          {
            variant: 'error',
          },
        )
        return
      }
      mutateAddCredit(
        {
          params: { organizationId: props.organizationId },
          body: {
            creditsNumber: formatDurationToSeconds(data),
          },
        },
        {
          onSuccess: () => {
            enqueueSnackbar(t('success_notification.credits_added'), {
              variant: 'success',
            })
            props.onClose()
            props.refetch()
          },
          onError: () => {
            enqueueSnackbar(t('error_notidication.error_credits_adding'), {
              variant: 'error',
            })
            props.onClose()
          },
        },
      )
    }
    if (props.type === 'SET') {
      mutateSetDefault(
        {
          body: {
            defaultCreditsLimit: formatDurationToSeconds(data),
          },
        },
        {
          onSuccess: () => {
            enqueueSnackbar(t('success_notification.default_credits_set'), {
              variant: 'success',
            })
            props.onClose()
            props.refetch()
          },
          onError: () => {
            enqueueSnackbar(t('error_notidication.error_credits_setting'), {
              variant: 'error',
            })
            props.onClose()
          },
        },
      )
    }
  }

  return (
    <FormModal<FormValues>
      onSubmit={onSubmit}
      title={t(
        `labels.${
          props.type === 'ADD' ? 'add_credit' : 'set_default_credit_limit'
        }`,
      )}
      submitTitle={t(`buttons.${props.type === 'ADD' ? 'add' : 'set'}`)}
      onClose={props.onClose}
      open={props.open}
      isLoading={$addCredit.isLoading || $setDefaultCreditLimit.isLoading}
      renderForm={({ control }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              type="filled"
              control={control}
              name="hours"
              required={false}
              fullWidth
              placeholder={t('placeholders.hours')}
              pattern={{
                value:
                  props.type === 'SET'
                    ? validation.numbersRegex
                    : validation.onlyNumbersRegex,
                message: t(
                  `validation.non_negative_number_${
                    props.type === 'SET' ? 'less_equal_100' : 'max_10'
                  }`,
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="minutes"
              required={false}
              fullWidth
              placeholder={t('placeholders.minutes')}
              pattern={{
                value: validation.numberLess60,
                message: t(`validation.non_negative_number_max`),
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}
