import 'styles/index.css'

import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthProvider } from 'app/auth'
import { CreateTaskProvider } from 'app/create-task'
import { muiTheme } from 'app/theme'
import { NotificationProvider } from 'components'
import Pace from 'components/Pace'
import { RuntimeErrorDialog } from 'components/RuntimeErrorDialog'
import { ComponentType, StrictMode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'

// It is a global configuration. You can change it per-query as well.
// Stale queries are re-fetched automatically in the background when:
// - New instances of the query mount.
// - The network is reconnected.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: any) => {
        return (
          error?.response?.status in [500, 502, 503, 504] && failureCount < 3
        )
      },
      refetchOnWindowFocus: false,
    },
  },
})

type Props = {
  Component: ComponentType
}

const App = ({ Component }: Props) => {
  return (
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <Pace color={muiTheme.palette.primary.light} />
          <ErrorBoundary fallbackRender={RuntimeErrorDialog}>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <AuthProvider>
                    <CreateTaskProvider>
                      <Component />
                    </CreateTaskProvider>
                  </AuthProvider>
                </BrowserRouter>
              </QueryClientProvider>
            </NotificationProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </StrictMode>
  )
}

export default App
