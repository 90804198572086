import {
  Box,
  CircularProgress,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TableContainer,
  Typography,
} from '@mui/material'
import { FileEventLog, FileEventLogTableCodec } from 'app/codecs'
import { ReactComponent as FileHistoryArrow } from 'assets/icons/file-history-arrow.svg'
import { ReactComponent as FileHistoryAvatar } from 'assets/icons/file-history-avatar.svg'
import { StyledAvatar } from 'components'
import { HeaderCodec } from 'components/DataGrid/DataGrid'
import { useInfiniteQuery } from 'lib/rest-query/rest-infinite-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCreationDate } from 'utils/format'

import { Chip, NoEntriesPlaceholder } from './components'
import { getInitials, statusColor, stepperIcons } from './helpers'

const limit = 100

type Props = {
  taskId: string
  translation: string | null
}

export const FileHistory = ({ taskId, translation }: Props) => {
  const { t } = useTranslation()

  const $eventLogs = useInfiniteQuery(
    'GET',
    `/api/event-logs/${taskId}`,
    FileEventLogTableCodec,
    {
      headersCodec: HeaderCodec,
      skipName: 'skip',
      params: {
        limit: String(limit),
        ...(translation && { additionalEntityId: translation }),
      },
      options: {
        keepPreviousData: true,
        getNextPageParam: (currentPage, allPages) => {
          const nextPage = allPages.length
          const totalPosts = Number(currentPage.headers['x-total-count'])
          const lastPage = Math.ceil(totalPosts / limit)

          return nextPage < lastPage ? nextPage * limit : undefined
        },
      },
    },
  )

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = $eventLogs

  const events = useMemo(() => {
    return data?.pages.flatMap(page => page.body) ?? []
  }, [data?.pages])

  if (!data) {
    return null
  }

  const getLogInstance = (event: FileEventLog, type: 'new' | 'old') => {
    if (event.eventLogType === 'TRANSCRIPTION_CHUNK_UPDATED') {
      return (
        <Typography
          fontSize="14px"
          lineHeight="22px"
          color="text.primary"
          fontWeight={400}
          textAlign="justify"
        >
          {event.logInfo[`${type}Text` as const]}
        </Typography>
      )
    }

    if (event.eventLogType === 'TRANSCRIPTION_SPEAKER_UPDATED') {
      return (
        <Typography
          fontSize="14px"
          lineHeight="22px"
          color="text.primary"
          fontWeight={400}
          textAlign="justify"
        >
          {event.logInfo[`${type}Speaker` as const]}
        </Typography>
      )
    }

    if (event.eventLogType === 'TASK_STATUS_UPDATED') {
      return (
        <Chip
          label={t(`task_status.${event.logInfo[`${type}Status` as const]}`)}
          backgroundColor={statusColor[event.logInfo[`${type}Status` as const]]}
        />
      )
    }

    if (event.eventLogType === 'TASK_SHARE_UPDATED') {
      return (
        <Chip
          label={t(
            `task_share_status.${
              event.logInfo[`${type}SharedStatus` as const]
            }`,
          )}
          backgroundColor={
            event.logInfo[`${type}SharedStatus` as const] === 'PUBLIC'
              ? 'secondary.dark'
              : 'text.disabled'
          }
        />
      )
    }

    return (
      <Typography
        fontSize="14px"
        lineHeight="22px"
        color="text.hai"
        fontWeight={400}
      >
        {t('common.none')}
      </Typography>
    )
  }

  return (
    <TableContainer
      sx={{ maxHeight: 'calc(100vh - 335px)', width: '100%' }}
      onScroll={event => {
        const element = event.target as HTMLElement
        const offset = 55

        if (
          hasNextPage &&
          !isFetchingNextPage &&
          Math.abs(
            element.scrollHeight -
              element.clientHeight -
              element.scrollTop -
              offset,
          ) < 100
        ) {
          fetchNextPage()
        }
      }}
    >
      <Stepper orientation="vertical" variant="outlined">
        {events.length === 0 ? (
          <NoEntriesPlaceholder />
        ) : (
          events.map((event, idx) => (
            <Step key={event.eventLogId} active={true}>
              <StepLabel
                StepIconComponent={stepperIcons[event.eventLogType]}
                StepIconProps={{
                  active: undefined,
                  error: undefined,
                  completed: undefined,
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  {event.eventLogType === 'TASK_STATUS_UPDATED' ? (
                    <FileHistoryAvatar />
                  ) : (
                    <StyledAvatar
                      sx={{
                        width: '32px',
                        height: '32px',
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        border: '1px solid #E6EAEE',
                      }}
                      src={event.logInfo.avatar ?? undefined}
                    >
                      {getInitials(event.logInfo.fullName)}
                    </StyledAvatar>
                  )}
                  <Box>
                    <Typography
                      fontSize="16px"
                      lineHeight="22px"
                      color="text.primary"
                      fontWeight={500}
                      ml={0.5}
                      mr={1.5}
                    >
                      {t(`file_history_message.${event.eventLogType}`, {
                        name: event.logInfo?.fullName ?? undefined,
                      })}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      fontSize="14px"
                      lineHeight="22px"
                      color="text.secondary"
                      fontWeight={400}
                    >
                      {formatCreationDate(event.createdAt)}
                    </Typography>
                  </Box>
                </Stack>
              </StepLabel>
              {event.eventLogType !== 'TRANSCRIPTION_RESET' && (
                <StepContent>
                  {event.eventLogType === 'TRANSCRIPTION_CHUNK_FINE_TUNED' ? (
                    <Typography
                      fontSize="14px"
                      lineHeight="22px"
                      color="text.primary"
                      fontWeight={400}
                      textAlign="justify"
                    >
                      {event.logInfo.text}
                    </Typography>
                  ) : event.eventLogType === 'TASK_TAG_CREATED' ||
                    event.eventLogType === 'TASK_TAG_REMOVED' ? (
                    <Chip
                      label={event.logInfo.tagName}
                      backgroundColor="text.disabled"
                    />
                  ) : (
                    <>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Box display="flex" flexShrink={1}>
                          {getLogInstance(event, 'old')}
                        </Box>
                        <Box display="flex" flexShrink={1}>
                          <FileHistoryArrow />
                        </Box>
                        <Box display="flex" flexGrow={1} flexShrink={1}>
                          {getLogInstance(event, 'new')}
                        </Box>
                      </Stack>
                      {event.eventLogType === 'TRANSCRIPTION_SPEAKER_UPDATED' &&
                        event.logInfo.speakerChangedForAllChunks === false && (
                          <Box mt={1}>
                            <Typography
                              fontSize="14px"
                              lineHeight="22px"
                              color="text.primary"
                              fontWeight={400}
                              textAlign="justify"
                            >
                              {t('common.phrase')}:{' '}
                              {event.logInfo.speakerChangedForChunks
                                .map(item => item.text)
                                .join(' ')}
                            </Typography>
                          </Box>
                        )}
                    </>
                  )}
                </StepContent>
              )}
            </Step>
          ))
        )}
      </Stepper>
      {hasNextPage && (
        <Box
          display="flex"
          flex={1}
          p={2}
          alignItems="center"
          justifyContent="center"
          height={55}
        >
          {isFetchingNextPage && <CircularProgress size={32} color="inherit" />}
        </Box>
      )}
    </TableContainer>
  )
}
