import {
  useQuery as useReactQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useAuthContext } from 'app/auth'
import { isLeft } from 'fp-ts/Either'
import { Mixed, TypeOf } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'
import { useEffect, useRef } from 'react'

import { MutationError, RestMethod, throwError } from './common'

export const useJsonQuery = <
  C extends Mixed,
  H extends Mixed | undefined = undefined,
>(
  method: RestMethod,
  url: string,
  codec: C,
  {
    rawBody,
    headersCodec,
    options,
  }: {
    rawBody?: {} | null
    headersCodec?: H | null
    options?: UseQueryOptions<TypeOf<C>, MutationError>
  } = {},
) => {
  const body = rawBody ? JSON.stringify(rawBody) : undefined
  const { accessToken } = useAuthContext()
  const token = accessToken === null ? null : accessToken.accessToken

  const tokenReference = useRef(token)
  useEffect(() => {
    tokenReference.current = token
  }, [token])

  type Result = H extends Mixed
    ? {
        body: TypeOf<C>
        headers: TypeOf<H>
      }
    : TypeOf<C>

  const prevData = useRef<Result | null>(null)

  const queryKey = [method, url, body]

  const queryFn = async () => {
    const headers = new Headers({
      'Content-Type': 'application/json',
    })

    const token = tokenReference.current

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    try {
      const response = await window.fetch(url, {
        method,
        body,
        headers,
      })

      if (response.ok) {
        try {
          const json: unknown = await response.json()
          const decodedJson = codec.decode(json)
          if (isLeft(decodedJson)) {
            const errors = formatValidationErrors(decodedJson.left)
            return throwError({ type: 'failed_to_decode_json', json, errors })
          }

          if (headersCodec) {
            const decodedHeaders = headersCodec.decode(
              Object.fromEntries(response.headers.entries()),
            )

            if (isLeft(decodedHeaders)) {
              const errors = formatValidationErrors(decodedHeaders.left)
              return throwError({
                type: 'failed_to_decode_headers',
                headers: response.headers,
                errors,
              })
            }

            return {
              body: decodedJson.right,
              headers: decodedHeaders.right,
            }
          }

          return decodedJson.right
        } catch (error: any) {
          if (error.type === undefined) {
            throwError({
              type: 'failed_to_parse_json',
              response,
              error,
            })
          }

          throw error
        }
      }

      if (response.status >= 500) {
        throwError({ type: 'server_error', status: response.status })
      }

      if (response.status === 404) {
        throwError({ type: 'not_found' })
      }
      if (response.status >= 400) {
        const json = await response.json()
        throwError({
          type: 'client_error',
          message: json.message,
          code: json.code,
        })
      }
    } catch (error: any) {
      if (error.type === undefined) {
        throwError({ type: 'network_error', error })
      }

      throw error
    }
  }

  const result = useReactQuery<Result, MutationError>(
    queryKey,
    queryFn,
    options,
  )

  const { data } = result

  if (data) {
    prevData.current = data
  }

  return { ...result, prevData: prevData.current }
}
