import { ButtonBase, Popover, Stack, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { Switch } from 'components'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useTranslation } from 'react-i18next'

type Props = {
  grouped: boolean
  onChange: (grouped: boolean) => void

  disabled?: boolean
}

export const ViewSettingsButton = ({ grouped, disabled, onChange }: Props) => {
  const { t } = useTranslation()

  const popupState = usePopupState({
    popupId: 'viewSettings',
    variant: 'popover',
  })

  return (
    <Box>
      <Tooltip title={t('labels.view_settings')}>
        <span>
          <ButtonBase
            {...bindTrigger(popupState)}
            sx={{ padding: '10px', borderRadius: '43px' }}
            disabled={disabled}
          >
            <SettingsIcon color="#37637E" />
          </ButtonBase>
        </span>
      </Tooltip>
      <Popover
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
      >
        <Box sx={{ px: 3.5, py: 4, minWidth: 350, minHeight: 150 }}>
          <Typography variant="h3" mb={1}>
            {t('labels.view_settings')}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              onChange={checked => {
                onChange(checked)
                popupState.close()
              }}
              isChecked={grouped}
              label={t('labels.group_representation')}
              labelPlacement="end"
            />
          </Stack>
        </Box>
      </Popover>
    </Box>
  )
}
