import { QueryDispatch, SetStateAction } from 'react-router-use-location-state'

import {
  useQueryStateValidated,
  useQueryStateValidatedNullable,
} from '../filters'

export type SortDirection = 'ASC' | 'DESC'

export type UseSortingInput<TSortColumn extends string> = {
  initialOrderBy: TSortColumn | null
  initialDirection: SortDirection
  sortingColumns: ReadonlyArray<TSortColumn>
}

export type UseSortingOutput<TSortColumn extends string> = {
  orderBy: TSortColumn | null
  setOrderBy: QueryDispatch<TSortColumn | null>
  direction: SortDirection
  setDirection: QueryDispatch<SetStateAction<SortDirection>>
}

/**
 * Handles server-side sorting.
 * Persists the sorting state (sorted column and direction) in the URL.
 *
 * The sorted column and direction are independent on each other, however both states aren't typically used apart, so putting them into a single hook makes sense.
 */
export const useSorting = <TSortColumn extends string>({
  initialOrderBy,
  initialDirection,
  sortingColumns,
}: UseSortingInput<TSortColumn>): UseSortingOutput<TSortColumn> => {
  const [orderBy, setOrderBy] =
    useQueryStateValidatedNullable<TSortColumn | null>({
      key: 'orderBy',
      defaultValue: initialOrderBy,
      isValid: v => v === null || sortingColumns.includes(v),
    })

  const [direction, setDirection] = useQueryStateValidated<SortDirection>({
    key: 'direction',
    defaultValue: initialDirection,
    isValid: v => v === 'ASC' || v === 'DESC',
  })

  return {
    orderBy,
    setOrderBy,
    direction,
    setDirection,
  }
}
