import { Box, InputLabel, Paper, Stack, Typography } from '@mui/material'
import { OrganizationsShortCodec } from 'app/codecs'
import { CreditsChartCodec, UsersChartCodec } from 'app/codecs/dashboard'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js'
import { AutocompleteSingle } from 'components/AutocompleteSingle'
import { DateRangePicker } from 'components/DateRangePicker'
import { InformationWidget } from 'components/Widgets'
import { addDays, endOfMonth, format, subMonths, subYears } from 'date-fns'
import { formatDuration } from 'imgplay-domain/format'
import { concatQueryParams } from 'lib/request'
import { useJsonQuery } from 'lib/rest-query'
import debounce from 'lodash.debounce'
import { useMemo, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  LineController,
  PointElement,
  Tooltip,
)

type Props = {
  type: 'line' | 'bar'
  entity: 'credit' | 'user'
}

type DateRange = {
  startDate: Date | null
  endDate: Date | null
}

export const StatisticsChart = (props: Props) => {
  const { t } = useTranslation()
  const initialEndDate = useMemo(() => {
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    )

    const previousMonthEnd = endOfMonth(subMonths(today, 1))
    return previousMonthEnd
  }, [])
  const initialStartDate = addDays(subYears(initialEndDate, 1), 1)

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: initialStartDate,
    endDate: initialEndDate,
  })

  const [organization, setOrganization] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const onInputChange = useMemo(() => {
    return debounce(setSearch, 500)
  }, [setSearch])

  const chartParams = useMemo(() => {
    const params = new URLSearchParams()

    if (dateRange.startDate) {
      params.set('startDate', format(dateRange.startDate, 'yyyy-MM-dd'))
    }

    if (dateRange.endDate) {
      params.set('endDate', format(dateRange.endDate, 'yyyy-MM-dd'))
    }

    if (organization) {
      params.set('search', organization)
    }

    return params
  }, [dateRange.endDate, dateRange.startDate, organization])

  const orgParams = useMemo(() => {
    const params = new URLSearchParams()

    if (search) {
      params.set('search', search)
    }

    return params
  }, [search])

  const $credits = useJsonQuery(
    'GET',
    concatQueryParams(`/api/organizations/statistics/credit`, chartParams),
    CreditsChartCodec,
    { options: { keepPreviousData: true, enabled: props.entity === 'credit' } },
  )

  const $users = useJsonQuery(
    'GET',
    concatQueryParams(`/api/organizations/statistics/user`, chartParams),
    UsersChartCodec,
    { options: { keepPreviousData: true, enabled: props.entity === 'user' } },
  )

  const $organizations = useJsonQuery(
    'GET',
    concatQueryParams('/api/organizations/short', orgParams),
    OrganizationsShortCodec,
    {
      options: {
        keepPreviousData: search.length > 0,
        enabled: search.length > 0,
      },
    },
  )

  if (
    (!$credits.data && props.entity === 'credit') ||
    (!$users.data && props.entity === 'user') ||
    (!$credits.data && !$users.data)
  ) {
    return null
  }

  const chartOptions: ChartOptions<'bar' | 'line'> = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        display: true,
        offset: true,
      },
      y: {
        display: true,
        beginAtZero: true,
        grace: '5%',
        ticks:
          props.entity === 'credit'
            ? {
                stepSize: 3600,
                callback: seconds => formatDuration(Number(seconds)),
              }
            : undefined,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (ttItem) {
            return props.entity === 'credit'
              ? formatDuration(ttItem.parsed.y)
              : `${t('labels.amount')}: ${ttItem.parsed.y}`
          },
        },
      },
    },
  }

  return (
    <Box mb={3}>
      <Paper>
        <Box p={3}>
          <Stack
            direction={{ lg: 'row' }}
            justifyContent={{ lg: 'space-between' }}
            alignItems={{ lg: 'center' }}
            rowGap={2}
            mb={3}
          >
            <Box>
              <Typography
                mb={2}
                variant="h2"
                fontWeight={600}
                textTransform="capitalize"
              >
                {t(`admin_organization_tabs.${props.entity}s`)}
              </Typography>
              <Box
                width="auto"
                alignItems="flex-start"
                display="flex"
                flexShrink={1}
              >
                <InformationWidget
                  info={t(`labels.total_${props.entity}s`) + ':'}
                  value={
                    props.entity === 'credit'
                      ? formatDuration($credits.data?.totalCreditsByPeriod ?? 0)
                      : String($users.data?.totalUsersNumber ?? 0) + '\u00A0'
                  }
                />
              </Box>
            </Box>
            <Stack
              direction={{ sm: 'row' }}
              spacing={3}
              rowGap={2}
              alignItems={{ sm: 'center' }}
            >
              <Box width={250}>
                <InputLabel
                  sx={{
                    mb: 0.5,
                    fontSize: 14,
                    fontWeight: 400,
                    color: '#37637E',
                  }}
                >
                  {t('labels.organization')}
                </InputLabel>
                <AutocompleteSingle
                  size="small"
                  options={
                    $organizations.data?.map(({ name, accountId }) => ({
                      label: name,
                      value: accountId,
                    })) ?? []
                  }
                  value={organization}
                  variant="standard"
                  placeholder={t('placeholders.all_organizations')}
                  onChange={value => {
                    setOrganization(value ?? '')
                  }}
                  onInputChange={(_e, value) => onInputChange(value)}
                  filterOptions={options => options}
                  getOptionLabel={option => option.label}
                  renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                      {`${option.label} (${option.value})`}
                    </li>
                  )}
                  loading={$organizations.isLoading}
                  noOptionsText={
                    search
                      ? undefined
                      : t('placeholders.start_typing_for_search')
                  }
                />
              </Box>
              <Box>
                <InputLabel
                  sx={{
                    mb: 0.5,
                    fontSize: 14,
                    fontWeight: 400,
                    color: '#37637E',
                  }}
                >
                  {t('labels.date_range')}
                </InputLabel>
                <DateRangePicker
                  value={dateRange}
                  onChange={range => setDateRange(range)}
                  anchorDirection="right"
                />
              </Box>
            </Stack>
          </Stack>

          <Box height={240} width="auto">
            <Chart
              type={props.type}
              data={{
                labels:
                  (props.entity === 'credit'
                    ? $credits.data?.creditsByDate
                    : $users.data?.usersByDate
                  )?.map(({ date }) =>
                    format(
                      new Date(date),
                      (props.entity === 'credit' &&
                        $credits.data?.periodType === 'YEAR') ||
                        (props.entity === 'user' &&
                          $users.data?.periodType === 'YEAR')
                        ? 'MMM yyyy'
                        : 'dd MMM yy',
                    ),
                  ) ?? [],
                datasets: [
                  {
                    data:
                      props.entity === 'credit'
                        ? $credits.data?.creditsByDate.map(
                            ({ credits }) => credits,
                          ) ?? []
                        : $users.data?.usersByDate.map(
                            ({ usersNumber }) => usersNumber,
                          ) ?? [],
                    backgroundColor:
                      props.entity === 'credit' ? '#4AC398' : '#8FA0FA',
                    borderWidth: props.type === 'line' ? 2 : undefined,
                    borderColor:
                      props.entity === 'user' ? '#8FA0FA' : undefined,
                  },
                ],
              }}
              options={chartOptions}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
