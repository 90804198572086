import { pipe } from 'fp-ts/lib/function'
import { UUID } from 'io-ts-types'
import { decodeJson } from 'lib/request'

import { TAuthResult } from './access-token'
import { del, get, patch, post } from './request'

export type IssueTokenInput = {
  email: string
  password: string
  clientSecret: string
}

export const issueToken = async (input: IssueTokenInput) => {
  return pipe(
    await post('api/tokens/credentials', {
      body: {
        client: input.clientSecret,
        email: input.email.trim(),
        password: input.password,
      },
    }),
    decodeJson(TAuthResult),
  )
}
export type RefreshTokenInput = {
  clientSecret: string
}

export const refreshToken = async (input: RefreshTokenInput) => {
  return pipe(
    await post('api/tokens/refresh', {
      body: {
        client: input.clientSecret,
      },
    }),
    decodeJson(TAuthResult),
  )
}

export const signOut = async () => {
  return del('api/tokens/me', {})
}

export type SendResetPasswordLinkInput = {
  email: string
}

export const sendResetPasswordLink = async (
  input: SendResetPasswordLinkInput,
) => {
  return post('api/confirmations/recovery', {
    query: new URLSearchParams({
      email: input.email.trim(),
    }),
  })
}

export type GetConfirmationInput = {
  requestId: UUID
  hash: string
}

export const getConfirmation = async (input: GetConfirmationInput) => {
  return get('api/confirmations/:requestId', {
    params: {
      requestId: input.requestId,
    },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}

export type ConfirmInvitationInput = {
  requestId: UUID
  hash: string
  password: string
}

export const confirmInvitation = async (input: ConfirmInvitationInput) => {
  return patch('api/confirmations/:requestId/invite', {
    body: {
      newPassword: input.password,
    },
    params: {
      requestId: input.requestId,
    },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}

export type ResetPasswordInput = {
  requestId: UUID
  hash: string
  password: string
}

export const resetPassword = async (input: ResetPasswordInput) => {
  return patch('api/confirmations/:requestId/password', {
    body: {
      newPassword: input.password,
    },
    params: {
      requestId: input.requestId,
    },
    query: new URLSearchParams({
      hash: input.hash,
    }),
  })
}
