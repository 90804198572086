import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { routes } from 'app/routes'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const RuntimeErrorDialog = (props: FallbackProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Dialog open>
      <DialogTitle>{t('something_went_wrong_dialog.title')} 🐞</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('something_went_wrong_dialog.may_be_error')} <br />
          {t('something_went_wrong_dialog.please_report')}
          <Typography color="error">
            <pre>{JSON.stringify(props.error)}</pre>
          </Typography>
          <CopyToClipboard text={JSON.stringify(props.error)}>
            <Button variant="outlined">
              {t('something_went_wrong_dialog.copy_to_clipboard')}
            </Button>
          </CopyToClipboard>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(0)
          }}
        >
          {t('something_went_wrong_dialog.refresh_page')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(routes.index, { replace: true })
            props.resetErrorBoundary()
          }}
        >
          {t('something_went_wrong_dialog.go_to_main_page')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
