import { ButtonBase, Tooltip } from '@mui/material'
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download-file.svg'
import { useTranslation } from 'react-i18next'

export const DownloadFileButton = ({ url }: { url: string }) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('buttons.download')}>
      <span>
        <ButtonBase
          component="a"
          href={url}
          download
          sx={{ padding: '10px', borderRadius: '43px' }}
        >
          <DownloadFileIcon color="#37637E" />
        </ButtonBase>
      </span>
    </Tooltip>
  )
}
