import { FileEventLog, ITaskStatus } from 'app/codecs'
import { ReactComponent as FileShareHistory } from 'assets/icons/file-share-history.svg'
import { ReactComponent as FileStatusHistory } from 'assets/icons/file-status-history.svg'
import { ReactComponent as FileTextHistory } from 'assets/icons/file-text-history.svg'
import { ReactComponent as FineTuneHistory } from 'assets/icons/fine-tune-history.svg'
import { ReactComponent as TranscriptionResetHistory } from 'assets/icons/transcription-reset-history.svg'
import { FunctionComponent } from 'react'

export const stepperIcons: Record<
  FileEventLog['eventLogType'],
  FunctionComponent
> = {
  TASK_STATUS_UPDATED: FileStatusHistory,
  TRANSCRIPTION_CHUNK_UPDATED: FileTextHistory,
  TASK_SHARE_UPDATED: FileShareHistory,
  TRANSCRIPTION_RESET: TranscriptionResetHistory,
  TRANSCRIPTION_CHUNK_FINE_TUNED: FineTuneHistory,
  TRANSCRIPTION_SPEAKER_UPDATED: FileTextHistory,
  TASK_TAG_CREATED: FileStatusHistory,
  TASK_TAG_REMOVED: FileStatusHistory,
}

export const statusColor: Record<ITaskStatus, string> = {
  DONE: 'secondary.dark',
  ERROR: 'secondary.light',
  CREATED: 'secondary.light',
  SYNCHRONIZED: 'secondary.dark',
  SCHEDULED: 'secondary.main',
  DOWNLOADED: 'secondary.main',
  DOWNLOADING: 'secondary.main',
  PROCESSING: 'secondary.main',
  CHUNK_AUDIO: 'secondary.main',
  SEND_TO_CHUNK_AUDIO: 'secondary.main',
  CHUNKING_AUDIO: 'secondary.main',
  EXTRACT_AUDIO: 'secondary.main',
  SEND_TO_EXTRACT_AUDIO: 'secondary.main',
  EXTRACTING_AUDIO: 'secondary.main',
  TRANSCODE_VIDEO: 'secondary.main',
  SEND_TO_TRANSCODE_VIDEO: 'secondary.main',
  TRANSCODING_VIDEO: 'secondary.main',
}

export const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(' ')

  return firstName.charAt(0) + lastName.charAt(0)
}
