import { ReactComponent as Enabled } from 'assets/icons/success.svg'
import { ReactComponent as Disabled } from 'assets/icons/upload-disabled.svg'
import React from 'react'
type Props = {
  upload: boolean
}

export const VideoUploadPermission = ({ upload }: Props) => {
  const renderer = upload ? <Enabled /> : <Disabled />
  return renderer
}
