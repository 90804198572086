import * as t from 'io-ts'

export const IncorrectPasswordErrorCodeCodec = t.literal(
  'error_incorrect_password',
)

export const UserNotFoundErrorCodeCodec = t.literal('user_not_found')

export const IncorrectNewPasswordErrorCodeCodec = t.literal(
  'error_incorrect_new_password',
)

export const UserAlreadyConfirmedErrorCodeCodec = t.literal(
  'error_user_already_confirmed',
)

export const EmailIsAlreadyUsedErrorCodeCodec = t.literal(
  'error_email_already_used',
)

export const EmailNotFoundErrorCodeCodec = t.literal('error_email_not_found')

export const UserIsInactiveErrorCodeCodec = t.literal('error_profile_inactive')

export const IncorrectCredentialsErrorCodeCodec = t.literal(
  'error_incorrect_credentials',
)
