import { Box, Stack, Typography } from '@mui/material'
import { TaskDetailsShortCodec } from 'app/codecs'
import { AutocompleteSingle } from 'components/AutocompleteSingle'
import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import * as c from 'io-ts'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  onClose: () => void
  open: boolean
  taskId: string
  refetch: () => void
}

export const SelectLanguageModal = ({
  open,
  onClose,
  taskId,
  refetch,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [autocompleteValue, setAutoCompleteValue] = useState<string | null>(
    null,
  )
  const { t } = useTranslation()
  const { data } = useJsonQuery(
    'GET',
    '/api/tasks/languages?withNone=false',
    c.record(c.string, c.string),
  )
  const $taskDetails = useJsonQuery(
    'GET',
    `api/tasks/${taskId}/short`,
    TaskDetailsShortCodec,
  )
  useEffect(() => {
    setAutoCompleteValue($taskDetails.data?.languageCode || null)
  }, [$taskDetails.data?.languageCode])

  const $setTaskLanguage = useMutation('PATCH', '/api/tasks/:taskId/language')
  const setTaskLanguage = $setTaskLanguage.mutate

  const onSubmit = (language: string | null) => {
    setTaskLanguage(
      {
        body: {
          languageCode: language,
        },
        params: {
          taskId,
        },
      },
      {
        onSuccess: () => {
          onClose()
          refetch()
          enqueueSnackbar(t('success_notification.language_selected'), {
            variant: 'success',
          })
        },
        onError: error => {
          if (error.type === 'client_error') {
            enqueueSnackbar(error.message, { variant: 'error' })
          }

          if (error.type === 'server_error') {
            enqueueSnackbar(t(`server_error.${error.status}`), {
              variant: 'error',
            })
          }
        },
      },
    )
  }

  if (!data) {
    return null
  }

  if ($taskDetails.data?.status !== 'CREATED') {
    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{ minWidth: 200, pt: 3, pr: 3.5, pb: 4.25, pl: 4 }}>
          <Typography variant="body1" mb={1.5}>
            {t('select_language_dialog.already_selected')}
          </Typography>

          <Stack direction="row" justifyContent="center" pt={3}>
            <Button
              onClick={onClose}
              noSpacing
              withShadow
              color="primary"
              variant="contained"
              sx={{ textTransform: 'capitalize', width: '124px' }}
            >
              {t('common.ok')}
            </Button>
          </Stack>
        </Box>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ minWidth: 200, pt: 3, pr: 3.5, pb: 4.25, pl: 4 }}>
        <Typography variant="h2" fontWeight={700} mb={1.5}>
          {t('select_language_dialog.title')}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          mb={3}
          width="80%"
          lineHeight={1.75}
        >
          {t('select_language_dialog.message')}
        </Typography>

        <AutocompleteSingle
          label="Choose language"
          variant="standard"
          placeholder={
            autocompleteValue ? '' : t('integrations.search_language')
          }
          options={Object.keys(data).map(key => ({
            label: data[key],
            value: key,
          }))}
          required
          value={autocompleteValue}
          onChange={value => setAutoCompleteValue(value)}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          pt={3}
        >
          <Button
            noSpacing
            variant="text"
            onClick={onClose}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {t('common.discard')}
          </Button>
          <Button
            noSpacing
            withShadow
            type="submit"
            onClick={() => onSubmit(autocompleteValue)}
            color="primary"
            variant="contained"
            sx={{ textTransform: 'capitalize', width: '124px' }}
          >
            {t('common.apply')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
