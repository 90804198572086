import { Box, Grid, Stack, SxProps, Typography } from '@mui/material'
import { Button, Modal } from 'components'
import { ReactNode, useEffect, useMemo } from 'react'
import {
  DefaultValues,
  FieldValues,
  useForm,
  UseFormReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Form } from '../Form/Form'

type Props<T extends FieldValues> = {
  title: string
  submitTitle: string
  open: boolean
  isLoading: boolean
  sx?: SxProps
  defaultValues?: DefaultValues<T>
  enableInitialSubmit?: boolean

  onClose: () => void
  onSubmit: (values: T, form: UseFormReturn<T>) => void
  renderForm: (form: UseFormReturn<T>) => ReactNode
  onDelete?: () => void
}

export const FormModal = <T extends FieldValues>(props: Props<T>) => {
  const { t } = useTranslation()
  const form = useForm<T>({ defaultValues: props.defaultValues })
  const { formState, handleSubmit, reset } = form
  const { isDirty } = formState

  const isDisabled = useMemo(
    () => (!isDirty && !props.enableInitialSubmit) || props.isLoading,
    [isDirty, props.enableInitialSubmit, props.isLoading],
  )

  useEffect(() => {
    if (isDirty && !props.open) {
      reset()
    }
  }, [isDirty, props.open, reset])

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Form
        form={form}
        onSubmit={handleSubmit(values => {
          props.onSubmit(values, form)
        })}
      >
        <Stack p={3} minWidth="444px" maxWidth="712px" minHeight="320px">
          <Box mb={2}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h2" fontWeight={700}>
                  {props.title}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {props.renderForm(form)}
          <Box mt={'auto'} mb={'10px'} sx={props.sx}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Button
                  noSpacing
                  variant="text"
                  fullWidth
                  onClick={props.onClose}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={1}>
                  {props.onDelete && (
                    <Button
                      noSpacing
                      variant="text"
                      fullWidth
                      onClick={props.onDelete}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {t('common.delete')}
                    </Button>
                  )}
                  <Button
                    noSpacing
                    withShadow
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={isDisabled}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {props.submitTitle}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Form>
    </Modal>
  )
}
