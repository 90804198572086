import { Switch } from 'components'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const ActivateDeactivateSwitch = ({ control }: { control: any }) => {
  const { t } = useTranslation()

  return (
    <Controller
      name="subtitle"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Switch
          onChange={onChange}
          isChecked={value}
          label={
            value
              ? t('integrations.deactivate_integration')
              : t('integrations.activate_integration')
          }
          labelPlacement="end"
        />
      )}
    />
  )
}
