import { ButtonBase, Tooltip } from '@mui/material'
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg'
import { ConfirmationDialog } from 'components'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useBoolean } from 'lib/use-boolean'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const ResetButton = ({
  taskId,
  edited,
  translation,
  onReset,
}: {
  taskId: string
  edited: boolean
  translation: string | null
  onReset: () => void
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const buttonDisabled = useBoolean(false)
  const confirmDialog = useBoolean(false)

  const params = useMemo(() => {
    const params = new URLSearchParams()

    if (translation) {
      params.set('language', translation)
    }

    return params
  }, [translation])

  const $reset = useMutation('PATCH', '/api/tasks/:taskId/transcription/reset')

  useEffect(() => {
    if (!edited && buttonDisabled) {
      buttonDisabled.setFalse()
    }
  }, [buttonDisabled, edited])

  if (!edited) {
    return null
  }

  return (
    <>
      <Tooltip title={t('buttons.reset')}>
        <span>
          <ButtonBase
            sx={{ padding: '10px', borderRadius: '43px' }}
            disabled={buttonDisabled.isTrue}
            onClick={() => {
              confirmDialog.setTrue()
            }}
          >
            <ResetIcon color="#37637E" />
          </ButtonBase>
        </span>
      </Tooltip>

      <ConfirmationDialog
        title={t('reset_transcription_dialog.title')}
        confirmationLabel={t('common.ok')}
        confirmationMessage={t('reset_transcription_dialog.message')}
        onConfirm={() => {
          buttonDisabled.setTrue()
          confirmDialog.setFalse()
          $reset.mutate(
            {
              params: {
                taskId,
              },
              search: params,
            },
            {
              onSuccess: () => {
                onReset()
              },
              onError: () => {
                enqueueSnackbar(
                  t('error_notification.error_unable_to_reset_transcription'),
                  {
                    variant: 'error',
                  },
                )
              },
            },
          )
        }}
        open={confirmDialog.isTrue}
        onClose={confirmDialog.setFalse}
      />
    </>
  )
}
